export const LINK_BANK_TILES = 'LINK_BANK_TILES';
export const linkBankTiles = () => ({
	type: LINK_BANK_TILES,
});

export const LINK_BANK_COMPLETE = 'LINK_BANK_COMPLETE';
export const linkBankComplete = () => ({
	type: LINK_BANK_COMPLETE,
});

export const LINK_BANK_REISSUE = 'LINK_BANK_REISSUE';
export const linkBankReissue = () => ({
	type: LINK_BANK_REISSUE,
});

export const LINK_BANK_REISSUE_COMPLETE = 'LINK_BANK_REISSUE_COMPLETE';
export const linkBankReissueComplete = () => ({
	type: LINK_BANK_REISSUE_COMPLETE,
});

export const LINK_BANK_MICROS = 'LINK_BANK_MICROS';
export const linkBankMicros = () => ({
	type: LINK_BANK_MICROS,
});

export const LINK_BANK_MICROS_SUBMIT = 'LINK_BANK_MICROS_SUBMIT';
export const linkBankMicrosSubmit = () => ({
	type: LINK_BANK_MICROS_SUBMIT,
});

export const LINK_BANK_QUOVO_CONNECT = 'LINK_BANK_QUOVO_CONNECT';
export const linkBankQuovoConnect = () => ({
	type: LINK_BANK_QUOVO_CONNECT,
});

export const LINK_BANK_QUOVO_SUBMIT = 'LINK_BANK_QUOVO_SUBMIT';
export const linkBankQuovoSubmit = () => ({
	type: LINK_BANK_QUOVO_SUBMIT,
});

export const LINK_BANK_QUOVO_SYNCED = 'LINK_BANK_QUOVO_SYNCED';
export const linkBankQuovoSynced = () => ({
	type: LINK_BANK_QUOVO_SYNCED,
});

export const LINK_BANK_SELECT = 'LINK_BANK_SELECT';
export const linkBankSelect = (bank, method) => ({
	type: LINK_BANK_SELECT,
	bank,
	method,
});

export const LINK_BANK_SKIP = 'LINK_BANK_SKIP';
export const linkBankSkip = (bank, method) => ({
	type: LINK_BANK_SKIP,
});
