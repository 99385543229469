import { fetchRequestWithTypes } from './index.js';

// GET a user's trusted contact status
export const GET_TRUSTED_CONTACT_REQUEST = 'GET_TRUSTED_CONTACT_REQUEST';
export const GET_TRUSTED_CONTACT_SUCCESS = 'GET_TRUSTED_CONTACT_SUCCESS';
export const GET_TRUSTED_CONTACT_ERRORED = 'GET_TRUSTED_CONTACT_ERRORED';

export const getTrustedContact = (body) =>
	fetchRequestWithTypes({
		types: [
			GET_TRUSTED_CONTACT_REQUEST,
			GET_TRUSTED_CONTACT_SUCCESS,
			GET_TRUSTED_CONTACT_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/trusted_contacts',
		},
	});

// POST a user's trusted contact

export const POST_TRUSTED_CONTACT_REQUEST = 'POST_TRUSTED_CONTACT_REQUEST';
export const POST_TRUSTED_CONTACT_SUCCESS = 'POST_TRUSTED_CONTACT_SUCCESS';
export const POST_TRUSTED_CONTACT_ERRORED = 'POST_TRUSTED_CONTACT_ERRORED';

export const postTrustedContact = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_TRUSTED_CONTACT_REQUEST,
			POST_TRUSTED_CONTACT_SUCCESS,
			POST_TRUSTED_CONTACT_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/trusted_contacts',
			body,
		},
	});
