export const AccountStatus = {
	RegistrationNotComplete: 'registrationNotComplete',
	Restricted: 'restricted',
	Normal: 'normal',
	Closed: 'closed',
	Locked: 'locked',
	Unknown: 'unknown',
	Pending: 'pending',
	Inactive: 'inactive',
	Closing: 'closing',
};

export const NextStep = {
	ContactSupport: 'contactSupport',
	SpendDown: 'spendDown',
	ClaimFunds: 'claimFunds',
	None: 'none',
	ReactivateAccount: 'reactivateAccount',
	TransferIn: 'transferIn',
};

export const TransactionCategoriesTypes = {
	Groceries: 'groceries',
	Food: 'food',
	Transportation: 'transportation',
	Shopping: 'shopping',
	Bills: 'bills',
	Entertainment: 'entertainment',
	Miscellaneous: 'miscellaneous',
};

export const TransactionTileTypes = {
	StockBackReward: 'stockBackReward',
};

export const TransactionDevToolTypes = {
	Purchase: 'purchase',
	Refund: 'refund',
	AtmWithdrawal: 'atmWithdrawal',
	TellerCashWithdrawal: 'tellerCashWithdrawal',
	NetworkCardLoad: 'networkCardLoad',
	AchOut: 'achOut',
	CashReload: 'cashReload',
	PeerTransfer: 'peerTransfer',
	PurseTransfer: 'purseTransfer',
	AchIn: 'achIn',
	Adjustment: 'adjustment',
	PartnerTransferIn: 'partnerTransferIn',
	PartnerTransferOut: 'partnerTransferOut',
	Fee: 'fee',
	Other: 'other',
	Promo: 'promo',
	MRDC: 'mrdc',
	BillPay: 'billPay',
	Disbursement: 'disbursement',
	BalanceInquiry: 'balanceInquiry',
	ECash: 'eCash',
};
