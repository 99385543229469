import { fetchRequestWithTypes } from './index.js';
import { serialize } from '../../utils';

export const GET_HISTORICAL_QUOTES_REQUEST = 'GET_HISTORICAL_QUOTES_REQUEST';
export const GET_HISTORICAL_QUOTES_SUCCESS = 'GET_HISTORICAL_QUOTES_SUCCESS';
export const GET_HISTORICAL_QUOTES_ERRORED = 'GET_HISTORICAL_QUOTES_ERRORED';

export const getHistoricalQuotes = (quote) => {
	return fetchRequestWithTypes({
		types: [
			GET_HISTORICAL_QUOTES_REQUEST,
			GET_HISTORICAL_QUOTES_SUCCESS,
			GET_HISTORICAL_QUOTES_ERRORED,
		],
		quote,
		request: {
			method: 'GET',
			path: `/apis/api/v1/historical_quotes/quotes${serialize(quote)}`,
		},
	});
};

export const GET_QUOTE_RANGE_REQUEST = 'GET_QUOTE_RANGE_REQUEST';
export const GET_QUOTE_RANGE_SUCCESS = 'GET_QUOTE_RANGE_SUCCESS';
export const GET_QUOTE_RANGE_ERRORED = 'GET_QUOTE_RANGE_ERRORED';

export const getQuoteRange = (cardId, timePeriod) => {
	return fetchRequestWithTypes({
		types: [
			GET_QUOTE_RANGE_REQUEST,
			GET_QUOTE_RANGE_SUCCESS,
			GET_QUOTE_RANGE_ERRORED,
		],
		cardId,
		timePeriod,
		request: {
			method: 'GET',
			path: `/apis/api/v1/historical_quotes/quotes_ranges?card_id=${cardId}&time_period=${timePeriod}`,
		},
	});
};
