const unavailablePaths = [
	'/account-verifications',
	'/custodian-incomplete-sign-up',
	'/potential',
	'/retire-distribution-revocation',
	'/document-uploads',
	'/smart-save*',
	'/unavailable',
	'/cash-back',
	'/cash-back/*',
	'/cashback',
	'/cashback/*',
	'/spend-wise',
	'/balance',
	'/user-profile/settings/changeaddress',
];

const publicUnavailabePaths = ['/doc-investigations/disputes'];

// CommonJS module export syntax is required as these constants are used in the server directory
exports.publicUnavailabePaths = publicUnavailabePaths;
exports.unavailablePaths = unavailablePaths.concat(publicUnavailabePaths);
