import pkg from '../../../../package.json';

export const STASH_VERSION_HEADERS = {
	'x-stash-web-version': pkg.version,
};

export const myStashInfoSheet = [
	{
		titleBanjoId: 'information/giveTerm/myStashTotalValue',
		descriptionBanjoId: 'information/explainTerm/myStashTotalValue',
	},
];
