import { GET_REFERRAL_HOME_SUCCESS } from 'stash/actions/api/referrals';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_REFERRAL_HOME_SUCCESS:
			return {
				...state,
				...action.response.referralsHomeView,
			};
		default:
			return state;
	}
};
