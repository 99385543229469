import { TRACK_ORIGIN } from 'stash/actions/tracking';

export default (state = {}, action) => {
	switch (action.type) {
		case TRACK_ORIGIN: {
			return {
				...state,
				productEventsOrigin: action.origin,
			};
		}

		default:
			return state;
	}
};
