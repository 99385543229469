import { Layout, ListView, Tile } from '@stashinvest/ui';
import React, { useEffect } from 'react';
import { sendMixpanel } from '../../../events/utils';

export const statementsTileView = () => {
	sendMixpanel('CheckingReg', {
		ScreenName: 'ValuePropStatementsTile',
		Action: undefined,
	});
};

export const statementsTileCTA = () => {
	sendMixpanel('CheckingReg', {
		ScreenName: 'ValuePropStatementsTile',
		Action: 'ViewStatements',
	});
};

export const StatementsTile = () => {
	useEffect(() => {
		statementsTileView();
	}, []);

	return (
		<Layout.ContentWrap mb="xl">
			<Tile>
				<ListView
					variant={2}
					as="div"
					p="none"
					onClick={statementsTileCTA}
					href="/documents/bankStatements"
					title="Looking for your statements?"
					subtitle="See past statements from your banking account."
					hideBottomDivider
				/>
			</Tile>
		</Layout.ContentWrap>
	);
};
