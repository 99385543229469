import { POST_BOOKMARK_SUCCESS, DELETE_BOOKMARK_SUCCESS } from 'stash/actions';

const bookmarkEvents = (dataLayer, getState, action) => {
	if (action.type === POST_BOOKMARK_SUCCESS) {
		const cardId = action.response.bookmark.card_id;

		dataLayer.push({
			event: 'Bookmark',
			properties: {
				Action: 'Add',
				CardId: cardId,
			},
		});

		dataLayer.push({
			event: 'InvestmentCard',
			properties: {
				ScreenName: 'CardDetailsV2',
				Action: 'TapBookmark',
				CardId: cardId,
			},
		});
	} else if (action.type === DELETE_BOOKMARK_SUCCESS) {
		const cardId = action.meta.cardId;

		dataLayer.push({
			event: 'Bookmark',
			properties: {
				Action: 'Remove',
				CardId: cardId,
			},
		});

		dataLayer.push({
			event: 'InvestmentCard',
			properties: {
				ScreenName: 'CardDetailsV2',
				Action: 'TapUnbookmark',
				CardId: cardId,
			},
		});
	}
};

export default bookmarkEvents;
