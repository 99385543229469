import {
	GET_PLANS_SUCCESS,
	GET_ELIGIBLE_PLATFORM_TIERS_SUCCESS,
} from 'stash/actions/api';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_PLANS_SUCCESS:
		case GET_ELIGIBLE_PLATFORM_TIERS_SUCCESS:
			return action.response.eligible_platform_tiers;
		default:
			return state;
	}
};
