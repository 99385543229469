export const SET_CONTINUATION_PATH = 'SET_CONTINUATION_PATH';

export const setContinuationPath = (continuationPath) => ({
	type: SET_CONTINUATION_PATH,
	continuationPath,
});

export const CLEAR_CONTINUATION_PATH = 'CLEAR_CONTINUATION_PATH';

export const clearContinuationPath = () => ({
	type: CLEAR_CONTINUATION_PATH,
});
