import { GET_CARD_PRICES_SUCCESS } from 'stash/actions/api/prices';
import { formatDate } from 'stash/utils/time';

const RESOLUTION_UNIT = {
	MINUTES: 'MINUTES',
	HOURS: 'HOURS',
	DAYS: 'DAYS',
};

/**
 * This is used to set a specific date label for each price data point. The
 * reason why it is set here is the resolutionUnit returned from the prices
 * endpoint is dynamic. For instance if you request 1Y of history, there may
 * only be 2 months of data, so instead of showing day resolution like we do
 * for a full year of data, the backend will tell us to show hour resolution.
 */
export function normalizePriceData({ prices, resolutionUnit }) {
	const dateFormat = [RESOLUTION_UNIT.MINUTES, RESOLUTION_UNIT.HOURS].includes(
		resolutionUnit
	)
		? 'MMM d, h:mm aaa'
		: 'MMM d, yyyy';

	return prices.map((price) => ({
		priceAt: price.priceAt,
		priceAtLabel: formatDate(new Date(price.priceAt), dateFormat),
		price: price.price,
	}));
}

const pricesReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CARD_PRICES_SUCCESS: {
			const cardUuid = action.response.coin.id;

			return {
				...state,
				[cardUuid]: {
					...state[cardUuid],
					[action.range]: normalizePriceData(action.response),
				},
			};
		}

		default:
			return state;
	}
};

export default pricesReducer;
