import { isProdOrStaging } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';
import { datadogRum } from '@datadog/browser-rum';
import { getAnalyticsInstance } from 'stash/initializers/analytics';

/**
 * @deprecated use `useTrack` hook instead
 */
export const track = ({ event, properties, context, metric }) => {
	const analytics = getAnalyticsInstance();

	if (isProdOrStaging()) {
		datadogRum.addAction(metric, properties);
	}

	analytics.track(event, properties, {
		context,
	});
};

export function sendMixpanel(event, properties) {
	if (!isProdOrStaging() && getCookie('stashEventLogger')) {
		console.info(`[${event}]`, { event, properties });
	}

	if (!window.dataLayer) {
		console.log('GTM dataLayer not found');
		return;
	}

	return window.dataLayer.push({
		event,
		properties: {
			Action: undefined,
			ScreenName: undefined,
			TileType: undefined,
			ActionLink: undefined,
			...properties,
		},
	});
}

export function sendUserLeapEvent(event, properties) {
	if (!window.UserLeap) {
		console.log('UserLeap SDK not found');
		return;
	}
	if (!isProdOrStaging() && getCookie('stashEventLogger')) {
		console.info(`[${event}]`, properties);
	}
	return window.UserLeap(event, properties);
}

export function trackUserLeapEvent(eventName) {
	return sendUserLeapEvent('track', eventName);
}

export function setUserLeapAttribute(attribute, value) {
	return sendUserLeapEvent('setAttributes', {
		[attribute]: value,
	});
}
