import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDebitAccount } from 'stash/actions';
import PropTypes from 'prop-types';
import EligibilityRoute from './EligibilityRoute';
import NextStepRoute from './NextStepRoute';
import { Box, Flex, Text, PrimaryButton } from '@stashinvest/ui';

export const DisabledFeaturesList = {
	CardReissue: 'cardReissue',
	CardActivation: 'cardActivation',
	MoneyTransfer: 'moneyTransfer',
	AtmLocator: 'atmLocator',
	PlaidInsights: 'plaidInsights',
	CashReload: 'cashReload',
	VirtualCard: 'virtualCard',
	DirectDeposit: 'directDepositInfo',
	AccountInfo: 'accountAndRoutingNumbers',
	Statements: 'statements',
	PinReset: 'pinReset',
	Travel: 'travelInfo',
	SpendingInsights: 'spendingInsights',
	StashSettings: 'stashSettings',
	StockBack: 'stockBack',
	DataSharing: 'dataSharing',
	PayBills: 'payBills',
	RecurringTransfer: 'recurringTransfer',
	MRDC: 'mrdc',
	Envelopes: 'envelopes',
	CardPerks: 'cardPerks',
	Partitions: 'partitions',
	PartitionCreateOrEdit: 'partitionCreateOrEdit',
	CardManagement: 'cardManagement',
	DebitFund: 'debitFund',
	CreateDispute: 'createDispute',
};

export const mapStateToProps = (state) => ({
	disabledFeatures: state?.entities?.bank?.debit_accounts?.[0]?.disabledFeatures,
	account: state?.entities?.bank?.debit_accounts,
});

const mapDispatchToProps = {
	getDebitAccount,
};

export const BankRoute = (props) => {
	const {
		children,
		disabledFeatures,
		getDebitAccount,
		feature,
		redirect,
		account,
		path,
		...routeProps
	} = props;
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!account) {
			getDebitAccount().catch(() => {
				setError(true);
			});
		}
	}, [account, getDebitAccount]);

	if (error) {
		return (
			<Flex
				flexDirection="column"
				minHeight="95vh"
				justifyContent="center"
				alignItems="center"
			>
				<Box align="center" maxWidth="28rem" width="100%">
					<Text.H1 size="3" mb="m">
						Could not load banking
					</Text.H1>
					<Text mb="m">There was a connection issue. Please try again.</Text>
					<PrimaryButton onClick={() => window.location.reload()}>
						Try again
					</PrimaryButton>
				</Box>
			</Flex>
		);
	}

	if (account && account.length === 0) {
		return (
			<Route {...routeProps}>
				<EligibilityRoute />
			</Route>
		);
	}

	if (account && account.length) {
		return (
			<Route {...routeProps}>
				<NextStepRoute account={account} {...props} />
			</Route>
		);
	}

	return null;
};

BankRoute.propTypes = {
	children: PropTypes.node,
	disabledFeatures: PropTypes.arrayOf(PropTypes.string),
	feature: PropTypes.string,
	redirect: PropTypes.string,
	getDebitAccount: PropTypes.func.isRequired,
	path: PropTypes.string,
	account: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(BankRoute);
