import { fetchRequestWithTypes } from './index.js';

export const GET_USER_INCOME_QUESTION_REQUEST = 'GET_USER_INCOME_QUESTION_REQUEST';
export const GET_USER_INCOME_QUESTION_SUCCESS = 'GET_USER_INCOME_QUESTION_SUCCESS';
export const GET_USER_INCOME_QUESTION_ERRORED = 'GET_USER_INCOME_QUESTION_ERRORED';

export const getUserIncomeQuestion = () => {
	return fetchRequestWithTypes({
		types: [
			GET_USER_INCOME_QUESTION_REQUEST,
			GET_USER_INCOME_QUESTION_SUCCESS,
			GET_USER_INCOME_QUESTION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/onboarding/api/v2/users/:uuid/questions`,
		},
	});
};

export const GET_USER_CURRENT_INCOME_REQUEST = 'GET_USER_CURRENT_INCOME_REQUEST';
export const GET_USER_CURRENT_INCOME_SUCCESS = 'GET_USER_CURRENT_INCOME_SUCCESS';
export const GET_USER_CURRENT_INCOME_ERRORED = 'GET_USER_CURRENT_INCOME_ERRORED';

export const getUserCurrentIncome = () => {
	return fetchRequestWithTypes({
		types: [
			GET_USER_CURRENT_INCOME_REQUEST,
			GET_USER_CURRENT_INCOME_SUCCESS,
			GET_USER_CURRENT_INCOME_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/onboarding/api/v2/users/:uuid/questions/answers',
		},
	});
};

export const PATCH_ONBOARDING_QUESTIONS_ANSWERS_REQUEST =
	'PATCH_ONBOARDING_QUESTIONS_ANSWERS_REQUEST';
export const PATCH_ONBOARDING_QUESTIONS_ANSWERS_SUCCESS =
	'PATCH_ONBOARDING_QUESTIONS_ANSWERS_SUCCESS';
export const PATCH_ONBOARDING_QUESTIONS_ANSWERS_ERRORED =
	'PATCH_ONBOARDING_QUESTIONS_ANSWERS_ERRORED';

export const patchOnboardingQuestions = (body) => {
	return fetchRequestWithTypes({
		types: [
			PATCH_ONBOARDING_QUESTIONS_ANSWERS_REQUEST,
			PATCH_ONBOARDING_QUESTIONS_ANSWERS_SUCCESS,
			PATCH_ONBOARDING_QUESTIONS_ANSWERS_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/onboarding/api/v2/users/:uuid/questions/answers',
			body,
		},
	});
};
