import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getRestrictedMessage } from '../../actions';
import { Box, Text, Drawer, PrimaryButton } from '@stashinvest/ui/dist/es/2';
import { ButtonsRow, ContinueButton } from './DuplicateAccountModal.styles';
import { useOnMount } from 'stash/utils/hooks';

const DuplicateAccountModal = ({ onSubmit, onClose }) => {
	const [restrictionMsg, setRestrictionMsg] = useState({});
	const [ready, setReady] = useState(false);
	const dispatch = useDispatch();
	const loadData = () => {
		dispatch(getRestrictedMessage()).then((response) => {
			setRestrictionMsg(response);
			setReady(true);
		});
	};

	useOnMount(() => loadData());

	if (!ready) {
		return null;
	}

	return (
		<Drawer onDismiss={onSubmit}>
			<Box p="s24">
				<Text.Title24 mb="4px" fontWeight="bold">
					{restrictionMsg.message?.title}
				</Text.Title24>
				<Text.Body16 mb="s24">{restrictionMsg.message.body}</Text.Body16>
				<ButtonsRow>
					<PrimaryButton size="medium" onClick={onClose}>
						{restrictionMsg?.cta?.text}
					</PrimaryButton>
					<ContinueButton onClick={onSubmit}>
						{restrictionMsg?.secondary_cta?.text}
					</ContinueButton>
				</ButtonsRow>
			</Box>
		</Drawer>
	);
};

DuplicateAccountModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default DuplicateAccountModal;
