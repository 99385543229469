import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components/macro';
import {
	Text,
	Layout,
	Flex,
	Box,
	Link,
	MoneyShieldIllustration,
	ArrowsIllustration,
	PieGraphIllustration,
	SafeIllustration,
	BanksyImage,
} from '@stashinvest/ui';
import { BANKING_FEE_OUTLINE_URL } from 'stash/constants';

// Flex pb="xl" flexDirection={{ mobile: 'column', tablet: 'row' }}
const InfoGrid = styled(Box)`
	display: grid;
	grid-template-rows: 1fr 1fr 1fr 1fr;

	@media screen and (min-width: 500px) {
		grid-template-rows: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		max-width: 500px;
		margin: auto;
	}

	${({ theme }) => theme.mediaQueries.desktop} {
		grid-template-rows: 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		max-width: none;
	}
`;

const InfoBoxContainer = styled(Flex)`
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 250px;
	margin: 0 auto;
`;

const BoxWithBorder = styled(Box)`
	flex: 1;
	width: 100%;

	${({ theme, withBorder }) =>
		withBorder &&
		css`
			margin-bottom: ${theme.space.l};
			padding-bottom: ${theme.space.l};
			border-bottom: 1px solid rgba(255, 255, 255, 0.24);
		`}

	@media screen and (min-width: 500px) {
		border-bottom: 0;
		grid-row-gap: ${({ theme }) => theme.space.xl};
	}

	${({ theme }) => theme.mediaQueries.desktop} {
		margin-bottom: 0;
		padding-bottom: 0;

		${({ theme, withBorder }) =>
			withBorder &&
			css`
				border-right: 1px solid rgba(255, 255, 255, 0.24);
			`};
	}
`;

function InfoBox(props) {
	return (
		<InfoBoxContainer>
			<props.component size={props.size} />
			<BoxWithBorder withBorder={props.withBorder}>{props.children}</BoxWithBorder>
		</InfoBoxContainer>
	);
}

InfoBox.defaultProps = {
	withBorder: true,
};

InfoBox.propTypes = {
	imageSrc: PropTypes.string,
	imageWidth: PropTypes.string,
	withBorder: PropTypes.bool,
	children: PropTypes.node,
	size: PropTypes.number,
};

export function HeroSection(props) {
	return (
		<>
			<Layout.ContentWrap mb="xl">
				<Flex alignItems="center" flexDirection={{ mobile: 'column', tablet: 'row' }}>
					<BanksyImage
						src="/images/illustrations/scene/debitCardBranded/debitCardBranded.svg"
						maxWidth="250px"
						flex="1"
						mb={{ mobile: 's', tablet: 'none' }}
					/>
					<Box
						flex="1"
						order={{ mobile: 1, tablet: -1 }}
						mr={{ mobile: 'none', tablet: 'l' }}
					>
						<Text.H1 size={3} mb="s">
							The debit card that earns you stock as you spend.¹
						</Text.H1>
						<Text>Let’s confirm your information and get you on your way.</Text>
					</Box>
				</Flex>
			</Layout.ContentWrap>

			<Layout.ContentWrap wide>
				<InfoGrid pb={{ desktop: 'xl' }}>
					<InfoBox component={MoneyShieldIllustration} size="70px">
						<Text.Subtitle mb="xxs" align="center">
							No hidden fees
						</Text.Subtitle>
						<Text.Caption align="center">
							Zero minimum, set-up, <br />
							or overdraft fees.² <br />
							<Link target="_blank" href={BANKING_FEE_OUTLINE_URL}>
								View fees
							</Link>
						</Text.Caption>
					</InfoBox>

					<InfoBox component={ArrowsIllustration} size="70px">
						<Text.Subtitle mb="xxs" align="center">
							Get paid early
						</Text.Subtitle>
						<Text.Caption align="center">
							Set up direct deposit to get paid <br />
							up to 2 days early.³
						</Text.Caption>
					</InfoBox>

					<InfoBox component={PieGraphIllustration} size="70px">
						<Text.Subtitle mb="xxs" align="center">
							Debit insights
						</Text.Subtitle>

						<Text.Caption align="center">
							Track your spending and get <br />
							insights to help you save more.
						</Text.Caption>
					</InfoBox>

					<InfoBox component={SafeIllustration} size="70px" withBorder={false}>
						<Text.Subtitle mb="xxs" align="center">
							Safe and secure
						</Text.Subtitle>
						<Text.Caption align="center">
							FDIC-insured debit account <br />
							with 256-bit encryption.
						</Text.Caption>
					</InfoBox>
				</InfoGrid>
			</Layout.ContentWrap>
		</>
	);
}
export default HeroSection;
