import { SET_SEARCH_QUERY, CLEAR_SEARCH_RESULTS } from 'stash/actions';
import { SEARCH_CARDS_SUCCESS } from 'stash/actions/api';

export const initialState = {
	query: '',
	results: [],
	loading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_SEARCH_QUERY:
			return {
				...state,
				query: action.query,
				loading: true,
			};
		case SEARCH_CARDS_SUCCESS:
			return {
				...state,
				results: action.response.cards,
				loading: false,
			};
		case CLEAR_SEARCH_RESULTS:
			return {
				...state,
				results: [],
				loading: false,
			};
		default:
			return state;
	}
};
