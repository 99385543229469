export const SET_ACTIVE_NAV_TAB = 'application/SET_ACTIVE_NAV_TAB';

export const setActiveTab = (activeTab) => ({
	type: SET_ACTIVE_NAV_TAB,
	activeTab,
});

export const SET_ACTIVE_ACCOUNT = 'application/SET_ACTIVE_ACCOUNT';

export const setActiveAccount = (activeAccount) => ({
	type: SET_ACTIVE_ACCOUNT,
	activeAccount,
});
