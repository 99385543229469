import React from 'react';
import Drawer from './Drawer';
import propTypes from 'prop-types';

const DrawerPortal = (props) => {
	const { active } = props;

	return active && <Drawer {...props} />;
};

DrawerPortal.propTypes = {
	active: propTypes.bool,
};

export default DrawerPortal;
