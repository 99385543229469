import React from 'react';
import { Route, Redirect } from 'react-router';
import { Layout } from '@stashinvest/ui';
import { connect } from 'react-redux';
import {
	RejectManualCure,
	RejectNoCure,
	UnknownError,
} from '../components/sign-up/bank/BankErrorScreen';
import { sendMeTheApp } from 'stash/actions';
import {
	noCureScreenView,
	noCureContactClick,
	noCurePrimaryCtaClick,
	manualCureScreenView,
	manualCurePrimaryCtaClick,
	unknownScreenView,
	unknownContactClick,
	unknownPrimaryCtaClick,
} from 'stash/actions/debitSignUpTrackingEvents';
import { trimFirstPhoneDigit } from '../utils/formatters';
import { AccountStatus, NextStep } from '../components/bank/BankConstants';
import BANK_ACCOUNT_PARTNER_TYPE from '../constants/partnerTypes';
import EligibilityRoute from './EligibilityRoute';
import { DisabledFeaturesList } from './BankRoute';

const mapDispatchToProps = {
	noCureScreenView,
	noCureContactClick,
	noCurePrimaryCtaClick,
	manualCureScreenView,
	manualCurePrimaryCtaClick,
	unknownContactClick,
	unknownPrimaryCtaClick,
	unknownScreenView,
	sendMeTheApp,
};

const mapStateToProps = (state) => ({
	phone_number: trimFirstPhoneDigit(state?.entities?.profile?.phone_number ?? ''),
});

export const NextStepRoute = (props) => {
	const { children, feature, redirect, account } = props;

	const status = account[0].status;
	const nextStep = account[0].nextStep;
	const disabledFeatures = account[0].disabledFeatures;
	const isGreenDotPartner = account[0].partnerType === BANK_ACCOUNT_PARTNER_TYPE.GREENDOT;

	switch (status) {
		case AccountStatus.Normal:
			return handleNormal();
		case AccountStatus.RegistrationNotComplete:
			return handleRegistrationNotComplete();
		case AccountStatus.Restricted:
			return handleRestricted();
		case AccountStatus.Closed:
			return handleClosed();
		case AccountStatus.Pending:
			return handlePending();
		case AccountStatus.Inactive:
			return handleInactive();
		case AccountStatus.Closing:
			return handleClosing();
		case AccountStatus.Locked:
			return handleLocked();
		case AccountStatus.Unknown:
		default:
			return handleUnknown();
	}

	function doDisallowedFeaturesCheckAndContinue() {
		return (
			<Route {...props}>
				{!disabledFeatures.includes(feature) ? children : <Redirect to={redirect} />}
			</Route>
		);
	}

	function unknownError() {
		return (
			<Layout.PageWrap>
				<UnknownError
					handleMount={props.unknownScreenView}
					handleContactClick={props.unknownContactClick}
					handleCtaClick={props.unknownPrimaryCtaClick}
				/>
			</Layout.PageWrap>
		);
	}

	function handleNormal() {
		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}
	}

	function handleRegistrationNotComplete() {
		if (nextStep === NextStep.ContactSupport) {
			return (
				<Layout.PageWrap>
					<RejectManualCure
						handleMount={props.manualCureScreenView}
						handleCtaClick={props.manualCurePrimaryCtaClick}
					/>
				</Layout.PageWrap>
			);
		}

		if (nextStep === NextStep.None) {
			return (
				<Layout.PageWrap>
					<RejectNoCure
						handleMount={props.noCureScreenView}
						handleContactClick={props.noCureContactClick}
						handleCtaClick={props.noCurePrimaryCtaClick}
					/>
				</Layout.PageWrap>
			);
		}
	}

	function handleRestricted() {
		if (nextStep === NextStep.ContactSupport) {
			return doDisallowedFeaturesCheckAndContinue();
		}

		if (nextStep === NextStep.SpendDown) {
			return doDisallowedFeaturesCheckAndContinue();
		}

		return unknownError();
	}

	function handleClosedGreendotAccount() {
		if (feature === DisabledFeaturesList.Statements) {
			return doDisallowedFeaturesCheckAndContinue();
		}

		return <EligibilityRoute />;
	}

	function handleClosed() {
		if (nextStep === NextStep.ClaimFunds) {
			return doDisallowedFeaturesCheckAndContinue();
		}

		if (isGreenDotPartner) {
			return handleClosedGreendotAccount();
		}

		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		return unknownError();
	}

	function handleClosing() {
		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		return unknownError();
	}

	function handlePending() {
		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		if (nextStep === NextStep.ContactSupport) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		return unknownError();
	}

	function handleInactive() {
		if (nextStep === NextStep.TransferIn) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		return unknownError();
	}

	function handleLocked() {
		if (nextStep === NextStep.ContactSupport) {
			return doDisallowedFeaturesCheckAndContinue();
		}
		if (nextStep === NextStep.None) {
			return doDisallowedFeaturesCheckAndContinue();
		}

		return unknownError();
	}

	function handleUnknown() {
		return (
			<Layout.PageWrap>
				<RejectManualCure
					handleMount={props.manualCureScreenView}
					handleCtaClick={props.manualCurePrimaryCtaClick}
				/>
			</Layout.PageWrap>
		);
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(NextStepRoute);
