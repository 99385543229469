import { DEFER_ACTION, CLEAR_ACTION } from '../../actions/deferredActions.js';

export default (state = {}, action) => {
	switch (action.type) {
		case DEFER_ACTION: {
			return {
				...state,
				[action.actionKey]: action.deferredAction,
			};
		}

		case CLEAR_ACTION: {
			return Object.keys(state).reduce((acc, key) => {
				if (key === action.actionKey) return acc;

				acc[key] = state[key];
				return acc;
			}, {});
		}

		default:
			return state;
	}
};
