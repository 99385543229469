import { fetchRequestWithTypes } from './index.js';

// Returns Lemonade application questions with user specific values
// https://edge.stsh.io/documentation/1.0/lemonade/application_questions.html

export const GET_LEMONADE_APPLICATION_QUESTIONS_REQUEST =
	'GET_LEMONADE_APPLICATION_QUESTIONS_REQUEST';
export const GET_LEMONADE_APPLICATION_QUESTIONS_SUCCESS =
	'GET_LEMONADE_APPLICATION_QUESTIONS_SUCCESS';
export const GET_LEMONADE_APPLICATION_QUESTIONS_ERRORED =
	'GET_LEMONADE_APPLICATION_QUESTIONS_ERRORED';

export const getLemonadeApplicationQuestions = () =>
	fetchRequestWithTypes({
		types: [
			GET_LEMONADE_APPLICATION_QUESTIONS_REQUEST,
			GET_LEMONADE_APPLICATION_QUESTIONS_SUCCESS,
			GET_LEMONADE_APPLICATION_QUESTIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/insurance/home/application_questions`,
		},
	});

// Gathers proper information for lemonade insurance quote and returns lemonade insurance data
// https://edge.stsh.io/documentation/1.0/lemonade/quote.html

export const POST_LEMONADE_QUOTE_REQUEST = 'POST_LEMONADE_QUOTE_REQUEST';
export const POST_LEMONADE_QUOTE_SUCCESS = 'POST_LEMONADE_QUOTE_SUCCESS';
export const POST_LEMONADE_QUOTE_ERRORED = 'POST_LEMONADE_QUOTE_ERRORED';

export const postLemonadeQuote = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_LEMONADE_QUOTE_REQUEST,
			POST_LEMONADE_QUOTE_SUCCESS,
			POST_LEMONADE_QUOTE_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/insurance/home/quote`,
			body,
		},
	});

// Get payment details and submit payment to lemonade
// https://edge.stsh.io/documentation/1.0/lemonade/pay.html

export const POST_LEMONADE_PAYMENT_REQUEST = 'POST_LEMONADE_PAYMENT_REQUEST';
export const POST_LEMONADE_PAYMENT_SUCCESS = 'POST_LEMONADE_PAYMENT_SUCCESS';
export const POST_LEMONADE_PAYMENT_ERRORED = 'POST_LEMONADE_PAYMENT_ERRORED';

export const postPayment = (quote_id, card_token_id) =>
	fetchRequestWithTypes({
		types: [
			POST_LEMONADE_PAYMENT_REQUEST,
			POST_LEMONADE_PAYMENT_SUCCESS,
			POST_LEMONADE_PAYMENT_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/insurance/home/pay`,
			body: {
				tos_approved: true,
				quote_id,
				card_token_id,
			},
		},
	});
