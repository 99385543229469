import styled, { keyframes } from 'styled-components/macro';

const loaderShowDelay = 300;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const LoaderContainer = styled.div`
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	> * {
		animation: 10ms ${fadeIn} linear ${loaderShowDelay}ms;
	}
`;
