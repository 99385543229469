import { fetchRequestWithTypes } from './index.js';

// Get whether an IRA account is elgible for full distribution.
// https://staging.stsh.io/documentation/1.0/accounts/check_ira_full_distribution_eligible.html

export const GET_IRA_DISTRIBUTION_ELIGIBLE_REQUEST =
	'GET_IRA_DISTRIBUTION_ELIGIBLE_REQUEST';
export const GET_IRA_DISTRIBUTION_ELIGIBLE_SUCCESS =
	'GET_IRA_DISTRIBUTION_ELIGIBLE_SUCCESS';
export const GET_IRA_DISTRIBUTION_ELIGIBLE_ERRORED =
	'GET_IRA_DISTRIBUTION_ELIGIBLE_ERRORED';

export const getIraDistributionEligible = (accountId, distribution) => {
	return fetchRequestWithTypes({
		types: [
			GET_IRA_DISTRIBUTION_ELIGIBLE_REQUEST,
			GET_IRA_DISTRIBUTION_ELIGIBLE_SUCCESS,
			GET_IRA_DISTRIBUTION_ELIGIBLE_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/ira_full_distribution_eligible`,
		},
	});
};

// Initiate distribution for user's specific IRA account.
// https://staging.stsh.io/documentation/1.0/ira_distributions/create.html

export const POST_IRA_DISTRIBUTION_REQUEST = 'POST_IRA_DISTRIBUTION_REQUEST';
export const POST_IRA_DISTRIBUTION_SUCCESS = 'POST_IRA_DISTRIBUTION_SUCCESS';
export const POST_IRA_DISTRIBUTION_ERRORED = 'POST_IRA_DISTRIBUTION_ERRORED';

export const postIraDistribution = (accountId, distribution) => {
	return fetchRequestWithTypes({
		types: [
			POST_IRA_DISTRIBUTION_REQUEST,
			POST_IRA_DISTRIBUTION_SUCCESS,
			POST_IRA_DISTRIBUTION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/ira_distributions`,
			body: {
				distribution,
			},
		},
	});
};
