/**
 * This middleware is responsible for opening/closing
 * the dialog dialog. It returns a Promise that is
 * resolved/rejected when the user responds to the
 * dialog.
 */

import { OPEN_DIALOG, CONFIRM_DIALOG, CANCEL_DIALOG } from '../actions/dialog';

let confirm, cancel;

export default ({ dispatch, getState }) => (next) => (action) => {
	if (action.type === OPEN_DIALOG) {
		// Fire the OPEN_DIALOG action through to the reducer.
		next(action);

		return new Promise((resolve, reject) => {
			confirm = resolve;
			cancel = reject;
		});
	}

	if (action.type === CONFIRM_DIALOG) {
		typeof confirm === 'function' && confirm();
	}

	if (action.type === CANCEL_DIALOG) {
		typeof cancel === 'function' && cancel();
	}

	return next(action);
};
