import { GET_INVESTMENT_GROUPS_SUCCESS } from 'stash/actions/api';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_INVESTMENT_GROUPS_SUCCESS:
			return {
				...state,
				[action.meta.type]: action.response.groups,
			};

		default:
			return state;
	}
};
