import { AccountType } from '@stashinvest/product-events-javascript/lib/enum_properties';

export const PERSONAL_BROKERAGE = 'PERSONAL_BROKERAGE';
export const BROKERAGE_ACCOUNTS_COMBINED = 'BROKERAGE_ACCOUNTS_COMBINED';
export const ROBO_PERSONAL_BROKERAGE = 'ROBO_PERSONAL_BROKERAGE';
export const TRADITIONAL_IRA = 'TRADITIONAL_IRA';
export const ROTH_IRA = 'ROTH_IRA';
export const CUSTODIAN = 'CUSTODIAN';
export const DEBIT = 'DEBIT';
export const ACCOUNT_STATUS_CLOSED = 'ACCOUNT_STATUS_CLOSED';

//Used for analytics events
export const ANALYTICS_ACCOUNT_TYPE = {
	[PERSONAL_BROKERAGE]: AccountType.personalBrokerage,
	[ROBO_PERSONAL_BROKERAGE]: AccountType.personalBrokerage,
	[TRADITIONAL_IRA]: AccountType.traditionalIra,
	[ROTH_IRA]: AccountType.rothIra,
	[CUSTODIAN]: AccountType.custodial,
	[DEBIT]: AccountType.bank,
};

//Returned by /apis/experiences/v1/overviews/accounts/by/users/:uuid'
export const PX_ACCOUNT_TYPES = {
	BANK_DDA: 'ACCOUNT_TYPE_BANK_DDA',
	CUSTODIAN: 'ACCOUNT_TYPE_CUSTODIAN',
	PERSONAL_BROKERAGE: 'ACCOUNT_TYPE_PERSONAL_BROKERAGE',
	ROBO_PERSONAL_BROKERAGE: 'ACCOUNT_TYPE_ROBO_PERSONAL_BROKERAGE',
	ROTH_IRA: 'ACCOUNT_TYPE_ROTH_IRA',
	STASH_BANK: 'ACCOUNT_TYPE_STASH_BANK',
	TRADITIONAL_IRA: 'ACCOUNT_TYPE_TRADITIONAL_IRA',
};

// Special account type formats used for platform tier product types
export const RETIRE_PRODUCT_TYPE = 'IRA';
export const CUSTODIAL_PRODUCT_TYPE = 'CUSTODIAL';

export const ALL_BROKERAGE_ACCOUNTS = [
	PERSONAL_BROKERAGE,
	ROBO_PERSONAL_BROKERAGE,
	CUSTODIAN,
];
export const ALLOWS_MULTIPLE_ACCOUNTS = {
	CUSTODIAN: true,
};

export const accountTypes = {
	PERSONAL_BROKERAGE: {
		name: 'Personal Brokerage',
	},
	ROBO_PERSONAL_BROKERAGE: {
		name: 'Smart',
	},
	TRADITIONAL_IRA: {
		name: 'Traditional IRA',
	},
	ROTH_IRA: {
		name: 'Roth IRA',
	},
	CUSTODIAN: {
		name: 'Custodial',
	},
	DEBIT: {
		name: 'Debit',
	},
};

export const READABLE_ACCOUNT_NAMES = {
	PERSONAL_BROKERAGE: 'Personal Portfolio',
	ROBO_PERSONAL_BROKERAGE: 'Smart Portfolio',
	TRADITIONAL_IRA: 'Traditional IRA',
	ROTH_IRA: 'Roth IRA',
	CUSTODIAN: 'Custodial',
};

// https://github.com/StashInvest/stash-invest-rails/blob/edge/app/services/platform_tiers/constants.rb
export const MONOLITH_PRODUCT_TYPES = {
	PERSONAL_BROKERAGE: 'PERSONAL_BROKERAGE',
	ROBO_PERSONAL_BROKERAGE: 'ROBO_PERSONAL_BROKERAGE',
	IRA: 'IRA',
	CUSTODIAL: 'CUSTODIAL',
	STASH_BANK: 'STASH_BANK',
	PREMIUM_STOCK_REWARD: 'PREMIUM_STOCK_REWARD',
	LEARN_INSIGHT: 'LEARN_INSIGHT',
	PREMIUM_EXCLUSIVE_CONTENT: 'PREMIUM_EXCLUSIVE_CONTENT',
	GROWTH_EXCLUSIVE_CONTENT: 'GROWTH_EXCLUSIVE_CONTENT',
	BEGINNER_EXCLUSIVE_CONTENT: 'BEGINNER_EXCLUSIVE_CONTENT',
	METAL_CARD_STANDARD: 'Standard',
	METAL_CARD_PREMIUM: 'Premium',
	STOCK_BACK_DOUBLE: 'DoubleStockBack',
	STOCK_BACK_DEFAULT: 'DefaultStockBack',
};
