import get from 'lodash/get';
import { createSelector } from 'reselect';

export const baseSelector = (state) => get(state, 'entities', {});

export const userSelector = createSelector(baseSelector, (data) => get(data, 'user', {}));

export const isIRAEligibleSelector = createSelector(userSelector, (user) =>
	get(user, 'ira_eligible', false)
);

export const selectUserFirstName = createSelector(
	userSelector,
	(user) => user?.first_name
);

export const selectPlatformTier = (state) => {
	return state?.entities?.user?.platform_tier ?? {};
};

export const selectPlatformTierProductTypes = (state) => {
	return state?.entities?.user?.platform_tier?.product_types ?? [];
};

export const selectPlatformTierFeeWaiver = (state) => {
	return state?.entities?.user?.fee_waiver?.waivedTiers ?? [];
};

export const selectHasUserPlatformTierFeeWaived = (state) => {
	const userActivePlatformTier = state?.entities?.user?.platform_tier?.type;
	const waivedTiers = state?.entities?.user?.fee_waiver?.waivedTiers ?? [];
	return waivedTiers.includes(userActivePlatformTier);
};

export const selectBillingFrequencyOptions = (state) => {
	return state?.entities?.user?.billing_frequency_options ?? [];
};

export const selectBillingSummary = (state) => {
	return state?.entities?.user?.billing_summary ?? null;
};

export const selectBillingSummaryError = (state) => {
	return state?.entities?.user?.billing_summary_error ?? null;
};

export const selectRefundPolicy = (state) => {
	return state?.entities?.user?.refund_policy ?? {};
};

export const selectUserPaymentMethods = (state) => {
	return state?.entities?.user?.payment_methods ?? [];
};
