import { fetchRequestWithTypes } from './index.js';

// Returns an account's bank conversion
export const GET_BANK_CONVERSION_REQUEST = 'GET_BANK_CONVERSION_REQUEST';
export const GET_BANK_CONVERSION_SUCCESS = 'GET_BANK_CONVERSION_SUCCESS';
export const GET_BANK_CONVERSION_ERRORED = 'GET_BANK_CONVERSION_ERRORED';

export const getBankConversion = () => {
	return fetchRequestWithTypes({
		types: [
			GET_BANK_CONVERSION_REQUEST,
			GET_BANK_CONVERSION_SUCCESS,
			GET_BANK_CONVERSION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/externalAccounts/api/v1/users/:uuid/conversion`,
		},
	});
};
