import {
	GET_BANK_ACCOUNT_HOME_SUCCESS,
	GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_SUCCESS,
	POST_DEBIT_ACCOUNT_SUCCESS,
	UPDATE_TRANSACTION_CATEGORY_SUCCESS,
	GET_BANK_ACCOUNT_STATUS_SUCCESS,
} from 'stash/actions/api/harmonyBank';
import {
	GET_DEBIT_ACCOUNT_SUCCESS,
	GET_DEBIT_ELIGIBILITY_SUCCESS,
} from '../../actions/api';

const harmonyBank = (state = {}, action) => {
	switch (action.type) {
		case GET_BANK_ACCOUNT_HOME_SUCCESS: {
			return {
				...state,
				...action.response.home,
			};
		}

		case UPDATE_TRANSACTION_CATEGORY_SUCCESS: {
			return {
				...state,
				transactions: state.transactions.map((item) => {
					return {
						yearMonth: item.yearMonth,
						transactions: item.transactions.map((transaction) => {
							if (transaction.id !== action.response.transaction.id) {
								return transaction;
							}

							return {
								...transaction,
								category: {
									...action.response.transaction.category,
								},
							};
						}),
					};
				}),
			};
		}

		case GET_DEBIT_ELIGIBILITY_SUCCESS: {
			return {
				...state,
				eligibility: action.response.eligibility,
			};
		}

		case GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_SUCCESS: {
			return {
				...state,
				confirmationData: action.response.confirmationData,
			};
		}

		case POST_DEBIT_ACCOUNT_SUCCESS: {
			return {
				...state,
				debit_accounts: [action.response.account],
			};
		}

		case GET_DEBIT_ACCOUNT_SUCCESS: {
			return {
				...state,
				debit_accounts: action.response.accounts,
				partnerTransitionStatus: action.response.partnerTransitionStatus,
			};
		}

		//This action is only used by the BuyFundingSource component to check cash balance
		case GET_BANK_ACCOUNT_STATUS_SUCCESS: {
			return {
				...state,
				cashBalance: action.response.accountStatusAndBalance.balance,
			};
		}

		default:
			return state;
	}
};

export default harmonyBank;
