import {
	GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS,
	POST_ACCOUNT_BANK_ACCOUNT_SUCCESS,
} from 'stash/actions/api/bankAccount';

function bankAccountReducer(state = {}, action) {
	switch (action.type) {
		case POST_ACCOUNT_BANK_ACCOUNT_SUCCESS:
		case GET_ACCOUNT_BANK_ACCOUNTS_SUCCESS:
			return action.response.bank_account || state;
		default:
			return state;
	}
}

export default bankAccountReducer;
