/**
 * This middleware integrates Google's reCAPTCHA challenge
 * when a user is trying to sign up. Google loves nothing more
 * than a global callback on window so this could get a little
 * messy.
 */

import { POST_USER_REQUEST } from '../actions/api/user';
import { GOOGLE_RECAPTCHA_SITE_ID } from '../constants';
import { isProdOrStaging } from 'stash/utils';

// Inject the reCAPTCHA script tag in to the DOM.
// Resolves a promise on load to ensure `window.grecaptcha` is available
export const loadRecaptchaScript = () => {
	if (!isProdOrStaging()) return;

	return new Promise((resolve, reject) => {
		if (window.grecaptcha) {
			return resolve();
		}
		if (!document.getElementById('google-recaptcha-script')) {
			window.onRecaptchaLoad = resolve;
			const script = document.createElement('script');
			script.id = 'google-recaptcha-script';
			script.async = true;
			script.defer = true;
			script.src = `https://www.google.com/recaptcha/api.js?render=${GOOGLE_RECAPTCHA_SITE_ID}`;
			document.getElementsByTagName('body')[0].appendChild(script);
		}
	});
};

// The middleware. Intercepts when a user is POSTed and executes
// the reCAPTCHA challenge for the user.
export default ({ dispatch, getState }) => (next) => (action) => {
	if (action.type === POST_USER_REQUEST && isProdOrStaging()) {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(() => {
				window.grecaptcha
					.execute(GOOGLE_RECAPTCHA_SITE_ID, {
						action: 'create_user',
					})
					.then((token) => {
						action.request.body['recaptcha_version'] = 3;
						action.request.body['recaptcha_action'] = 'create_user';
						action.request.body['g-recaptcha-response'] = token;

						return next(action)
							.then((res) => {
								if (res.status === 201 && res.success) {
									return resolve(res);
								}
							})
							.catch((err) => {
								return reject(err);
							});
					});
			});
		});
	} else {
		return next(action);
	}
};
