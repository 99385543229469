import {
	GET_INVESTOR_APPLICATION_SUCCESS,
	PATCH_INVESTOR_APPLICATION_SUCCESS,
	PATCH_INVESTOR_APPLICATION_REOPEN_SUCCESS,
	GET_RETIRE_APPLICATION_SUCCESS,
	PATCH_RETIRE_APPLICATION_SUCCESS,
	PATCH_RETIRE_APPLICATION_REOPEN_SUCCESS,
} from 'stash/actions/api/investorApplication';

import { POST_USER_SUCCESS } from 'stash/actions/api/user';
import { isEmpty } from 'stash/utils/helpers';

const investor_application = (state = {}, action) => {
	switch (action.type) {
		case POST_USER_SUCCESS:
		case GET_INVESTOR_APPLICATION_SUCCESS:
			return {
				...state,
				...action.response.investor_application,
			};

		case PATCH_INVESTOR_APPLICATION_SUCCESS:
		case PATCH_INVESTOR_APPLICATION_REOPEN_SUCCESS:
			return {
				...state,
				...action.response.investor_application,
			};

		case GET_RETIRE_APPLICATION_SUCCESS:
			return {
				...state,
				retire_questions: action.response.retire_questions,
				retire_constants: action.response.retire_constants,
			};

		case PATCH_RETIRE_APPLICATION_SUCCESS: {
			const update = {};
			const filing_status =
				action?.request?.body?.investor_application?.filing_status ?? null;
			const household_income_bracket =
				action?.request?.body?.investor_application?.household_income_bracket ??
				null;

			const eligible_account_types =
				action?.response?.eligible_account_types ?? null;

			const retire_question = action?.response?.retire_question ?? {};

			if (filing_status) {
				update.filing_status = filing_status;
			}

			if (household_income_bracket) {
				update.household_income_bracket = household_income_bracket;
			}

			if (eligible_account_types) {
				update.eligible_account_types = eligible_account_types;

				// only add if retire question has data. This allows for navigating
				// back after initial question tax filing question, otherwise it gets
				// replaced with an empty object
				if (!isEmpty(retire_question)) {
					update.retire_question = retire_question;
				}
			}

			return {
				...state,
				...update,
			};
		}

		case PATCH_RETIRE_APPLICATION_REOPEN_SUCCESS: {
			const filing_status =
				action?.request?.body?.investor_application?.filing_status ?? null;
			const household_income_bracket =
				action?.request?.body?.investor_application?.household_income_bracket ??
				null;

			return {
				...state,
				filing_status,
				household_income_bracket,
			};
		}

		default:
			return state;
	}
};

export default investor_application;
