import { fetchRequestWithTypes } from './index.js';

export const CREATE_NUDATA_SESSION_REQUEST = 'CREATE_NUDATA_SESSION_REQUEST';
export const CREATE_NUDATA_SESSION_SUCCESS = 'CREATE_NUDATA_SESSION_SUCCESS';
export const CREATE_NUDATA_SESSION_ERRORED = 'CREATE_NUDATA_SESSION_ERRORED';

export const getCreateNudataSession = () => {
	return fetchRequestWithTypes({
		types: [
			CREATE_NUDATA_SESSION_REQUEST,
			CREATE_NUDATA_SESSION_SUCCESS,
			CREATE_NUDATA_SESSION_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/customers/v1/identities/devices/sessions',
		},
	});
};
