import { createStore as reduxCreateStore } from 'redux';
import createRootReducer from 'stash/reducers';
import { createMiddleware } from 'stash/middleware';
import { init } from 'stash/actions/init';
import { hydrateFromCookie } from './hydrate';
import type { AnalyticsBrowser } from '@segment/analytics-next';
import type { History } from 'history';

type StoreDependencies = {
	history: History;
	analytics: AnalyticsBrowser;
	initialState?: ReturnType<typeof hydrateFromCookie>;
};

export function initializeStore({
	history,
	analytics,
}: StoreDependencies) {
	const store = reduxCreateStore(
		createRootReducer(history),
        hydrateFromCookie(),
		createMiddleware({ history, analytics })
	);

	store.dispatch(init());

	return store;
}

