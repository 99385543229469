import {
	DASHBOARD_READY,
	DASHBOARD_NOT_READY,
} from '../../actions/dashboard.js';

export default (state = false, action) => {
	switch (action.type) {
		case DASHBOARD_READY:
			return true;
		case DASHBOARD_NOT_READY:
			return false;
		default:
			return state;
	}
};
