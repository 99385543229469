import { SET_NUDATA_SESSION } from 'stash/actions/nudata';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_NUDATA_SESSION: {
			return {
				...state,
				session: action.nudataSession,
			};
		}

		default:
			return state;
	}
};
