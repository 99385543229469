/**
 * This middleware handles promo codes to be injected in
 * the POST request when a user is created. Promo codes can
 * be stored in cookies or from the Branch.io service.
 */

import { setCookie, getCookie, deleteCookie } from 'stash/utils/cookies';
import { POST_USER_REQUEST } from 'stash/actions/api/user';
import { STASH_INIT } from 'stash/actions/init';
import { getURLParam } from 'stash/utils/urls';
import { GET_REFERRAL_CUSTOM_LANDING_ERRORED } from 'stash/actions/api';

export const PROMO_COOKIE_NAME = 'stashPromoCode';
export const PROMO_PARAM_NAME = 'stash_code';

export const getStashPromoCode = () => {
	return getCookie(PROMO_COOKIE_NAME);
};

export const hasStashPromoCode = () => {
	return !!getCookie(PROMO_COOKIE_NAME);
};

export const doesNotHaveStashPromoCode = () => {
	return !getCookie(PROMO_COOKIE_NAME);
};

export default ({ dispatch, getState }) => (next) => (action) => {
	// Promotion is invalid / expired so delete cookie.
	if (action.type === GET_REFERRAL_CUSTOM_LANDING_ERRORED) {
		deleteCookie(PROMO_COOKIE_NAME);
	}

	// Persist promocode or branch code in a cookie on load.
	if (action.type === STASH_INIT) {
		if (getURLParam(PROMO_PARAM_NAME)) {
			setCookie(PROMO_COOKIE_NAME, getURLParam(PROMO_PARAM_NAME));
		}
	}

	// Get promocode from cookies or branch on a new user POST.
	if (action.type === POST_USER_REQUEST) {
		const stashPromoCode = getCookie(PROMO_COOKIE_NAME);

		return next({
			...action,
			request: {
				...action.request,
				body: {
					...action.request.body,
					referral_source_code: stashPromoCode,
				},
			},
		});
	}

	return next(action);
};
