export default [
	['/manage-account', 'ManageAccount'],
	['/manage-bank-account', 'BankAccounts'],
	['/ira-close-account', 'IraCloseAccountModal'],
	['/account-settings', 'AccountSettings'],
	['/plan-details', 'SubscriptionDetails'],
	['/manage-subscription', 'ManageSubscription'],
	['/security', 'Security'],
	['/security/change-phone-number', 'ChangePhoneNumber'],
	['/security/change-email', 'ChangeEmail'],
	['/security/phone-verification', 'PhoneVerification'],
	['/referrals/new', 'ReferralFormModal'],
	['/referrals/complete', 'ReferralCompleteModal'],
	['/referrals/agreement', 'ReferralAgreementModal'],
	['/documents', 'Documents'],
	['/tax-documents', 'TaxDocuments'],
	['/statements', 'Statements'],
	['/trade-confirmations', 'TradeConfirmations'],
	['/activity', 'Activity'],
	['/investments_lending', 'SecuritiesLending'],
	['/promotion-code', 'PromotionCode'],
	['/notification-settings', 'NotificationContainer'],
	['/accessibility-settings', 'AccessibilitySettings'],
	['/market-insights-report', 'MarketInsightsReport'],
	['/investor-profile', 'UpdateInvestorApplication'],
	['/cancel-stash/:screen?', 'CloseAccountContainer'],
	['/dividend-reinvestment-program', 'Drip'],
	['/change-billing-frequency', 'BillingFrequencyChooser'],
	['/data-sharing', 'DataSharing'],
	['/add-payment-method', 'PaymentMethodOptions'],
];
