import { sendMixpanel } from '../../events/utils';

const trackRecurringTransfer = ({ ScreenName, Action }) => () => {
	sendMixpanel('RecurringTransfer', {
		ScreenName,
		Action,
	});
};

export const setUpRecurring = trackRecurringTransfer({
	ScreenName: 'RecurringTransferSetup',
});

export const confirmRecurring = trackRecurringTransfer({
	ScreenName: 'RecurringTransferConfirm',
});

export const updateRecurring = trackRecurringTransfer({
	ScreenName: 'RecurringTransferUpdate',
});

export const scheduledRecurring = trackRecurringTransfer({
	ScreenName: 'RecurringTransferScheduled',
});

export const recurringTransferScreenLink = trackRecurringTransfer({
	ScreenName: 'RecurringTransfer',
	Action: 'RecurringTransferLink',
});

export const setUpCta = trackRecurringTransfer({
	ScreenName: 'RecurringTransferSetup',
	Action: 'RecurringTransferSetupCTA',
});

export const confirmCta = trackRecurringTransfer({
	ScreenName: 'RecurringTransferConfirm',
	Action: 'RecurringTransferConfirmCTA',
});

export const updateCta = trackRecurringTransfer({
	ScreenName: 'RecurringTransferUpdate',
	Action: 'RecurringTransferUpdateCTA',
});

export const pauseCta = trackRecurringTransfer({
	ScreenName: 'RecurringTransferUpdate',
	Action: 'RecurringTransferUpdateConfirmPause',
});

export const resumeCta = trackRecurringTransfer({
	ScreenName: 'RecurringTransferUpdate',
	Action: 'RecurringTransferUpdateResumeCTA',
});
