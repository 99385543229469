import queryString from 'query-string';
import {
	FILTER_INVESTMENT_TYPE,
	FILTER_RISK_LEVEL,
	FILTER_GROUP_SLUG,
	DEFAULT_SORT,
	DEFAULT_INVESTMENT_TYPE,
	DEFAULT_PAGE_SIZE,
} from 'stash/constants/investmentFilters';

// Converts varioius query param formats to array
export function parseFilterValues(filterParam, fallback = []) {
	if (!filterParam) return fallback;
	if (typeof filterParam === 'string') return [filterParam];
	return filterParam;
}

export function getParams(search = {}, page = 0) {
	return {
		page,
		page_size: DEFAULT_PAGE_SIZE,
		sort: search.sort || DEFAULT_SORT,
		filter: [
			{
				key: FILTER_INVESTMENT_TYPE,
				values: parseFilterValues(search.investment_type, [
					DEFAULT_INVESTMENT_TYPE,
				]),
			},
			{
				key: FILTER_RISK_LEVEL,
				values: parseFilterValues(search.risk_level),
			},
			{
				key: FILTER_GROUP_SLUG,
				values: parseFilterValues(search.investment_group_slug),
			},
		],
	};
}

export function stringifyParams(search) {
	return queryString.stringify(search, { arrayFormat: 'comma', sort: false });
}

export function getSearchUrl() {
	const search = window.location.search;
	return search.replace(/\?/, '');
}
