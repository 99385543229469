const lazyLoadCache = (window.Stash.lazyLoad = {
	fetched: {},
	success: {},
	error: {},
});

export const lazyLoadScript = (options) => {
	const { src, onLoad, onError, setAsync, setDefer } = options;

	if (lazyLoadCache.success[src]) {
		return onLoad && onLoad();
	}

	if (lazyLoadCache.error[src]) {
		return onError && onError();
	}

	if (lazyLoadCache.fetched[src]) {
		return; // don't load scripts more than once.
	}

	lazyLoadCache.fetched[src] = true;

	const script = document.createElement('script');
	script.src = src;

	setAsync && script.setAttribute('async', setAsync);
	setDefer && script.setAttribute('defer', setDefer);

	script.onload = () => {
		lazyLoadCache.success[src] = true;
		onLoad && onLoad();
	};

	script.onerror = () => {
		lazyLoadCache.error[src] = true;
		onError && onError();
	};

	document.body.appendChild(script);
};

export default lazyLoadScript;
