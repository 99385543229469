import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from 'stash/routes';
import {
	ThemePreferenceProvider,
	ThemeProvider,
	SnackbarProvider,
} from '@stashinvest/ui';
import { BanjoProvider } from '@stashinvest/react-banjo';
import { isProd } from 'stash/utils';
import { languageMap } from 'banjo';
import {
	getLaunchDarklyProvider,
	ExperimentsContextUpdater,
} from 'stash/components/ab-testing';
import { SdsNavProvider } from '@stashinvest/react-components';
import { Link } from 'react-router-dom';
import setAnalyticCookies from './stash/utils/instrumentation/setAnalyticCookies';
import { getAnalyticsInstance } from 'stash/initializers/analytics';
import { AnalyticsProvider } from 'stash/contexts/analytics';
import { initializeStore } from 'stash/initializers/store';
import { createBrowserHistory } from 'history';

const isLocalhost = window.Stash.env === '__STASH_ENV__';

// If you need to change API locally, update your .env file.
if (!isProd() && !isLocalhost) {
	const customAPI = localStorage && localStorage.getItem('stashAPI');

	if (customAPI) {
		window.Stash.CUSTOM_API_PATH = customAPI;
	}
}

(function ($, T, A, S, H) {
	$[S] = $[S] || [];
	$[S].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
	var f = T.getElementsByTagName(A)[0],
		auth = $.location.host.match(/app-/)
			? '&gtm_auth=znL2FsNpfpodk-Q9depotQ&gtm_preview=env-3&gtm_cookies_win=x'
			: '',
		j = T.createElement(A),
		dl = S !== 'dataLayer' ? '&l=' + S : '';
	j.async = true;
	j.src = 'https://www.googletagmanager.com/gtm.js?id=' + H + dl + auth;
	f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-KCW5WQ');

function App() {
	const analytics = getAnalyticsInstance();
	const history = createBrowserHistory();
	const store = initializeStore({ history, analytics });

	return (
		<AnalyticsProvider analytics={analytics}>
			<Provider store={store}>
				<ExperimentsContextUpdater />
				<SdsNavProvider linkComponent={Link}>
					<ThemePreferenceProvider>
						<ThemeProvider>
							<BanjoProvider
								defaultLocale="en-us"
								languageMap={languageMap}
								variants={[]}
							>
								<SnackbarProvider>
									<Router history={history} />
								</SnackbarProvider>
							</BanjoProvider>
						</ThemeProvider>
					</ThemePreferenceProvider>
				</SdsNavProvider>
			</Provider>
		</AnalyticsProvider>
	);
}

(async () => {
	setAnalyticCookies();
	const LaunchDarklyProvider = await getLaunchDarklyProvider();

	ReactDOM.render(
		<LaunchDarklyProvider>
			<App />
		</LaunchDarklyProvider>,
		document.getElementById('root')
	);
})();
