import {
	GET_ACCOUNT_HISTORY_SUCCESS,
	REFRESH_ACCOUNT_HISTORY_SUCCESS,
} from '../../actions/api/account';

const populateFields = (items) => {
	items.forEach((item) => {
		item.details.fieldsIndex = item.details.fields.reduce((obj, f) => {
			obj[f.field] = f.value;
			return obj;
		}, {});
	});
};

function mergeSectionItems(existingItems, newItems) {
	const existingItemIds = existingItems.map((i) => i.id);

	return newItems.reduce((acc, item) => {
		if (!existingItemIds.includes(item.id)) acc.push(item);
		return acc;
	}, existingItems);
}

const accountHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ACCOUNT_HISTORY_SUCCESS: {
			const existingAccountHistory = state[action.response.account_id] || [];

			const merged = action.response.account_history.reduce(
				(accountHistory, newItem) => {
					const section = accountHistory.find(
						(existingItem) =>
							existingItem.section_title === newItem.section_title
					);

					// Add fields object to each item's details
					populateFields(newItem.items);

					// merge section items
					section
						? (section.items = mergeSectionItems(section.items, newItem.items))
						: accountHistory.push(newItem);

					return accountHistory;
				},
				existingAccountHistory
			);

			return {
				...state,
				[action.response.account_id]: merged,
			};
		}

		case REFRESH_ACCOUNT_HISTORY_SUCCESS: {
			const accountHistory = action.response.account_history.reduce(
				(accountHistory, newItem) => {
					populateFields(newItem.items);
					accountHistory.push(newItem);
					return accountHistory;
				},
				[]
			);

			return {
				...state,
				[action.response.account_id]: accountHistory,
			};
		}

		default:
			return state;
	}
};

export default accountHistoryReducer;
