import {
	LINK_BANK_COMPLETE,
	LINK_BANK_REISSUE,
	LINK_BANK_REISSUE_COMPLETE,
} from '../../actions/linkBank.js';

export default (state = true, action) => {
	switch (action.type) {
		case LINK_BANK_COMPLETE:
			return 'complete';
		case LINK_BANK_REISSUE:
			return 'reissue';
		case LINK_BANK_REISSUE_COMPLETE:
			return 'reissue-complete';
		default:
			return state;
	}
};
