import { fetchRequestWithTypes } from './index.js';

export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_ERRORED = 'GET_PLANS_ERRORED';

export const getPlans = ({ context = '', partnerType = '' }) => {
	const contextQueryString = `?context=${context}`;
	const partnerTypeQueryString = partnerType ? `&type=${partnerType}` : '';
	return fetchRequestWithTypes({
		types: [GET_PLANS_REQUEST, GET_PLANS_SUCCESS, GET_PLANS_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/plans${contextQueryString}${partnerTypeQueryString}`,
		},
	});
};
