import { fetchRequestWithTypes } from './index.js';

// This endpoint can be used to get questions and answers for the investor application questionnaire
// https://edge.stsh.io/documentation/1.0/investor_applications/questions.html

export const GET_INVESTOR_APPLICATION_QUESTIONS_REQUEST =
	'GET_INVESTOR_APPLICATION_QUESTIONS_REQUEST';
export const GET_INVESTOR_APPLICATION_QUESTIONS_SUCCESS =
	'GET_INVESTOR_APPLICATION_QUESTIONS_SUCCESS';
export const GET_INVESTOR_APPLICATION_QUESTIONS_ERRORED =
	'GET_INVESTOR_APPLICATION_QUESTIONS_ERRORED';

export const getInvestorApplicationQuestions = () =>
	fetchRequestWithTypes({
		types: [
			GET_INVESTOR_APPLICATION_QUESTIONS_REQUEST,
			GET_INVESTOR_APPLICATION_QUESTIONS_SUCCESS,
			GET_INVESTOR_APPLICATION_QUESTIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/investor_applications/questions',
		},
	});

// GET an investor application.

export const GET_INVESTOR_APPLICATION_REQUEST =
	'GET_INVESTOR_APPLICATION_REQUEST';
export const GET_INVESTOR_APPLICATION_SUCCESS =
	'GET_INVESTOR_APPLICATION_SUCCESS';
export const GET_INVESTOR_APPLICATION_ERRORED =
	'GET_INVESTOR_APPLICATION_ERRORED';

export const getInvestorApplication = () =>
	fetchRequestWithTypes({
		types: [
			GET_INVESTOR_APPLICATION_REQUEST,
			GET_INVESTOR_APPLICATION_SUCCESS,
			GET_INVESTOR_APPLICATION_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/investor_application',
		},
	});

// PATCH an investor application.

export const PATCH_INVESTOR_APPLICATION_REQUEST =
	'PATCH_INVESTOR_APPLICATION_REQUEST';
export const PATCH_INVESTOR_APPLICATION_SUCCESS =
	'PATCH_INVESTOR_APPLICATION_SUCCESS';
export const PATCH_INVESTOR_APPLICATION_ERRORED =
	'PATCH_INVESTOR_APPLICATION_ERRORED';

export const patchInvestorApplication = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_INVESTOR_APPLICATION_REQUEST,
			PATCH_INVESTOR_APPLICATION_SUCCESS,
			PATCH_INVESTOR_APPLICATION_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/investor_application',
			body,
		},
	});

// Updates a user's investor profile in the closed state
// https://edge.stsh.io/documentation/1.0/investor_applications/update_on_reopen.html

export const PATCH_INVESTOR_APPLICATION_REOPEN_REQUEST =
	'PATCH_INVESTOR_APPLICATION_REOPEN_REQUEST';
export const PATCH_INVESTOR_APPLICATION_REOPEN_SUCCESS =
	'PATCH_INVESTOR_APPLICATION_REOPEN_SUCCESS';
export const PATCH_INVESTOR_APPLICATION_REOPEN_ERRORED =
	'PATCH_INVESTOR_APPLICATION_REOPEN_ERRORED';

export const patchInvestorApplicationReopen = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_INVESTOR_APPLICATION_REOPEN_REQUEST,
			PATCH_INVESTOR_APPLICATION_REOPEN_SUCCESS,
			PATCH_INVESTOR_APPLICATION_REOPEN_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/investor_applications/reopen',
			body,
		},
	});

// Returns all retire specific application questions.
// https://staging.stsh.io/documentation/1.0/investor_applications/retire_questions.html

export const GET_RETIRE_APPLICATION_REQUEST = 'GET_RETIRE_APPLICATION_REQUEST';
export const GET_RETIRE_APPLICATION_SUCCESS = 'GET_RETIRE_APPLICATION_SUCCESS';
export const GET_RETIRE_APPLICATION_ERRORED = 'GET_RETIRE_APPLICATION_ERRORED';

export const getRetireApplication = () =>
	fetchRequestWithTypes({
		types: [
			GET_RETIRE_APPLICATION_REQUEST,
			GET_RETIRE_APPLICATION_SUCCESS,
			GET_RETIRE_APPLICATION_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/investor_applications/retire_questions',
		},
	});

// Updates the retire specific questions of a user's investor application.
// https://staging.stsh.io/documentation/1.0/investor_applications/update_retire.html

export const PATCH_RETIRE_APPLICATION_REQUEST =
	'PATCH_RETIRE_APPLICATION_REQUEST';
export const PATCH_RETIRE_APPLICATION_SUCCESS =
	'PATCH_RETIRE_APPLICATION_SUCCESS';
export const PATCH_RETIRE_APPLICATION_ERRORED =
	'PATCH_RETIRE_APPLICATION_ERRORED';

export const patchRetireApplication = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_RETIRE_APPLICATION_REQUEST,
			PATCH_RETIRE_APPLICATION_SUCCESS,
			PATCH_RETIRE_APPLICATION_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/investor_applications/update_retire',
			body,
		},
	});

// Updates the retire specific questions of a user's investor application.
// https://staging.stsh.io/documentation/1.0/investor_applications/update_retire_reopen.html

export const PATCH_RETIRE_APPLICATION_REOPEN_REQUEST =
	'PATCH_RETIRE_APPLICATION_REOPEN_REQUEST';
export const PATCH_RETIRE_APPLICATION_REOPEN_SUCCESS =
	'PATCH_RETIRE_APPLICATION_REOPEN_SUCCESS';
export const PATCH_RETIRE_APPLICATION_REOPEN_ERRORED =
	'PATCH_RETIRE_APPLICATION_REOPEN_ERRORED';

export const patchRetireApplicationReopen = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_RETIRE_APPLICATION_REOPEN_REQUEST,
			PATCH_RETIRE_APPLICATION_REOPEN_SUCCESS,
			PATCH_RETIRE_APPLICATION_REOPEN_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/investor_applications/update_retire_reopen',
			body,
		},
	});
