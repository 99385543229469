import React from 'react';
import { Box, PrimaryButton, Flex, Text } from '@stashinvest/ui';

const handleReload = () => {
	if (window.location.search && window.location.search.includes('error')) {
		window.location.replace(window.location.origin);
	} else {
		window.location.reload();
	}
};

function ErrorMessageComponent(props) {
	return (
		<Flex
			flexDirection="column"
			minHeight="95vh"
			justifyContent="center"
			alignItems="center"
		>
			<Box align="center" maxWidth="28rem" width="100%">
				<span
					style={{ fontSize: '3.75rem' }}
					role="img"
					aria-label="thinking face"
				>
					🤔
				</span>
				<Text.H1 size="3" mb="m">
					Whoops, something went wrong
				</Text.H1>
				<Text mb="m">
					Try reloading or clearing your browser&#8217;s history or cache and
					logging back in.
				</Text>
			</Box>
			<Box align="center" maxWidth="28rem" width="100%">
				<PrimaryButton type="button" mb="m" onClick={handleReload}>
					Reload Stash
				</PrimaryButton>
			</Box>
		</Flex>
	);
}

export default ErrorMessageComponent;
