/**
 * This middleware fires events to UserLeap
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import {
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_SUCCESS,
	POST_USER_SUCCESS,
	LOG_OUT,
} from 'stash/actions';
import { tryWarn } from 'stash/utils';
import { sendUserLeapEvent, trackUserLeapEvent } from 'stash/events/utils';

const identifyUser = tryWarn((uuid) => {
	sendUserLeapEvent('setUserId', uuid);
});

const logoutUser = tryWarn(() => {
	window.UserLeap.logoutUser();
});

const trackLocationChange = tryWarn((pathname) => {
	switch (pathname) {
		case '/invest':
			trackUserLeapEvent('screen_view_invest_home');
			break;
		case '/debit':
			trackUserLeapEvent('screen_view_bank_home');
			break;
		case '/portfolio/diversification-score/recommendations':
			trackUserLeapEvent('screen_view_diversification_score_recommendations');
			break;
		case '/debit/stockBack':
			trackUserLeapEvent('screen_view_debit_stock_back');
			break;
		case '/subscriber-advice/welcome':
			trackUserLeapEvent('screen_view_content_moat_home');
			break;
		default:
			break;
	}
});

const userLeapMiddleware =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		switch (action.type) {
			case LOG_OUT:
				logoutUser();
				break;
			case GET_USER_SUCCESS:
			case POST_USER_SUCCESS:
			case GET_LAUNCH_INFO_SUCCESS:
				identifyUser(action.response.user.uuid);
				break;
			case LOCATION_CHANGE:
				trackLocationChange(action.payload.location.pathname);
				break;
			default:
				break;
		}

		return next(action);
	};

export default userLeapMiddleware;
