import { GET_BANK_CONVERSION_SUCCESS } from 'stash/actions/api/buffet';

const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_BANK_CONVERSION_SUCCESS:
			const { conversion } = action.response;

			return conversion;

		default:
			return state;
	}
};
