import {
	v1_MyStash_Clicked,
	v1_MyGoals_Clicked,
	v1_Transfer_Clicked,
	v1_Home_Clicked,
} from '@stashinvest/product-events-javascript/lib/v1/event_static_methods';
import {
	ArrowsLeftRightIcon,
	ChartLineIcon,
	HouseIcon,
	TargetIcon,
} from '@stashinvest/react-assets';
import { navTransferTab } from 'stash/components/global/events';

export const HOME_NAV_OPTION = {
	event: v1_Home_Clicked,
	banjoId: 'navigation/navigate/home',
	urlMatches: ['/$'],
	railOption: { Icon: HouseIcon, to: '/' },
};

export const MY_GOALS_NAV_OPTION = {
	event: v1_MyGoals_Clicked,
	banjoId: 'navigation/navigate/goals',
	urlMatches: ['/goals*'],
	railOption: { Icon: TargetIcon, to: '/goals' },
};

export const TRANSFER_NAV_OPTION = {
	event: v1_Transfer_Clicked,
	banjoId: 'navigation/navigate/transfer',
	urlMatches: ['/transfer*'],
	railOption: {
		Icon: ArrowsLeftRightIcon,
		to: '/transfer',
		onClick: navTransferTab,
	},
};
export const MY_STASH_NAV_OPTION = {
	event: v1_MyStash_Clicked,
	banjoId: 'navigation/navigate/myStash',
	urlMatches: ['/my-stash'],
	railOption: {
		Icon: ChartLineIcon,
		to: '/my-stash',
	},
};

export const defaultNavOptions = [
	HOME_NAV_OPTION,
	MY_STASH_NAV_OPTION,
	MY_GOALS_NAV_OPTION,
	TRANSFER_NAV_OPTION,
];
