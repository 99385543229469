import {
	GET_TAX_DOCUMENTS_SUCCESS,
	GET_PX_TAX_DOCUMENTS_SUCCESS,
} from '../../actions/api/clearing';

const sortDescendingByYear = (a, b) => b.year - a.year;

export default (state = {}, action) => {
	switch (action.type) {
		case GET_PX_TAX_DOCUMENTS_SUCCESS:
			return {
				...state,
				[action.response.accountId]: {
					...state[action.response.accountId],
					px_documents: action.response.documents || [],
				},
			};
		case GET_TAX_DOCUMENTS_SUCCESS:
			return {
				...state,
				[action.response.account_id]: {
					documents: action.response.tax_documents.slice().sort(sortDescendingByYear),
					warning: action.response.warning,
					warning_url: action.response.warning_url,
				},
			};
		default:
			return state;
	}
};
