export const BASE_URLS = {
	__STASH_ENV__: 'https://login-edge.stash.com',
	edge: 'https://login-edge.stash.com',
	staging: 'https://login-staging.stash.com',
	production: 'https://login.stash.com',
	stockton_dev: 'https://login-staging.stash.com',
};

export const CLIENT_IDS = {
	__STASH_ENV__: '26df0baf-d961-4d19-b95c-f150931a7b2b',
	edge: 'f29b429f-5483-42e5-8ba6-4aeb02d5d3a9',
	staging: 'e9c5b819-702a-4617-ac77-4c82723df5b7',
	production: '92652692-61cc-48f6-9d41-3031f3031edc',
	stockton_dev: '00ffe299-5a99-4c3b-bf20-11d8d2afa184',
};

export const REDIRECT_URLS = {
	__STASH_ENV__: 'https://app-local.stash.com:57454/authorize',
	edge: 'https://app-edge.stash.com/authorize',
	staging: 'https://app-staging.stash.com/authorize',
	production: 'https://app.stash.com/authorize',
	stockton_dev: 'https://app-local.stash.com:57454/authorize',
};
