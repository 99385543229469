import { CLEAR_TOAST, TOAST_ERROR, TOAST_SUCCESS } from 'stash/actions';

export default (state = null, action) => {
	switch (action.type) {
		case TOAST_SUCCESS: {
			return {
				type: 'success',
				message: action.message,
				icon: action.icon,
			};
		}
		case TOAST_ERROR: {
			return {
				type: 'error',
				message: action.message,
			};
		}
		case CLEAR_TOAST: {
			return null;
		}
		default:
			return state;
	}
};
