import { fetchRequestWithTypes } from './index';
import { stringToNumberConverter } from 'stash/utils/formatters';

export const GET_TRANSFER_SOURCES_REQUEST = 'GET_TRANSFER_SOURCES_REQUEST';
export const GET_TRANSFER_SOURCES_SUCCESS = 'GET_TRANSFER_SOURCES_SUCCESS';
export const GET_TRANSFER_SOURCES_ERRORED = 'GET_TRANSFER_SOURCES_ERRORED';

export const getTransferSources = () =>
	fetchRequestWithTypes({
		types: [
			GET_TRANSFER_SOURCES_REQUEST,
			GET_TRANSFER_SOURCES_SUCCESS,
			GET_TRANSFER_SOURCES_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/sources`,
		},
	});

export const GET_TRANSFER_DESTINATIONS_REQUEST =
	'GET_TRANSFER_DESTINATIONS_REQUEST';
export const GET_TRANSFER_DESTINATIONS_SUCCESS =
	'GET_TRANSFER_DESTINATIONS_SUCCESS';
export const GET_TRANSFER_DESTINATIONS_ERRORED =
	'GET_TRANSFER_DESTINATIONS_ERRORED';

export const getTransferDestinations = (sourceUid) =>
	fetchRequestWithTypes({
		types: [
			GET_TRANSFER_DESTINATIONS_REQUEST,
			GET_TRANSFER_DESTINATIONS_SUCCESS,
			GET_TRANSFER_DESTINATIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/sources/${sourceUid}/destinations`,
		},
	});

export const GET_TRANSFER_PREFILL_REQUEST = 'GET_TRANSFER_PREFILL_REQUEST';
export const GET_TRANSFER_PREFILL_SUCCESS = 'GET_TRANSFER_PREFILL_SUCCESS';
export const GET_TRANSFER_PREFILL_ERRORED = 'GET_TRANSFER_PREFILL_ERRORED';

export const getTransferPrefill = (prefillType) =>
	fetchRequestWithTypes({
		types: [
			GET_TRANSFER_PREFILL_REQUEST,
			GET_TRANSFER_PREFILL_SUCCESS,
			GET_TRANSFER_PREFILL_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/prefill?type=${prefillType}`,
		},
	});

export const CONFIRM_TRANSFER_REQUEST = 'CONFIRM_TRANSFER_REQUEST';
export const CONFIRM_TRANSFER_SUCCESS = 'CONFIRM_TRANSFER_SUCCESS';
export const CONFIRM_TRANSFER_ERRORED = 'CONFIRM_TRANSFER_ERRORED';

export const confirmTransfer = ({
	source,
	destination,
	amount,
	transferType = 'amount',
}) => {
	const transfer = {
		transfer: {
			sourceUri: source.uri,
			destinationUri: destination.uri,
			amount: {
				value: stringToNumberConverter(amount.value),
				currency: amount.currency,
			},
			transferType,
		},
	};

	return fetchRequestWithTypes({
		types: [
			CONFIRM_TRANSFER_REQUEST,
			CONFIRM_TRANSFER_SUCCESS,
			CONFIRM_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/confirm`,
			body: transfer,
		},
	});
};

export const INIT_TRANSFER_REQUEST = 'INIT_TRANSFER_REQUEST';
export const INIT_TRANSFER_SUCCESS = 'INIT_TRANSFER_SUCCESS';
export const INIT_TRANSFER_ERRORED = 'INIT_TRANSFER_ERRORED';

export const initiateTransfer = ({
	source,
	destination,
	amount,
	transferType = 'amount',
}) => {
	const transfer = {
		transfer: {
			sourceUri: source.uri,
			destinationUri: destination.uri,
			amount: {
				value: stringToNumberConverter(amount.value),
				currency: amount.currency,
			},
			transferType,
		},
	};

	return fetchRequestWithTypes({
		types: [
			INIT_TRANSFER_REQUEST,
			INIT_TRANSFER_SUCCESS,
			INIT_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers`,
			body: transfer,
		},
	});
};
