export const EXPAND_INVEST_OVERVIEW_LIST = 'EXPAND_INVEST_OVERVIEW_LIST';

export const expandList = () => ({
	type: EXPAND_INVEST_OVERVIEW_LIST,
});

export const COLLAPSE_INVEST_OVERVIEW_LIST = 'COLLAPSE_INVEST_OVERVIEW_LIST';

export const collapseList = () => ({
	type: COLLAPSE_INVEST_OVERVIEW_LIST,
});

export const HIDE_LINK_BANK_SUCCESS = 'HIDE_LINK_BANK_SUCCESS';
