import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout, PrimaryButton, Box, SmartLink } from '@stashinvest/ui';
import {
	valuePropScreenView,
	valuePropCtaClick,
} from 'stash/actions/debitSignUpTrackingEvents';
import HeroSection from './HeroSection';
import StockbackFeedSection from './StockbackFeedSection';
import DisclosureSection from './DisclosureSection';
import { StatementsTile } from '../statementsTile';
import BANK_ACCOUNT_PARTNER_TYPE from '../../../../constants/partnerTypes';
import { AccountStatus } from '../../../bank/BankConstants';
import { BanjoString } from '@stashinvest/react-banjo';

const mapStateToProps = (state) => ({
	account: state?.entities?.bank?.debit_accounts,
});

const mapDispatchToProps = {
	valuePropScreenView,
	valuePropCtaClick,
};

export function ValuePropScreen(props) {
	const { valuePropScreenView, valuePropCtaClick, account } = props;

	useEffect(() => {
		valuePropScreenView();
	}, [valuePropScreenView]);

	const isClosedGreenDotUser =
		account &&
		account[0]?.partnerType === BANK_ACCOUNT_PARTNER_TYPE.GREENDOT &&
		account[0]?.status === AccountStatus.Closed;

	return (
		<Layout.PageWrap>
			<HeroSection />
			{isClosedGreenDotUser && <StatementsTile />}
			<Box width="100%" maxWidth="500px" mx="auto">
				<PrimaryButton as={SmartLink} to="/sign-up-debit" handleClick={valuePropCtaClick}>
					<BanjoString stringId={'action/startTask/getStockBackCard'} />
				</PrimaryButton>
			</Box>
			<StockbackFeedSection />

			<DisclosureSection />
		</Layout.PageWrap>
	);
}

ValuePropScreen.defaultProps = {
	valuePropScreenView: () => {},
	valuePropCtaClick: () => {},
};

ValuePropScreen.propTypes = {
	valuePropScreenView: PropTypes.func.isRequired,
	valuePropCtaClick: PropTypes.func.isRequired,
	account: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(ValuePropScreen);
