// Open/Close the side drawer for account settings on mobile.

export const OPEN_DRAWER = 'OPEN_DRAWER';

export const openDrawer = () => ({
	type: OPEN_DRAWER,
});

export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const closeDrawer = () => ({
	type: CLOSE_DRAWER,
});
