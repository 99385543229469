export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
export const setSearchQuery = (query) => ({
	type: SET_SEARCH_QUERY,
	query,
});

export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const clearSearchResults = (query) => ({
	type: CLEAR_SEARCH_RESULTS,
	query,
});
