export const DEFER_ACTION = 'DEFER_ACTION';

export const deferAction = (actionKey, deferredAction) => ({
	type: DEFER_ACTION,
	actionKey,
	deferredAction,
});

export const CLEAR_ACTION = 'CLEAR_ACTION';

export const clearAction = (actionKey) => ({
	type: CLEAR_ACTION,
	actionKey,
});
