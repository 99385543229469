const edge = '5c4b418a30f553456b19390f';
const staging = '5c4b419b30f553456b193917';
const production = '5c4b41a40a0edd4545baef6a';

export const LAUNCH_DARKLY_CLIENT_IDS = {
	edge,
	staging,
	production,

	__STASH_ENV__: edge,
	stockton_dev: staging,
};
