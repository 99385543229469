import { HOME, REG_CONTENT_HOME } from 'stash/constants/launchInfo';

export const selectIsContentMoat = (state) => {
	const launchInfoLocation = state?.entities?.launch_info?.location;
	return launchInfoLocation === REG_CONTENT_HOME;
};

export const selectIsHome = (state) => {
	const launchInfoLocation = state?.entities?.launch_info?.location;
	return launchInfoLocation === HOME;
};
