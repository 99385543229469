import { GET_INVEST_ACCOUNTS_SUCCESS } from '../../actions/api/investAccounts';

const invest_accounts = (state = {}, action) => {
	switch (action.type) {
		case GET_INVEST_ACCOUNTS_SUCCESS:
			const accountsById = action.response.accounts.reduce((acc, current) => {
				acc[current.id] = current;
				return acc;
			}, {});

			return {
				...state,
				...accountsById,
			};
		default:
			return state;
	}
};

export default invest_accounts;
