import {
	GET_AUTO_STASH_OVERVIEW_SUCCESS,
	GET_STRATEGY_TRANSFERS_SUCCESS,
} from 'stash/actions';

const initialState = {
	strategies: {},
	strategyTransfers: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_AUTO_STASH_OVERVIEW_SUCCESS:
			const strategies = action?.response?.brokerage?.strategies ?? {};
			const lifetimeSaved = action?.response?.brokerage?.lifetimeSaved ?? {};
			return {
				...state,
				strategies,
				lifetimeSaved,
			};
		case GET_STRATEGY_TRANSFERS_SUCCESS:
			return {
				...state,
				strategyTransfers: {
					[action.strategyName]: action.response.strategyTransfers,
				},
			};

		default:
			return state;
	}
};
