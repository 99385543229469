import {
	GET_USER_SUCCESS,
	POST_USER_SUCCESS,
	GET_LAUNCH_INFO_SUCCESS,
} from '../../actions/api';
import { POST_ACCOUNT_SUCCESS } from '../../actions/api/account';
import { ALLOWS_MULTIPLE_ACCOUNTS } from '../../constants/accountTypes';

// Returns an array of account ids for a given account type if that account type
// supports multiple accounts. Otherwise, it returns a single id.
const getAccountIds = (account, existingIndex = []) => {
	if (ALLOWS_MULTIPLE_ACCOUNTS[account.type]) {
		return [account.id, ...existingIndex];
	}

	return account.id;
};

export default (state = {}, action) => {
	switch (action.type) {
		case POST_USER_SUCCESS:
		case GET_USER_SUCCESS:
		case GET_LAUNCH_INFO_SUCCESS:
			return action.response.accounts.reduce((accountsByType, account) => {
				accountsByType[account.type] = getAccountIds(
					account,
					accountsByType[account.type]
				);
				return accountsByType;
			}, {});

		case POST_ACCOUNT_SUCCESS: {
			const account = action.response.account;

			return {
				...state,
				[account.type]: getAccountIds(account, state[account.type]),
			};
		}

		default:
			return state;
	}
};
