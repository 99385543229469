import { GET_SUBSCRIPTION_ACTION_SUCCESS } from 'stash/actions/api/platformTiers';

export default (state = [], action) => {
	switch (action.type) {
		case GET_SUBSCRIPTION_ACTION_SUCCESS:
			if (action.response.subscriptionActions.length > 0) {
				return action.response.subscriptionActions[0];
			}

			return state;
		default:
			return state;
	}
};
