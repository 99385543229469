import { fetchRequestWithTypes } from './index.js';

// Searches for cards that match search terms and returns them.
// https://staging.stsh.io/documentation/1.0/cards/search.html

export const SEARCH_CARDS_REQUEST = 'SEARCH_CARDS_REQUEST';
export const SEARCH_CARDS_SUCCESS = 'SEARCH_CARDS_SUCCESS';
export const SEARCH_CARDS_ERRORED = 'SEARCH_CARDS_ERRORED';

export const searchCards = (searchQuery) => {
	return fetchRequestWithTypes({
		types: [SEARCH_CARDS_REQUEST, SEARCH_CARDS_SUCCESS, SEARCH_CARDS_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/cards/search?name=${searchQuery}`,
		},
	});
};

// Returns a collection of Trending Searches.
// https://staging.stsh.io/documentation/1.0/trending_searches/index.html

export const GET_TRENDING_SEARCHES_REQUEST = 'GET_TRENDING_SEARCHES_REQUEST';
export const GET_TRENDING_SEARCHES_SUCCESS = 'GET_TRENDING_SEARCHES_SUCCESS';
export const GET_TRENDING_SEARCHES_ERRORED = 'GET_TRENDING_SEARCHES_ERRORED';

export const getTrendingSearches = (searchQuery) => {
	return fetchRequestWithTypes({
		types: [
			GET_TRENDING_SEARCHES_REQUEST,
			GET_TRENDING_SEARCHES_SUCCESS,
			GET_TRENDING_SEARCHES_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/cards/search?name=${searchQuery}`,
		},
	});
};
