import { fetchRequestWithTypes } from './index.js';

// https://edge.stsh.io/documentation/1.0/guidance_questions/index.html
// https://edge.stsh.io/documentation/1.0/user_guidance/update.html

export const GET_GUIDANCE_QUESTIONS_REQUEST = 'GET_GUIDANCE_QUESTIONS_REQUEST';
export const GET_GUIDANCE_QUESTIONS_SUCCESS = 'GET_GUIDANCE_QUESTIONS_SUCCESS';
export const GET_GUIDANCE_QUESTIONS_ERRORED = 'GET_GUIDANCE_QUESTIONS_ERRORED';

export const getGuidanceQuestions = () =>
	fetchRequestWithTypes({
		types: [
			GET_GUIDANCE_QUESTIONS_REQUEST,
			GET_GUIDANCE_QUESTIONS_SUCCESS,
			GET_GUIDANCE_QUESTIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/guidance_questions?uuid=:uuid',
		},
	});

export const GET_USER_GUIDANCE_REQUEST = 'GET_USER_GUIDANCE_REQUEST';
export const GET_USER_GUIDANCE_SUCCESS = 'GET_USER_GUIDANCE_SUCCESS';
export const GET_USER_GUIDANCE_ERRORED = 'GET_USER_GUIDANCE_ERRORED';

export const getUserGuidance = () =>
	fetchRequestWithTypes({
		types: [
			GET_USER_GUIDANCE_REQUEST,
			GET_USER_GUIDANCE_SUCCESS,
			GET_USER_GUIDANCE_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/user_guidance',
		},
	});

export const PATCH_USER_GUIDANCE_REQUEST = 'PATCH_USER_GUIDANCE_REQUEST';
export const PATCH_USER_GUIDANCE_SUCCESS = 'PATCH_USER_GUIDANCE_SUCCESS';
export const PATCH_USER_GUIDANCE_ERRORED = 'PATCH_USER_GUIDANCE_ERRORED';

export const patchUserGuidance = (body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_USER_GUIDANCE_REQUEST,
			PATCH_USER_GUIDANCE_SUCCESS,
			PATCH_USER_GUIDANCE_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/user_guidance',
			body,
		},
	});
