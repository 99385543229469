import { AnalyticsBrowser } from '@segment/analytics-next';
import React, { createContext } from 'react';

const AnalyticsContext = createContext<null | AnalyticsBrowser>(null);

type ProviderProps = {
	analytics: AnalyticsBrowser;
	children: React.ReactNode;
};

export function AnalyticsProvider({ analytics, children }: ProviderProps) {
	return (
		<AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>
	);
}

export default AnalyticsContext;