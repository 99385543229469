import { fetchRequestWithTypes } from './index.js';

export const GET_RETENTION_OFFER_REQUEST = 'GET_RETENTION_OFFER_REQUEST';
export const GET_RETENTION_OFFER_SUCCESS = 'GET_RETENTION_OFFER_SUCCESS';
export const GET_RETENTION_OFFER_ERRORED = 'GET_RETENTION_OFFER_ERRORED';

export const getRetentionOffer = (tierType) =>
	fetchRequestWithTypes({
		types: [
			GET_RETENTION_OFFER_REQUEST,
			GET_RETENTION_OFFER_SUCCESS,
			GET_RETENTION_OFFER_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/retention/api/v1/users/:uuid/offers/stick-around?tierType=${tierType}`.replace(
				'+',
				'%2B'
			),
		},
	});

export const POST_RETENTION_OFFER_REQUEST = 'POST_RETENTION_OFFER_REQUEST';
export const POST_RETENTION_OFFER_SUCCESS = 'POST_RETENTION_OFFER_SUCCESS';
export const POST_RETENTION_OFFER_ERRORED = 'POST_RETENTION_OFFER_ERRORED';

export const postRetentionOffer = (offerId) =>
	fetchRequestWithTypes({
		types: [
			POST_RETENTION_OFFER_REQUEST,
			POST_RETENTION_OFFER_SUCCESS,
			POST_RETENTION_OFFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/retention/api/v1/users/:uuid/offers/${offerId}/redeem`,
			body: {},
		},
	});

export const DECLINE_RETENTION_OFFER_REQUEST =
	'DECLINE_RETENTION_OFFER_REQUEST';
export const DECLINE_RETENTION_OFFER_SUCCESS =
	'DECLINE_RETENTION_OFFER_SUCCESS';
export const DECLINE_RETENTION_OFFER_ERRORED =
	'DECLINE_RETENTION_OFFER_ERRORED';

export const declineRetentionOffer = (offerId) =>
	fetchRequestWithTypes({
		types: [
			DECLINE_RETENTION_OFFER_REQUEST,
			DECLINE_RETENTION_OFFER_SUCCESS,
			DECLINE_RETENTION_OFFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/retention/api/v1/users/:uuid/offers/${offerId}/decline`,
			body: {},
		},
	});
