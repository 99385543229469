import { useEffect, useState } from 'react';
import {
	HOME_NAV_OPTION,
	MY_STASH_NAV_OPTION,
	TRANSFER_NAV_OPTION,
	MY_GOALS_NAV_OPTION,
	defaultNavOptions,
} from '../constants';
import { useBanjo } from '@stashinvest/react-banjo';
import { trackNavLinkClicked } from '../events';
import { track } from 'stash/events/utils';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { trackOrigin } from 'stash/actions';
import { Origin } from '@stashinvest/product-events-javascript/lib/enum_properties';

const useNavOptions = () => {
	const { getString } = useBanjo();
	const { pathname } = useLocation();
	const [selectedIndex, setSelectedIndex] = useState();
	const dispatch = useDispatch();
	const updateBanjoStrings = (navOptions) => {
		return navOptions.map((option) => {
			option.railOption.title = getString(option.banjoId);
			return option;
		});
	};
	const getSelectedNavIndex = (pathname) => {
		const navOptions = getNavOptions();
		const index = navOptions?.findIndex((optionKey) => {
			return optionKey.urlMatches.some((url) => {
				return new RegExp(url).test(pathname);
			});
		});

		return index > -1 ? index : null;
	};

	const getNavOptions = () => {
		const navOptions = [
			HOME_NAV_OPTION,
			MY_STASH_NAV_OPTION,
			MY_GOALS_NAV_OPTION,
			TRANSFER_NAV_OPTION,
		].filter((nav) => !!nav);

		if (navOptions?.length > 5 || navOptions?.length < 4) {
			return updateBanjoStrings(defaultNavOptions);
		}

		return updateBanjoStrings(navOptions);
	};

	const onNavLinkClick = (index) => {
		// negative index means profile icon or referral link was clicked
		if (index > -1) {
			// Send to old MixPanel
			trackNavLinkClicked(pathname, getNavOptions()[index].railOption.title);
			dispatch(trackOrigin(Origin?.rail));
			// Send to new Segment
			const segmentEvent = getNavOptions()[index].event({
				origin: Origin.rail,
				screen: pathname,
			});
			if (segmentEvent) {
				track(segmentEvent);
			}
		}

		setSelectedIndex(index);
	};

	useEffect(() => {
		setSelectedIndex(getSelectedNavIndex(pathname));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return {
		selectedIndex,
		getNavOptions,
		getSelectedNavIndex,
		onNavLinkClick,
	};
};

export default useNavOptions;
