import React from 'react';
import { Text, Layout } from '@stashinvest/ui';

export function StockbackFeedSection(props) {
	return (
		<Layout.ContentWrap pt="l" pb="s">
			<>
				<Text.H1 size={3}>Over 50M pieces of stock rewarded.</Text.H1>
				<Text.Caption mb="l">(as of 03/31/2022)</Text.Caption>
			</>

			<Text mb="m">
				Reward amounts are calculated as a percentage of each debit transaction.
			</Text>
		</Layout.ContentWrap>
	);
}

StockbackFeedSection.propTypes = {};

export default StockbackFeedSection;
