import { fetchRequestWithTypes } from './index.js';

// Get all the the accounts from PX endpoint

export const GET_INVEST_ACCOUNTS_REQUEST = 'GET_INVEST_ACCOUNTS_REQUEST';
export const GET_INVEST_ACCOUNTS_SUCCESS = 'GET_INVEST_ACCOUNTS_SUCCESS';
export const GET_INVEST_ACCOUNTS_ERRORED = 'GET_INVEST_ACCOUNTS_ERRORED';

export const getInvestAccountsByUser = () =>
	fetchRequestWithTypes({
		types: [
			GET_INVEST_ACCOUNTS_REQUEST,
			GET_INVEST_ACCOUNTS_SUCCESS,
			GET_INVEST_ACCOUNTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/investing-accounts/v1/accounts/by/users/:uuid`,
		},
	});
