import { DEBIT_SIGN_UP_CONFIRM_ADDRESS } from 'stash/actions/debitSignUpTrackingEvents';

export default (state = {}, action) => {
	switch (action.type) {
		case DEBIT_SIGN_UP_CONFIRM_ADDRESS: {
			return {
				...state,
				address: action.address,
			};
		}

		default:
			return state;
	}
};
