export const TOAST_SUCCESS = 'TOAST_SUCCESS';
export const TOAST_ERROR = 'TOAST_ERROR';
export const CLEAR_TOAST = 'CLEAR_TOAST';

export const toastSuccess = (message, icon) => ({
	type: TOAST_SUCCESS,
	message,
	icon,
});

export const toastError = (message) => ({
	type: TOAST_ERROR,
	message,
});

export const clearToast = () => ({
	type: CLEAR_TOAST,
});
