import { GET_QUOTE_RANGE_SUCCESS } from 'stash/actions/api/quotes';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_QUOTE_RANGE_SUCCESS: {
			const quote = action.response.data;
			const cardId = action.cardId;
			const timePeriod = action.timePeriod;

			return {
				...state,
				[cardId]: {
					...state[cardId],
					[timePeriod]: quote,
				},
			};
		}

		default:
			return state;
	}
};
