import { fetchRequestWithTypes } from './index.js';

// Returns a user's bookmarks.
// https://staging.stsh.io/documentation/1.0/bookmarks/index.html

export const GET_BOOKMARKS_REQUEST = 'GET_BOOKMARKS_REQUEST';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_ERRORED = 'GET_BOOKMARKS_ERRORED';

export const getBookmarks = () => {
	return fetchRequestWithTypes({
		types: [
			GET_BOOKMARKS_REQUEST,
			GET_BOOKMARKS_SUCCESS,
			GET_BOOKMARKS_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/bookmarks',
		},
	});
};

// Adds a bookmark to a user.
// https://staging.stsh.io/documentation/1.0/bookmarks/create.html

export const POST_BOOKMARK_REQUEST = 'POST_BOOKMARK_REQUEST';
export const POST_BOOKMARK_SUCCESS = 'POST_BOOKMARK_SUCCESS';
export const POST_BOOKMARK_ERRORED = 'POST_BOOKMARK_ERRORED';

export const postBookmark = (cardId) => {
	return fetchRequestWithTypes({
		types: [
			POST_BOOKMARK_REQUEST,
			POST_BOOKMARK_SUCCESS,
			POST_BOOKMARK_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/bookmarks',
			body: {
				card_id: cardId,
			},
		},
	});
};

// Deletes a particular bookmark for a user.
// https://staging.stsh.io/documentation/1.0/bookmarks/destroy.html

export const DELETE_BOOKMARK_REQUEST = 'DELETE_BOOKMARK_REQUEST';
export const DELETE_BOOKMARK_SUCCESS = 'DELETE_BOOKMARK_SUCCESS';
export const DELETE_BOOKMARK_ERRORED = 'DELETE_BOOKMARK_ERRORED';

export const deleteBookmark = (cardId) => {
	return fetchRequestWithTypes({
		types: [
			DELETE_BOOKMARK_REQUEST,
			DELETE_BOOKMARK_SUCCESS,
			DELETE_BOOKMARK_ERRORED,
		],
		meta: {
			cardId,
		},
		request: {
			method: 'DELETE',
			path: `/apis/api/v1/users/:uuid/bookmarks/${cardId}?card_id=${cardId}`,
		},
	});
};
