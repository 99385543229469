import { LOG_IN } from 'stash/actions';
import {
	POST_TOKEN_SUCCESS,
	REFRESH_TOKEN_SUCCESS,
	POST_THIRD_PARTY_AUTH_SUCCESS,
} from 'stash/actions/api/auth';
import {
	POST_USER_SUCCESS,
	PUT_PASSWORD_SUCCESS,
} from 'stash/actions/api/user';

const apiKeyReducer = (state = {}, action) => {
	switch (action.type) {
		case PUT_PASSWORD_SUCCESS:
			return action.response.api_key.refresh_token
				? {
						...state,
						access_token: 'Bearer ' + action.response.api_key.access_token,
						refresh_token: action.response.api_key.refresh_token,
				  }
				: {
						...state,
						access_token: action.response.api_key.access_token,
				  };
		case POST_THIRD_PARTY_AUTH_SUCCESS:
			return {
				...state,
				...action.response.api_key,
			};
		case POST_USER_SUCCESS:
			return {
				...state,
				...action.response.api_key,
				// This doesn't come back in api_key model so jerry rigging it there
				uuid: action.response.user.uuid,
			};
		case POST_TOKEN_SUCCESS:
		case REFRESH_TOKEN_SUCCESS:
			return {
				...state,
				access_token: 'Bearer ' + action.response.access_token,
				refresh_token: action.response.refresh_token,
			};
		case LOG_IN:
			return {
				...state,
				uuid: action.uuid,
			};
		default:
			return state;
	}
};

export default apiKeyReducer;
