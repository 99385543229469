import { fetchRequestWithTypes } from './index.js';
import {
	subMonths,
	startOfMonth,
	addDays,
	getISOString,
} from 'stash/utils/time';

// Returns the account. Accepts only uuid version of user's id, and the uuid version of account's id.
export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERRORED = 'GET_ACCOUNT_ERRORED';

export const getAccount = (accountId) =>
	fetchRequestWithTypes({
		types: [GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}`,
		},
	});

// GETs a dashboard for a given account.
export const GET_ACCOUNT_PORTFOLIO_REQUEST = 'GET_ACCOUNT_PORTFOLIO_REQUEST';
export const GET_ACCOUNT_PORTFOLIO_SUCCESS = 'GET_ACCOUNT_PORTFOLIO_SUCCESS';
export const GET_ACCOUNT_PORTFOLIO_ERRORED = 'GET_ACCOUNT_PORTFOLIO_ERRORED';

export const getAccountPortfolio = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_PORTFOLIO_REQUEST,
			GET_ACCOUNT_PORTFOLIO_SUCCESS,
			GET_ACCOUNT_PORTFOLIO_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/portfolio`,
		},
	});

export const GET_ACCOUNT_CARDS_REQUEST = 'GET_ACCOUNT_CARDS_REQUEST';
export const GET_ACCOUNT_CARDS_SUCCESS = 'GET_ACCOUNT_CARDS_SUCCESS';
export const GET_ACCOUNT_CARDS_ERRORED = 'GET_ACCOUNT_CARDS_ERRORED';

export const getAccountCards = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_CARDS_REQUEST,
			GET_ACCOUNT_CARDS_SUCCESS,
			GET_ACCOUNT_CARDS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/cards`,
		},
	});

// Get an account history

export const GET_ACCOUNT_HISTORY_REQUEST = 'GET_ACCOUNT_HISTORY_REQUEST';
export const GET_ACCOUNT_HISTORY_SUCCESS = 'GET_ACCOUNT_HISTORY_SUCCESS';
export const GET_ACCOUNT_HISTORY_ERRORED = 'GET_ACCOUNT_HISTORY_ERRORED';

export const getAccountHistory = (accountId, before, after) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_HISTORY_REQUEST,
			GET_ACCOUNT_HISTORY_SUCCESS,
			GET_ACCOUNT_HISTORY_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/account_history?before=${before}&after=${after}&omit_statements=true`,
		},
	});

// Get an account history

export const REFRESH_ACCOUNT_HISTORY_REQUEST =
	'REFRESH_ACCOUNT_HISTORY_REQUEST';
export const REFRESH_ACCOUNT_HISTORY_SUCCESS =
	'REFRESH_ACCOUNT_HISTORY_SUCCESS';
export const REFRESH_ACCOUNT_HISTORY_ERRORED =
	'REFRESH_ACCOUNT_HISTORY_ERRORED';

export const refreshAccountHistory = (accountId) => {
	const before = addDays(new Date(), 1);
	const after = startOfMonth(subMonths(before, 1));

	return fetchRequestWithTypes({
		types: [
			REFRESH_ACCOUNT_HISTORY_REQUEST,
			REFRESH_ACCOUNT_HISTORY_SUCCESS,
			REFRESH_ACCOUNT_HISTORY_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/account_history?before=${getISOString(
				before
			)}&after=${getISOString(after)}&omit_statements=true`,
		},
	});
};

export const GET_BROKERAGE_AGREEMENTS_REQUEST =
	'GET_BROKERAGE_AGREEMENTS_REQUEST';
export const GET_BROKERAGE_AGREEMENTS_SUCCESS =
	'GET_BROKERAGE_AGREEMENTS_SUCCESS';
export const GET_BROKERAGE_AGREEMENTS_ERRORED =
	'GET_BROKERAGE_AGREEMENTS_ERRORED';

export const getBrokerageAgreements = (retireAccountType) => {
	return fetchRequestWithTypes({
		types: [
			GET_BROKERAGE_AGREEMENTS_REQUEST,
			GET_BROKERAGE_AGREEMENTS_SUCCESS,
			GET_BROKERAGE_AGREEMENTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/concierge/api/v1/users/:uuid/agreements/brokerage${
				retireAccountType ? '?retireAccountType=' + retireAccountType : ''
			}`,
		},
	});
};

// GET ESIG AGREEMENTS WITH ABILITY TO GET MULTIPLE ACCOUNT TYPES
export const GET_ESIG_AGREEMENTS_REQUEST = 'GET_ESIG_AGREEMENTS_REQUEST';
export const GET_ESIG_AGREEMENTS_SUCCESS = 'GET_ESIG_AGREEMENTS_SUCCESS';
export const GET_ESIG_AGREEMENTS_ERRORED = 'GET_ESIG_AGREEMENTS_ERRORED';

export const getEsigAgreements = (accountTypes) => {
	const accountTypeQuery = accountTypes
		.map((accountType) => {
			return `account_types%5B%5D=${accountType}`;
		})
		.join('&');

	return fetchRequestWithTypes({
		types: [
			GET_ESIG_AGREEMENTS_REQUEST,
			GET_ESIG_AGREEMENTS_SUCCESS,
			GET_ESIG_AGREEMENTS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/account_types/multiple/agreements?variant=1&${accountTypeQuery}`,
		},
	});
};

// Post a new account (ROTH_IRA, PERSONAL_BROKERAGE, TRADITIONAL_IRA, CUSTODIAN)
// https://staging.stsh.io/documentation/1.0/accounts/create.html

export const POST_ACCOUNT_REQUEST = 'POST_ACCOUNT_REQUEST';
export const POST_ACCOUNT_SUCCESS = 'POST_ACCOUNT_SUCCESS';
export const POST_ACCOUNT_ERRORED = 'POST_ACCOUNT_ERRORED';

export const postAccount = (body) =>
	fetchRequestWithTypes({
		types: [POST_ACCOUNT_REQUEST, POST_ACCOUNT_SUCCESS, POST_ACCOUNT_ERRORED],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/accounts',
			body,
		},
	});

// Submits an update to the custodian account for a user's minor
// https://staging.stsh.io/documentation/1.0/accounts/minor.html

export const PATCH_CUSTODIAN_ACCOUNT_REQUEST =
	'PATCH_CUSTODIAN_ACCOUNT_REQUEST';
export const PATCH_CUSTODIAN_ACCOUNT_SUCCESS =
	'PATCH_CUSTODIAN_ACCOUNT_SUCCESS';
export const PATCH_CUSTODIAN_ACCOUNT_ERRORED =
	'PATCH_CUSTODIAN_ACCOUNT_ERRORED';

export const patchCustodianAccount = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_CUSTODIAN_ACCOUNT_REQUEST,
			PATCH_CUSTODIAN_ACCOUNT_SUCCESS,
			PATCH_CUSTODIAN_ACCOUNT_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/minor`,
			body,
		},
	});

// Sets the e-signature without submitting to Apex
// https://staging.stsh.io/documentation/1.0/accounts/e_signature.html

export const PATCH_ACCOUNT_ESIG_REQUEST = 'PATCH_ACCOUNT_ESIG_REQUEST';
export const PATCH_ACCOUNT_ESIG_SUCCESS = 'PATCH_ACCOUNT_ESIG_SUCCESS';
export const PATCH_ACCOUNT_ESIG_ERRORED = 'PATCH_ACCOUNT_ESIG_ERRORED';

export const patchAccountEsig = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			PATCH_ACCOUNT_ESIG_REQUEST,
			PATCH_ACCOUNT_ESIG_SUCCESS,
			PATCH_ACCOUNT_ESIG_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/e_signature`,
			body,
		},
	});

// Submits an account's application to Apex
// https://staging.stsh.io/documentation/1.0/accounts/submit_application.html

export const POST_ACCOUNT_SUBMIT_REQUEST = 'POST_ACCOUNT_SUBMIT_REQUEST';
export const POST_ACCOUNT_SUBMIT_SUCCESS = 'POST_ACCOUNT_SUBMIT_SUCCESS';
export const POST_ACCOUNT_SUBMIT_ERRORED = 'POST_ACCOUNT_SUBMIT_ERRORED';

export const postAccountSubmit = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_SUBMIT_REQUEST,
			POST_ACCOUNT_SUBMIT_SUCCESS,
			POST_ACCOUNT_SUBMIT_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/submit`,
			body,
		},
	});

// Initiates Closing Process for an account
// https://staging.stsh.io/documentation/1.0/accounts/initiate_close.html

export const POST_ACCOUNT_CLOSE_REQUEST = 'POST_ACCOUNT_CLOSE_REQUEST';
export const POST_ACCOUNT_CLOSE_SUCCESS = 'POST_ACCOUNT_CLOSE_SUCCESS';
export const POST_ACCOUNT_CLOSE_ERRORED = 'POST_ACCOUNT_CLOSE_ERRORED';

export const postAccountClose = (accountId) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_CLOSE_REQUEST,
			POST_ACCOUNT_CLOSE_SUCCESS,
			POST_ACCOUNT_CLOSE_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/initiate_close`,
		},
	});

// Save the user's responses to the brokerage account closure feedback form
// https://edge.stsh.io/documentation/1.0/close_account_feedback_form/create.html

export const POST_ACCOUNT_CLOSE_FEEDBACK_REQUEST =
	'POST_ACCOUNT_CLOSE_FEEDBACK_REQUEST';
export const POST_ACCOUNT_CLOSE_FEEDBACK_SUCCESS =
	'POST_ACCOUNT_CLOSE_FEEDBACK_SUCCESS';
export const POST_ACCOUNT_CLOSE_FEEDBACK_ERRORED =
	'POST_ACCOUNT_CLOSE_FEEDBACK_ERRORED';

export const postAccountCloseFeedback = (accountId, responses) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_CLOSE_FEEDBACK_REQUEST,
			POST_ACCOUNT_CLOSE_FEEDBACK_SUCCESS,
			POST_ACCOUNT_CLOSE_FEEDBACK_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/close_account_feedback_form`,
			body: {
				close_account_feedback_form: {
					responses,
				},
			},
		},
	});

// Cancels Closing Process for an account
// https://staging.stsh.io/documentation/1.0/accounts/cancel_close.html

export const PATCH_CANCEL_ACCOUNT_CLOSE_REQUEST =
	'PATCH_CANCEL_ACCOUNT_CLOSE_REQUEST';
export const PATCH_CANCEL_ACCOUNT_CLOSE_SUCCESS =
	'PATCH_CANCEL_ACCOUNT_CLOSE_SUCCESS';
export const PATCH_CANCEL_ACCOUNT_CLOSE_ERRORED =
	'PATCH_CANCEL_ACCOUNT_CLOSE_ERRORED';

export const patchCancelAccountClose = (accountId) =>
	fetchRequestWithTypes({
		types: [
			PATCH_CANCEL_ACCOUNT_CLOSE_REQUEST,
			PATCH_CANCEL_ACCOUNT_CLOSE_SUCCESS,
			PATCH_CANCEL_ACCOUNT_CLOSE_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/cancel_close `,
		},
	});

// PATCH to reactivate an inactive user.

export const PATCH_REACTIVATE_ACCOUNT_REQUEST =
	'PATCH_REACTIVATE_ACCOUNT_REQUEST';
export const PATCH_REACTIVATE_ACCOUNT_SUCCESS =
	'PATCH_REACTIVATE_ACCOUNT_SUCCESS';
export const PATCH_REACTIVATE_ACCOUNT_ERRORED =
	'PATCH_REACTIVATE_ACCOUNT_ERRORED';

export const patchReactivateAccount = (accountId) =>
	fetchRequestWithTypes({
		types: [
			PATCH_REACTIVATE_ACCOUNT_REQUEST,
			PATCH_REACTIVATE_ACCOUNT_SUCCESS,
			PATCH_REACTIVATE_ACCOUNT_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/reactivate`,
		},
	});

// Reopens the given account for a user in the closed state
export const PATCH_REOPEN_ACCOUNT_REQUEST = 'PATCH_REOPEN_ACCOUNT_REQUEST';
export const PATCH_REOPEN_ACCOUNT_SUCCESS = 'PATCH_REOPEN_ACCOUNT_SUCCESS';
export const PATCH_REOPEN_ACCOUNT_ERRORED = 'PATCH_REOPEN_ACCOUNT_ERRORED';

export const patchReopenAccount = (accountId) =>
	fetchRequestWithTypes({
		types: [
			PATCH_REOPEN_ACCOUNT_REQUEST,
			PATCH_REOPEN_ACCOUNT_SUCCESS,
			PATCH_REOPEN_ACCOUNT_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/reopen`,
		},
	});
