import { FETCH_INVESTMENTS_SUCCESS } from 'stash/actions/api/investments';
import {
	FILTER_SORT,
	FILTER_INVESTMENT_TYPE,
	FILTER_RISK_LEVEL,
	FILTER_GROUP_SLUG,
} from 'stash/constants/investmentFilters';

export const DEFAULT_INVESTMENTS_STATE = {
	searchKey: '',
	filters: {
		[FILTER_SORT]: {},
		[FILTER_INVESTMENT_TYPE]: {},
		[FILTER_RISK_LEVEL]: {},
		[FILTER_GROUP_SLUG]: {},
	},
	results: {
		total: 0,
		investments: [],
		page_index: 0,
		page_size: 24,
	},
};

function getFilterData(response) {
	const filterData = {
		sort: {
			key: 'sort',
			display: 'Sort',
			multiselect: false,
			options: response.sort,
		},
	};

	response.filters.forEach((filter) => {
		filterData[filter.key] = filter;
	});

	return filterData;
}

function getResultData(response, state) {
	const investments =
		response.page_index > 0
			? state.results.investments.concat(response.results)
			: response.results;

	return {
		total: response.total,
		page_index: response.page_index,
		page_size: response.page_size,
		investments,
	};
}

export default (state = DEFAULT_INVESTMENTS_STATE, action) => {
	switch (action.type) {
		case FETCH_INVESTMENTS_SUCCESS: {
			return {
				searchKey: action.meta.searchKey,
				filters: getFilterData(action.response.data),
				results: getResultData(action.response.data, state),
			};
		}
		default:
			return state;
	}
};
