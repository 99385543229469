import {
	GET_FUNDING_SOURCES_SUCCESS,
	POST_DEBIT_SUCCESS,
} from '../../actions/api/fundingSource';

export default (state = [], action) => {
	switch (action.type) {
		case GET_FUNDING_SOURCES_SUCCESS:
			return action.response.funding_sources;
		case POST_DEBIT_SUCCESS:
			return [...state, action.response.funding_source];
		default:
			return state;
	}
};
