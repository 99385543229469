import {
	REFRESH_TOKEN_REQUEST,
	REFRESH_TOKEN_SUCCESS,
	REFRESH_TOKEN_ERRORED,
} from 'stash/actions/api/auth';

export default (state = false, action) => {
	switch (action.type) {
		case REFRESH_TOKEN_REQUEST:
			return true;
		case REFRESH_TOKEN_SUCCESS:
		case REFRESH_TOKEN_ERRORED:
			return false;
		default:
			return state;
	}
};
