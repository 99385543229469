import { SET_ACTIVE_NAV_TAB, SET_ACTIVE_ACCOUNT } from '../../actions/nav';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_ACTIVE_NAV_TAB:
			return {
				...state,
				activeTab: action.activeTab,
			};

		case SET_ACTIVE_ACCOUNT:
			return {
				...state,
				activeAccount: action.activeAccount,
			};

		default:
			return state;
	}
};
