import { POST_PHONE_VERIFICATION_SUCCESS } from '../../actions/api/phoneVerification';

export default (state = {}, action) => {
	switch (action.type) {
		case POST_PHONE_VERIFICATION_SUCCESS:
			return action.response.phone_number_verification || state;
		default:
			return state;
	}
};
