import {
	GET_ESIG_AGREEMENTS_SUCCESS,
	GET_BROKERAGE_AGREEMENTS_SUCCESS,
} from '../../actions/api/account';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_ESIG_AGREEMENTS_SUCCESS: {
			return {
				all_links: action.response.agreements,
				agreement_statement: action.response.agreement_statement,
			};
		}
		case GET_BROKERAGE_AGREEMENTS_SUCCESS: {
			return {
				...action.response.agreement,
			};
		}
		default:
			return state;
	}
};
