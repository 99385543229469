/**
 * This middleware responds to authentication actions. Cookies are
 * securely stored when a user logs in or signs up. Users are logged
 * out after a period of inactivity.
 *
 */

import { LOCATION_CHANGE } from 'connected-react-router';
import { logIn } from 'stash/actions/session';
import { PUT_PASSWORD_SUCCESS } from 'stash/actions/api/user';
import { STASH_INIT } from 'stash/actions/init';
import {
	POST_TOKEN_SUCCESS,
	POST_TOKEN_ERRORED,
	POST_THIRD_PARTY_AUTH_SUCCESS,
	REFRESH_TOKEN_SUCCESS,
	deleteAuth,
	deleteRefreshToken,
	refreshTokenRequest,
} from 'stash/actions/api/auth';
import { setCookie, deleteCookie, getCookie } from 'stash/utils/cookies';
import { parseJwt } from 'stash/utils/helpers';
import {
	LOG_IN,
	LOG_OUT,
	LOG_IN_THIRD_PARTY,
	authSessionStart,
	logOut,
	logOutReasons,
	logInThirdParty,
} from 'stash/actions/session';
import {
	ACCESS_TOKEN_COOKIE_NAME,
	REFRESH_TOKEN_COOKIE_NAME,
	SESSION_TIMEOUT_INTERVAL,
	TOKEN_REFRESH_INTERVAL,
	USER_ID_COOKIE_NAME,
	UUID_COOKIE_NAME,
} from '../constants/session';

let sessionTimeout;
let refreshTimeout;

const sessionMiddleware =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		// At application start, dispatch AUTH_SESSION_START if logged in.
		if (action.type === STASH_INIT) {
			const uuid = getCookie(UUID_COOKIE_NAME);
			if (uuid) {
				next(action);
				return dispatch(authSessionStart(uuid));
			}
		}
		if (action.type === POST_THIRD_PARTY_AUTH_SUCCESS) {
			const apiKey = action.response.api_key;
			dispatch(logInThirdParty(apiKey.access_token, apiKey.uuid));
		}

		if (action.type === LOG_IN_THIRD_PARTY) {
			setCookie(ACCESS_TOKEN_COOKIE_NAME, action.authToken);
			if (!!action.uuid) setCookie(UUID_COOKIE_NAME, action.uuid);
		}

		// Dispatch AUTH_SESSION_START on log in.
		if (action.type === LOG_IN) {
			dispatch(authSessionStart(action.uuid));
		}

		// Store session cookies when user logs in with auth v2.
		if (action.type === LOG_IN) {
			setCookie(ACCESS_TOKEN_COOKIE_NAME, 'Bearer ' + action.authToken);
			setCookie(REFRESH_TOKEN_COOKIE_NAME, action.refreshToken);
			if (!!action.uuid) setCookie(UUID_COOKIE_NAME, action.uuid);
			// set the token to auto-refresh upon login
			window.clearTimeout(refreshTimeout);
			refreshTimeout = window.setTimeout(() => {
				dispatch(refreshTokenRequest(action.refreshToken));
			}, TOKEN_REFRESH_INTERVAL);
		}

		// Parse jwt and fire off auth actions
		if (action.type === POST_TOKEN_SUCCESS) {
			const { response } = action;
			const { sub } = parseJwt(response.access_token);
			dispatch(logIn(response.access_token, response.refresh_token, sub));
		}

		// Force log out if the initial token request fails
		if (action.type === POST_TOKEN_ERRORED) {
			dispatch(logOut());
		}

		if (action.type === REFRESH_TOKEN_SUCCESS) {
			setCookie(ACCESS_TOKEN_COOKIE_NAME, 'Bearer ' + action.response.access_token);
			setCookie(REFRESH_TOKEN_COOKIE_NAME, action.response.refresh_token);
			window.clearTimeout(refreshTimeout);
			refreshTimeout = window.setTimeout(() => {
				dispatch(refreshTokenRequest(getCookie(REFRESH_TOKEN_COOKIE_NAME)));
			}, TOKEN_REFRESH_INTERVAL);
		}

		// Update access token when a user changes their password
		if (action.type === PUT_PASSWORD_SUCCESS) {
			if (action.response.api_key.refresh_token) {
				setCookie(
					ACCESS_TOKEN_COOKIE_NAME,
					'Bearer ' + action.response.api_key.access_token
				);
				setCookie(REFRESH_TOKEN_COOKIE_NAME, action.response.api_key.refresh_token);
			} else {
				setCookie(ACCESS_TOKEN_COOKIE_NAME, action.response.api_key.access_token);
			}
		}

		// Delete session cookies and auth + redirect when user logs out.
		if (action.type === LOG_OUT) {
			const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_NAME);
			const uuid = getCookie(UUID_COOKIE_NAME);

			if (refreshToken) {
				dispatch(deleteRefreshToken(refreshToken)).catch((res) => res);
			}
			if (uuid) {
				dispatch(deleteAuth()).catch((res) => res);
			}
			deleteCookie(ACCESS_TOKEN_COOKIE_NAME);
			deleteCookie(REFRESH_TOKEN_COOKIE_NAME);
			deleteCookie(USER_ID_COOKIE_NAME);
			deleteCookie(UUID_COOKIE_NAME);

			window.localStorage && window.localStorage.clear();
			window.sessionStorage && window.sessionStorage.clear();

			// Do a hard redirect on window to clear memory.
			window.setTimeout(() => {
				window.location.href = `/log-in?reason=${action.reason || ''}`;
			}, 300);
		}

		// A location change is our definition of user 'activity'.
		// Reset the session timeouts when this happens.
		if (action.type === LOCATION_CHANGE) {
			if (getCookie(UUID_COOKIE_NAME) && getCookie(ACCESS_TOKEN_COOKIE_NAME)) {
				// Refresh session cookies.
				setCookie(ACCESS_TOKEN_COOKIE_NAME, getCookie(ACCESS_TOKEN_COOKIE_NAME), {
					'max-age': SESSION_TIMEOUT_INTERVAL / 1000,
				});
				setCookie(UUID_COOKIE_NAME, getCookie(UUID_COOKIE_NAME), {
					'max-age': SESSION_TIMEOUT_INTERVAL / 1000,
				});

				// Reset session timeout.
				window.clearTimeout(sessionTimeout);
				sessionTimeout = window.setTimeout(() => {
					dispatch(logOut(logOutReasons.TIMED_OUT));
				}, SESSION_TIMEOUT_INTERVAL);
			}
		}

		return next(action);
	};

export default sessionMiddleware;
