import styled from 'styled-components/macro';

export const ButtonsRow = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-top: 24px;
`;

export const ContinueButton = styled.span`
	margin-top: 16px;
	width: 100%;
	color: #9babba;
	&:hover {
		cursor: pointer;
	}
`;
