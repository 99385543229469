import React from 'react';
import { Link, SmartLink } from '@stashinvest/ui';

export const checkingDisclosures = {
	// When bank is mentioned and no debit card is shown or mentioned
	checking_disclosure_d1:
		'Bank Account Services provided by Green Dot Bank, Member FDIC.',

	// When bank is mentioned and debit card is shown or mentioned
	checking_disclosure_d2: `Bank Account Services provided by and Stash Visa Debit Card (Stock-Back® Card) issued by Green Dot Bank, Member FDIC, pursuant to a license from Visa U.S.A. Inc. Visa is a registered trademark of Visa International Service Association.`,

	// If ASAP direct deposit is mentioned (getting paycheck up to 2 days early, government benefits up to 4 days early)
	checking_disclosure_d3: `Early availability depends on timing of payor’s payment instructions and fraud prevention restrictions may apply. As such, the availability or timing of early direct deposit may vary from pay period to pay period.`,

	//When bank + a non-bank product / feature are mentioned or depicted together
	checking_disclosure_d4: `Investment products and services provided by Stash Investments LLC, not Green Dot Bank, and are Not FDIC Insured, Not Bank Guaranteed, and May Lose Value.`,

	//unused - not in compliance doc
	checking_disclosure_d5: `Green Dot Bank and Stash have taken certain physical, administrative, and technical steps to safeguard the information we collect from and about our customers.`,

	//When mentioning Spending Insights and insights are actually listed/used (i.e. not just saying that we offer spending insights)
	checking_disclosure_d6: `Spending Insights provided by Stash.`,

	//unused - not in compliance doc
	checking_disclosure_d9: `Earning stock excludes cash, cash equivalents (including the funding of gift cards and prepaid cards), and transactions through Venmo®, Square, Inc.’s Cash App®, PayPal®, or similar services.`,

	// When the Stock-Back® Card is mentioned
	checking_disclosure_d11: `All rewards earned through use of the Stash Visa Debit card (Stock-Back® Card) will be fulfilled by Stash Investments LLC. Rewards will go to your Stash personal investment account, which is not FDIC insured. You will bear the standard fees and expenses reflected in the pricing of the investments that you earn, plus fees for various ancillary services charged by Stash. Stash Stock-Back® Rewards is not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A., or any of their respective affiliates.`,

	//not in compliance doc
	checking_disclosure_privacy_policy_d1: `Green Dot Bank Privacy Policy`,

	//When mentioning Goals
	checking_disclosure_partition_d1: `Money moved into a Goal must be moved back to the bank account available balance to be used and does not earn interest.`,

	//When lock/unlock of debit card is mentioned
	checking_disclosure_settings_d1: `When you Lock your card, withdrawals and purchases conducted using your card will not be authorized until you unlock your card. Some activity will continue, including your monthly fee, if applicable, debit transactions that were authorized before you Locked your card, transactions crediting funds to your account, ACH debit transactions conducted using your account and routing numbers, recurring bill payments, mobile check deposit, and cash deposits.`,

	// not in compliance doc
	checking_disclosure_stockback_d1: `Program is subject to terms and conditions. A user will receive shares of the ETF that is designated as their Default ETF at the time the qualifying purchase posts to the user’s bank account.`,

	//When mentioning stock round-ups
	checking_disclosure_stockback_d2: `This Program is subject to terms and conditions. In order to participate, a user must comply with all eligibility
	requirements and make a qualifying purchase with their Stock-Back® Card.
	All funds used for this Program will be taken from your Stash Banking
	account. This Program is not sponsored or endorsed by Green Dot Bank
	
	`,
	//not in compliance doc
	checking_disclosure_stockback_d3: (endDate) => {
		return (
			<>
				The Stock-Back® Community Rewards Program is subject to
				<Link
					as={SmartLink}
					to="https://lp.stash.com/stock-back-community-rewards-program-nov/"
				>
					Terms and Conditions
				</Link>
				and expires at 8:00 AM ET on ${endDate}. Eligibility restrictions apply.
				See Terms and Conditions for details. Participation in this Program is
				not an endorsement or statement of satisfaction by any customer. You
				represent and warrant that by participating in this Program you will not
				solicit any advisory services or the purchase or sale of any securities
				on behalf of Stash, any such user of Stash or any third party. Not all
				participants will receive marketing messaging directly from Stash
				regarding this Program.
			</>
		);
	},

	//When a promotion / sweepstakes mentions a bank product or feature (or an ad mentions a promotion / sweepstakes and a bank feature separately but in the same ad) AND the promotion / sweepstakes is not sponsored by Green Dot.
	checking_disclosure_stockback_d4: `The Stock-Back® Community Rewards Program and Stash Stock-Back® Rewards are not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A. Inc., or any of their respective affiliates, and none of the foregoing has any responsibility to fulfill any funds earned through these.`,

	checking_disclosure_redemption_d1: `This Promotion is not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A., or any of their respective affiliates, and none of the foregoing has any responsibility to fulfill any aspect of this Promotion.`,

	//not in compliance doc
	checking_disclosure_stockback_d5: `Stock-Back Rewards that are issued to a participating customer’s personal brokerage account via the Stash Stock-Back Program, are not FDIC Insured, Not Bank Guaranteed and May Lose Value.`,

	//When you use phrases like “Earn Stock-Back rewards on virtually every purchase” OR “Earn Stock-Back rewards wherever you spend” OR “Earn stock with every purchase”
	checking_disclosure_stockback_d6: `What doesn’t count: Cash withdrawals, money orders, prepaid cards, and P2P payment. If stock of the merchant is not available for a qualifying purchase, the security will be in shares of a predetermined ETF or from a list of predetermined publicly-traded companies available on the Stash Platform. See full terms and conditions.`,

	//not in compliance doc
	checking_disclosure_stockback_d7: `Opt-in required for Stash Stock Back.`,

	//not in compliance doc
	checking_disclosure_card_activation_d1: `See Account Agreement for fees, limits, and exclusions.`,

	//When Stock-Back® rewards are mentioned, but the Stock-Back® Card is not
	checking_disclosure_card_activation_d2: `Stash Stock-Back® Rewards is not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A., or any of their respective affiliates, and none of the foregoing has any responsibility to fulfill any stock rewards earned through this program.`,

	//not in compliance doc
	checking_disclosure_card_activation_d3: `This is not a funding service provided by Green Dot bank. Limitations on funding your Stash account with a debit card apply. Transactions must be between $0 and $100 and you may be limited to one transaction through this funding method.`,

	//not in compliance doc
	checking_disclosure_recurring_transfer_d1: `The recurring transfers feature is offered by Stash Investments LLC and is not sponsored or endorsed by Green Dot Bank.`,

	checking_disclosure_recurring_transfer_d2: `By tapping “Schedule transfer” below, I authorize the initiation of this transfer as described above. I understand this authorization will remain in effect until I “Pause” recurring transfers in the Stash debit account settings, which must be done at least 3 business days prior to a scheduled recurring transfer.`,

	//Spending Insights provided by Stash Investments LLC.
	checking_disclosure_spending_insights: `Spending Insights provided by Stash Investments LLC.`,

	//LEGACY SIGN UP
	checking_disclosure_legacy_signup_d1: `To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account.`,

	checking_disclosure_legacy_signup_d2: `What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver’s license or other identifying documents. Account opening is also subject to other fraud prevention measures. While there is no credit check to get a card, certain features may be subject to the use of a consumer report. You will be notified prior to any use.`,

	checking_disclosure_legacy_signup_d3: `To process your debit account application, we are required to send your personal info, date of birth, and Social Security number to our partner Green Dot Bank (provider of the account).`,

	checking_disclosure_legacy_signup_d4: `These terms and conditions for a Stash debit account, provided by our banking partner Green Dot Bank, are in addition to the Stash terms of use and Stash privacy policy that you’ve already agreed to.`,

	checking_disclosure_legacy_signup_d5: `Account opening of the debit account is subject to Green Dot Bank approval.`,

	checking_disclosure_legacy_signup_d6: `When you spend with your debit card at a merchant, we’ll reward you with stock in that company, if it’s on our platform. If it’s not, we’ll reward you with a diversified fund (ETF).`,

	checking_disclosure_legacy_signup_d7: `Opt-in required. Additional terms and conditions apply. Investments are held in your Personal Invest Account, and are not FDIC Insured, Not Bank Guaranteed and May Lose Value. Stash Stock-Back™ rewards is not sponsored or endorsed by Green Dot Bank, Green Dot Corporation, Visa U.S.A., or any of their respective affiliates, and none of the foregoing has any responsibility to fulfill any stock rewards earned by this promotion. `,

	checking_disclosure_legacy_signup_d8: `Visa is a registered trademark of Visa	International Service Association. Green Dot Bank operates under the following registered trade names: GoBank, Green Dot Bank and Bonneville Bank. All of the registered trade names are used by, and refer to a single FDIC-insured bank, Green Dot Bank. Deposits under any of these trade names are deposits with Green Dot Bank and are aggregated for deposit insurance coverage. Green Dot is a registered trademark of Green Dot Corporation. ©2019 Green Dot Bank. All rights reserved.`,

	//BANK ACTIVATION

	//If ASAP direct deposit is mentioned (getting paycheck up to 2 days early, government benefits up to 4 days early)
	checking_disclosure_direct_deposit_d1: `Early availability depends on timing of payor’s payment instructions and fraud prevention restrictions may apply. As such, the availability or timing of early direct deposit may vary from pay period to pay period.`,

	checking_disclosure_activation_d1: `The recurring transfers feature is offered by Stash Investments LLC and is not sponsored or endorsed by Green Dot Bank.`,

	checking_disclosure_activation_d2: `By "Confirm" below, I authorize the initiation of this transfer as described above. I understand this authorization will remain in effect until I "Pause" recurring transfers in the Stash banking account settings, which must be done at least 3 business days prior to a scheduled recurring transfer. Bank Account Services provided by Green Dot Bank, Member FDIC.`,

	checking_disclosure_activation_d3: `This material is not intended as investment advice and is not meant to suggest that any securities are suitable investments for any particular investor. Investment advice is only provided to Stash customers. All investments are subject to risk and may lose value. All product and company names are trademarks ™ or registered ® trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.`,

	checking_disclosure_activation_d4: `For overdraft, transaction is declined and no fee is charged. Other fees apply to the bank account. Please see the Deposit Account Agreement for details. If applicable, your Stash banking account is a funding account for purposes of the Advisory Agreement. Your Stash subscription fee may be deducted from your Stash banking account balance.`,

	checking_disclosure_activation_d5: `Transfers should take 2-4 business days to complete. Transfers scheduled for a weekend or bank holiday will be processed the following business day.`,

	transfers_disclosure_d1:
		'Investment products and services are provided by Stash Investments LLC and not provided by Green Dot Bank and are Not FDIC Insured, Not Bank Guaranteed, and May Lose Value. Bank Account Services provided by Green Dot Bank, Member FDIC.',
};
