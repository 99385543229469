import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import application from './application';
import entities from './entities';

export default (history) =>
	combineReducers({
		router: connectRouter(history),
		application,
		entities,
	});
