import { GET_NOTIFICATIONS_SUCCESS } from '../../actions/api/notifications';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				...action.response,
			};
		default:
			return state;
	}
};
