import { POST_DEPOSIT_WITH_INVESTMENT_SUCCESS } from '../../actions/api/transfers';
import { POST_AUTO_STASH_ALLOCATION_SUCCESS } from '../../actions/api/autoStash';

export default (state = {}, action) => {
	switch (action.type) {
		case POST_DEPOSIT_WITH_INVESTMENT_SUCCESS:
			return {
				...state,
				postDeposit: action.response,
			};
		case POST_AUTO_STASH_ALLOCATION_SUCCESS:
			return {
				...state,
				postAutoStash: action.response,
			};
		default:
			return state;
	}
};
