import { GET_TRADE_CONFIRMATIONS_SUCCESS } from '../../actions/api/clearing';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_TRADE_CONFIRMATIONS_SUCCESS:
			return {
				...state,
				[action.response.account_id]: action.response.trade_confirms,
			};
		default:
			return state;
	}
};
