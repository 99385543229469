import {
	GET_REFERRAL_MANAGEMENT_SUCCESS,
	GET_REFERRAL_REGISTRATION_SUCCESS,
	GET_REFERRAL_CUSTOM_LANDING_SUCCESS,
	PUT_REFERRAL_SOLICITOR_SUCCESS,
} from '../../actions/api/referrals.js';

export default (
	state = {
		customLanding: {},
		referralsRegistrationView: {},
		referralsManagementView: {},
	},
	action
) => {
	switch (action.type) {
		case GET_REFERRAL_CUSTOM_LANDING_SUCCESS:
			return {
				...state,
				customLanding: action.response,
			};

		case GET_REFERRAL_MANAGEMENT_SUCCESS:
			return {
				...state,
				referralsManagementView: action.response.referralsManagementView,
			};

		case GET_REFERRAL_REGISTRATION_SUCCESS:
			return {
				...state,
				referralsRegistrationView: action.response.referralsRegistrationView,
			};

		case PUT_REFERRAL_SOLICITOR_SUCCESS:
			const referralsManagementView = {
				...state.referralsManagementView,
				solicitorAgreement: {
					...state.referralsManagementView.solicitorAgreement,
					isAccepted: true,
				},
			};

			return {
				...state,
				referralsManagementView,
			};
		default:
			return state;
	}
};
