import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	openDialog,
	logOut,
	logOutReasons,
	openDrawer,
	closeDrawer,
} from 'stash/actions';
import DrawerPortal from 'stash/components/drawer/DrawerPortal';
import NotificationAvatar from 'stash/assets/top-nav/notification-avatar.svg';
import DefaultAvatar from 'stash/assets/top-nav/default-avatar.svg';
import { selectIsContentMoat } from 'stash/selectors';
import { Flex, Box, Logo, ThemeProvider, Image, Link } from '@stashinvest/ui';

const mapStateToProps = (state) => ({
	drawerOpen: state.application.drawer,
	user: state.entities.user,
	avatar: state.entities?.profile?.avatar_url || DefaultAvatar,
	isContentMoat: selectIsContentMoat(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	open: (e) => {
		const openSideDrawer = () => {
			e.preventDefault();
			dispatch(openDrawer());
		};

		if (window.innerWidth > 767) {
			ownProps.history.push('/account-settings');
		} else {
			openSideDrawer();
		}
	},
	close: () => {
		dispatch(closeDrawer());
	},
	logOut: (e) => {
		e.preventDefault();
		return dispatch(
			openDialog({
				prompt: 'Are you sure you want to log out?',
				confirmText: 'Log me out',
				cancelText: 'Cancel',
			})
		)
			.then(() => dispatch(logOut(logOutReasons.LOGGED_OUT)))
			.catch(() => {});
	},
});

export const ContentMoatNavbar = ({
	open,
	close,
	drawerOpen,
	logOut,
	user,
	avatar,
	isContentMoat,
}) => {
	return (
		<>
			<ThemeProvider mode="dark">
				<Box width="100%" py="s" px="xs" bg="bgPrimary">
					<Flex
						justifyContent="space-between"
						alignItems="center"
						maxWidth="1280px"
						mx="auto"
					>
						<Box>
							<Link href={'/'}>
								<Logo zIndex="1" height="1.3rem" />
							</Link>
						</Box>
						<Image
							style={{ cursor: 'pointer' }}
							size="36px"
							src={NotificationAvatar}
							onClick={open}
							alt="Account settings"
							aria-label="Account settings"
						/>
					</Flex>
				</Box>
			</ThemeProvider>
			<DrawerPortal
				active={drawerOpen}
				close={close}
				logOut={logOut}
				user={user}
				avatar={avatar}
			/>
		</>
	);
};

ContentMoatNavbar.propTypes = {
	open: propTypes.func,
	close: propTypes.func,
	drawerOpen: propTypes.bool,
	logOut: propTypes.func,
	user: propTypes.object,
	avatar: propTypes.string,
	isContentMoat: propTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentMoatNavbar);
