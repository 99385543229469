import { GET_REFERRAL_MANAGEMENT_SUCCESS } from 'stash/actions/api/referrals';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_REFERRAL_MANAGEMENT_SUCCESS:
			return action.response.referralsManagementView;
		default:
			return state;
	}
};
