import {
	CREATE_NUDATA_SESSION_SUCCESS,
	STASH_INIT,
	getCreateNudataSession,
	setNudataSession,
} from 'stash/actions';

export default ({ dispatch }) =>
	(next) =>
	(action) => {
		if (action.type === STASH_INIT) {
			try {
				dispatch(getCreateNudataSession());
			} catch (error) {
				console.error('create nudata session error: ', error.message);
			}
		}

		if (action.type === CREATE_NUDATA_SESSION_SUCCESS) {
			dispatch(setNudataSession(action.response.session));
		}

		return next(action);
	};
