import * as reactivationActions from 'stash/actions/reactivation';

export const initCompletedState = {};

export default (state = {}, action) => {
	switch (action.type) {
		case reactivationActions.SET_PROFILE:
		case reactivationActions.SET_IDV:
		case reactivationActions.SET_INVESTOR_PROFILE:
		case reactivationActions.SET_TIER_SELECTION:
		case reactivationActions.SET_PLASTIC_FUNDING_SOURCE:
		case reactivationActions.SET_BILLING_SUMMARY:
		case reactivationActions.SET_SILENT_IDV:
			return {
				...state,
				completed: {
					...state.completed,
					[action.name]: action.value,
				},
			};
		default:
			return state;
	}
};
