import { fetchRequestWithTypes } from './index.js';

// Returns a paged list of investments based on the filter and sorts applied.
// https://staging.stsh.io/documentation/1.0/investments/investments.html

export const FETCH_INVESTMENTS_REQUEST = 'FETCH_INVESTMENTS_REQUEST';
export const FETCH_INVESTMENTS_SUCCESS = 'FETCH_INVESTMENTS_SUCCESS';
export const FETCH_INVESTMENTS_ERRORED = 'FETCH_INVESTMENTS_ERRORED';

export const postFetchInvestments = (body, searchKey) => {
	return fetchRequestWithTypes({
		types: [
			FETCH_INVESTMENTS_REQUEST,
			FETCH_INVESTMENTS_SUCCESS,
			FETCH_INVESTMENTS_ERRORED,
		],
		meta: {
			searchKey, // used for caching responses (query param string)
		},
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/browse/investments',
			body,
		},
	});
};
