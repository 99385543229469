import { fetchRequestWithTypes } from './index.js';

export const GET_PLASTIC_CARD_REQUEST = 'GET_PLASTIC_CARD_REQUEST';
export const GET_PLASTIC_CARD_SUCCESS = 'GET_PLASTIC_CARD_SUCCESS';
export const GET_PLASTIC_CARD_ERRORED = 'GET_PLASTIC_CARD_ERRORED';

export const getPlasticCard = () =>
	fetchRequestWithTypes({
		types: [GET_PLASTIC_CARD_REQUEST, GET_PLASTIC_CARD_SUCCESS, GET_PLASTIC_CARD_ERRORED],
		request: {
			method: 'GET',
			path: `/apis/plastic/api/v1/users/:uuid/cardDetails`,
		},
	});

export const GET_FUNDING_SOURCES_REQUEST = 'GET_FUNDING_SOURCES_REQUEST';
export const GET_FUNDING_SOURCES_SUCCESS = 'GET_FUNDING_SOURCES_SUCCESS';
export const GET_FUNDING_SOURCES_ERRORED = 'GET_FUNDING_SOURCES_ERRORED';

export const getFundingSources = (body) =>
	fetchRequestWithTypes({
		types: [
			GET_FUNDING_SOURCES_REQUEST,
			GET_FUNDING_SOURCES_SUCCESS,
			GET_FUNDING_SOURCES_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/funding_sources`,
			body,
		},
	});

export const POST_DEBIT_REQUEST = 'POST_DEBIT_REQUEST';
export const POST_DEBIT_SUCCESS = 'POST_DEBIT_SUCCESS';
export const POST_DEBIT_ERRORED = 'POST_DEBIT_ERRORED';

export const postDebit = ({ cardData, ignoreRestrictions = false }) =>
	fetchRequestWithTypes({
		types: [POST_DEBIT_REQUEST, POST_DEBIT_SUCCESS, POST_DEBIT_ERRORED],
		request: {
			method: 'POST',
			path: `/apis/plastic/api/v1/users/:uuid/funding_sources`,
			body: {
				funding_source: {
					card: {
						name_on_card: cardData.name_on_card,
						token: cardData.token,
						last_four: cardData.lastFour,
						expiration_date: cardData.expirationDate,
					},
					billing_address: {
						postal_code: cardData.zipcode,
					},
				},
				credit_cards_allowed: true,
				prepaid_cards_allowed: true,
				// `ignore_restrictions` allows OB2.0 users in a closed state to link a card
				// during the reactivation flow
				ignore_restrictions: ignoreRestrictions,
			},
		},
	});

export const PATCH_DEBIT_REQUEST = 'PATCH_DEBIT_REQUEST';
export const PATCH_DEBIT_SUCCESS = 'PATCH_DEBIT_SUCCESS';
export const PATCH_DEBIT_ERRORED = 'PATCH_DEBIT_ERRORED';

export const patchDebit = ({ cardData, ignoreRestrictions = false }) =>
	fetchRequestWithTypes({
		types: [PATCH_DEBIT_REQUEST, PATCH_DEBIT_SUCCESS, PATCH_DEBIT_ERRORED],
		request: {
			method: 'PATCH',
			path: `/apis/plastic/api/v1/users/:uuid/funding_sources`,
			body: {
				funding_source: {
					card: {
						name_on_card: cardData.name_on_card,
						token: cardData.token,
						last_four: cardData.lastFour,
						expiration_date: cardData.expirationDate,
					},
					billing_address: {
						postal_code: cardData.zipcode,
					},
				},
			},
		},
	});

export const POST_DEBIT_TRANSFER_REQUEST = 'POST_DEBIT_TRANSFER_REQUEST';
export const POST_DEBIT_TRANSFER_SUCCESS = 'POST_DEBIT_TRANSFER_SUCCESS';
export const POST_DEBIT_TRANSFER_ERRORED = 'POST_DEBIT_TRANSFER_ERRORED';

export const postDebitTransfer = ({
	account_id,
	amount,
	funding_source_id,
	transfer_type = 'INCOMING',
}) => {
	return fetchRequestWithTypes({
		types: [
			POST_DEBIT_TRANSFER_REQUEST,
			POST_DEBIT_TRANSFER_SUCCESS,
			POST_DEBIT_TRANSFER_ERRORED,
		],
		meta: {
			accountId: account_id,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${account_id}/funding_source_transfers`,
			body: {
				funding_source_transfer: {
					amount,
					funding_source_id,
					transfer_type,
				},
			},
		},
	});
};
