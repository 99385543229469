/**
 * sms.js
 *
 * This file handles action creators for sending SMS
 * messages.
 *
 */

import { logBrazeEvent } from 'stash/middleware/braze';
import { getCookie } from '../utils/cookies';

export const sendMeTheApp = (number) => {
	return function (dispatch, getState) {
		return new Promise((resolve, reject) => {
			const source = getCookie('utm_source');
			const campaign = getCookie('utm_campaign');
			// Only send SMS in prod.
			if (process.env.NODE_ENV !== 'production') {
				console.warn('SMS not sent. Not in production.');
				return resolve();
			}

			logBrazeEvent('sms_app_download_link', {
				source,
				campaign,
				phone_number: number,
			});
		});
	};
};
