export const selectNudataSession = (state) =>
	state?.application?.nudata?.session;

export const selectNudataWidgetData = () => {
	let widgetData = '';

	try {
		widgetData = document
			.querySelector("input[name='nds-pmd']")
			.getAttribute('value');
	} catch (error) {
		console.error('Error getting nudata biometrics: ', error.message);
	}

	return widgetData;
};
