import { GET_RETENTION_OFFER_SUCCESS } from '../../actions/api/retentionOffer';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_RETENTION_OFFER_SUCCESS:
			const offer = action.response.offer;

			return {
				id: offer.id,
				amount: offer.amount.value,
				currency: offer.amount.currency,
			};
		default:
			return state;
	}
};
