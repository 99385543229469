import { fetchRequestWithTypes } from './index.js';

// Gets a list of our investment groups
// https://staging.stsh.io/documentation/1.0/groups/index.html

export const GET_INVESTMENT_GROUPS_REQUEST = 'GET_INVESTMENT_GROUPS_REQUEST';
export const GET_INVESTMENT_GROUPS_SUCCESS = 'GET_INVESTMENT_GROUPS_SUCCESS';
export const GET_INVESTMENT_GROUPS_ERRORED = 'GET_INVESTMENT_GROUPS_ERRORED';

export const getInvestmentGroups = (type = 'etf') =>
	fetchRequestWithTypes({
		types: [
			GET_INVESTMENT_GROUPS_REQUEST,
			GET_INVESTMENT_GROUPS_SUCCESS,
			GET_INVESTMENT_GROUPS_ERRORED,
		],
		meta: {
			type,
		},
		request: {
			method: 'GET',
			path: `/apis/api/v1/shop/groups?type=${type}`,
		},
	});

// Gets all investments by group
// https://staging.stsh.io/documentation/1.0/groups/show.html

export const GET_INVESTMENTS_BY_GROUP_REQUEST =
	'GET_INVESTMENTS_BY_GROUP_REQUEST';
export const GET_INVESTMENTS_BY_GROUP_SUCCESS =
	'GET_INVESTMENTS_BY_GROUP_SUCCESS';
export const GET_INVESTMENTS_BY_GROUP_ERRORED =
	'GET_INVESTMENTS_BY_GROUP_ERRORED';

export const getInvestmentsByGroup = (group, type) =>
	fetchRequestWithTypes({
		types: [
			GET_INVESTMENTS_BY_GROUP_REQUEST,
			GET_INVESTMENTS_BY_GROUP_SUCCESS,
			GET_INVESTMENTS_BY_GROUP_ERRORED,
		],
		meta: {
			group,
		},
		request: {
			method: 'GET',
			path: `/apis/api/v1/shop/groups/${group}/cards?type=${type}`,
		},
	});
