import { ROOT_IDV_PATH } from 'stash/components/account-verify/constants';
import { IDV_FLOW_NEXT_STEP } from '../../../../constants/session';
import { selectDateOfBirth } from '../../../../selectors/userProfile';

const SELF_CURE_VERIFICATION_FIELDS_SCREEN_MAP = {
	MISKEYED_FIELD_SSN: () => {
		return {
			screen: 'confirm_ssn',
			fields: [
				{
					field: 'social_security_number',
					value: '',
				},
			],
		};
	},
	MISKEYED_FIELD_DOB: (state) => {
		return {
			screen: 'confirm_dob',
			fields: [
				{
					field: 'date_of_birth',
					value: selectDateOfBirth(state),
				},
			],
		};
	},
};

export const selfCureTransformers = {
	stashGETToRedux: (state, response) => {
		let fieldGroups;

		if (window.sessionStorage.getItem(IDV_FLOW_NEXT_STEP)) {
			fieldGroups = response.miskeyedFields.map((key) => {
				return SELF_CURE_VERIFICATION_FIELDS_SCREEN_MAP[key](state);
			});
		} else {
			fieldGroups = response.screens;
		}

		return fieldGroups.map((fieldGroup) => {
			return {
				screenId: serverRefToScreenId(fieldGroup.screen),
				fields: fieldGroup.fields.reduce((acc, current) => {
					if (current.field === 'home_street_address') {
						//street address comes in an array, so we flatten it here
						current.value.forEach((field, index) => {
							acc[`${current.field}_${index}`] = field;
						});
					} else {
						acc[current.field] = current.value;
					}
					return acc;
				}, {}),
			};
		});
	},
	reduxToStashPOST: (grouped) => {
		return grouped.reduce((acc, group) => {
			let newFields;
			if (group.screenId === 'address') {
				// convert address back into array
				const homeStreetAddress = [group.fields.home_street_address_0];
				if (group.fields.home_street_address_1) {
					homeStreetAddress.push(group.fields.home_street_address_1);
				}
				newFields = {
					home_street_address: homeStreetAddress,
					home_city: group.fields.home_city,
					home_state: group.fields.home_state,
					home_postal_code: group.fields.home_postal_code,
				};
			} else {
				newFields = group.fields;
			}
			return { ...acc, ...newFields };
		}, {});
	},
	reduxToCustomerIdentityPut: (grouped) => {
		return grouped.reduce((acc, group) => {
			const fields =
				group.screenId === 'ssn'
					? { ssn: group.fields.social_security_number }
					: group.screenId === 'dob'
					? { birthdate: dateStringToFields(group.fields.date_of_birth) }
					: {};
			return { ...acc, ...fields };
		}, {});
	},
};
export const serverRefToScreenId = (serverRef) => serverRef.split('_').pop();
export const screenIdToUrl = (screenId) => `${ROOT_IDV_PATH}/${screenId}`;
const dateStringToFields = (dateString) => {
	const parts = (dateString ?? '').split('-');
	return {
		year: parseInt(parts[0]),
		month: parseInt(parts[1]),
		day: parseInt(parts[2]),
	};
};
