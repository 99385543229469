import { createSelector } from 'reselect';
import queryString from 'query-string';
import { DEFAULT_SEARCH_STRING } from 'stash/constants/investmentFilters';

export const selectQueryParams = createSelector(
	(props) => props?.location?.search ?? '',
	(searchString) => queryString.parse(searchString) || {}
);

// uses the comma style array formatting
export const selectSearchParams = createSelector(
	(props) => props?.location?.search ?? '',
	(searchString) =>
		queryString.parse(searchString || DEFAULT_SEARCH_STRING, {
			arrayFormat: 'comma',
			sort: false,
		})
);
