import { LOCATION_CHANGE } from 'connected-react-router';
import { MODAL_PAGE_RENDERED } from 'stash/actions';

const maxLocationArrayLength = 15;

const isSameUrl = (firstLocation = {}, secondLocation = {}) =>
	firstLocation.pathname + firstLocation.search ===
	secondLocation.pathname + secondLocation.search;

const isAuthRoute = (location) => {
	return location?.pathname === '/authorize';
};

const ModalReducer = (state = [], action) => {
	if (action.type === LOCATION_CHANGE) {
		const location = action.payload.location;

		if (isAuthRoute(location)) return state;

		if (state.length === 0) return [{ location }];

		if (isSameUrl(state[0].location, action.payload.location)) return state;

		return [{ location }, ...state].slice(0, maxLocationArrayLength);
	} else if (action.type === MODAL_PAGE_RENDERED) {
		return [{ ...state[0], isModal: true }, ...state.slice(1)];
	}
	return state;
};

export default ModalReducer;
