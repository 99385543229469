import { GET_LAUNCH_INFO_SUCCESS, GET_LAUNCH_LOCATION_INFO_SUCCESS  } from 'stash/actions/api';

export const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_LAUNCH_INFO_SUCCESS:
		case GET_LAUNCH_LOCATION_INFO_SUCCESS:
			return {
				...state,
				location: action.response.location,
			};
		default:
			return state;
	}
};
