import { GET_FIRST_CARDS_SUCCESS } from '../../actions/api/card';

export default (state = [], action) => {
	switch (action.type) {
		case GET_FIRST_CARDS_SUCCESS:
			return action.response.cards;
		default:
			return state;
	}
};
