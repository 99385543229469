import { useSelector } from 'react-redux';

export const selectAdvisoryAgreement = (state) => {
	return state?.entities?.advisory_agreements?.signature?.esignedAt;
};

export function useAdvisoryAgreements() {
	return useSelector((state) => state?.entities?.advisory_agreements);
}

export function useAdvisoryAgreementDisclosure() {
	return useSelector(
		(state) => state?.entities?.advisory_agreements?.disclosureStatement
	);
}

export function useCoreAgreementDisclosure() {
	return useSelector(
		(state) => state?.entities?.advisory_agreements?.coreDisclosureStatement
	);
}
