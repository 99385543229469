import { fetchRequestWithTypes } from './index.js';

// Verifies user email address via token confirmation.

export const POST_EMAIL_VERIFICATION_REQUEST =
	'POST_EMAIL_VERIFICATION_REQUEST';
export const POST_EMAIL_VERIFICATION_SUCCESS =
	'POST_EMAIL_VERIFICATION_SUCCESS';
export const POST_EMAIL_VERIFICATION_ERRORED =
	'POST_EMAIL_VERIFICATION_ERRORED';

export const postEmailVerification = ({ token }) =>
	fetchRequestWithTypes({
		types: [
			POST_EMAIL_VERIFICATION_REQUEST,
			POST_EMAIL_VERIFICATION_SUCCESS,
			POST_EMAIL_VERIFICATION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/email_verification/verify`,
			body: {
				code: token,
			},
		},
	});

// Generates a new email verification token and sends the new token.
// https://edge.stsh.io/documentation/1.0/email_verifications/send_code.html

export const POST_EMAIL_SEND_CODE_REQUEST = 'POST_EMAIL_SEND_CODE_REQUEST';
export const POST_EMAIL_SEND_CODE_SUCCESS = 'POST_EMAIL_SEND_CODE_SUCCESS';
export const POST_EMAIL_SEND_CODE_ERRORED = 'POST_EMAIL_SEND_CODE_ERRORED';

export const postEmailSendCode = () =>
	fetchRequestWithTypes({
		types: [
			POST_EMAIL_SEND_CODE_REQUEST,
			POST_EMAIL_SEND_CODE_SUCCESS,
			POST_EMAIL_SEND_CODE_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/email_verification/send_code`,
		},
	});

// TEST ENVIRONMENTS ONLY. Gets the code for a user's email_verification if an unverified one exists.
// https://edge.stsh.io/documentation/1.0/email_verifications/simulated_code.html

export const GET_SIMULATED_CODE_REQUEST = 'GET_SIMULATED_CODE_REQUEST';
export const GET_SIMULATED_CODE_SUCCESS = 'GET_SIMULATED_CODE_SUCCESS';
export const GET_SIMULATED_CODE_ERRORED = 'GET_SIMULATED_CODE_ERRORED';

export const getSimulatedCode = () =>
	fetchRequestWithTypes({
		types: [
			GET_SIMULATED_CODE_REQUEST,
			GET_SIMULATED_CODE_SUCCESS,
			GET_SIMULATED_CODE_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/email_verification/simulated_code`,
		},
	});
