import { fetchRequestWithTypes } from './index.js';

// Creates a withdraw transfer

export const POST_WITHDRAW_REQUEST = 'POST_WITHDRAW_REQUEST';
export const POST_WITHDRAW_SUCCESS = 'POST_WITHDRAW_SUCCESS';
export const POST_WITHDRAW_ERRORED = 'POST_WITHDRAW_ERRORED';

export const postWithdraw = (accountId, amount) =>
	fetchRequestWithTypes({
		types: [
			POST_WITHDRAW_REQUEST,
			POST_WITHDRAW_SUCCESS,
			POST_WITHDRAW_ERRORED,
		],
		meta: {
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transfers/withdraw`,
			body: {
				transfer: {
					amount,
				},
			},
		},
	});

// Creates a deposit transfer

export const POST_DEPOSIT_REQUEST = 'POST_DEPOSIT_REQUEST';
export const POST_DEPOSIT_SUCCESS = 'POST_DEPOSIT_SUCCESS';
export const POST_DEPOSIT_ERRORED = 'POST_DEPOSIT_ERRORED';

export const postDeposit = (accountId, amount) =>
	fetchRequestWithTypes({
		types: [POST_DEPOSIT_REQUEST, POST_DEPOSIT_SUCCESS, POST_DEPOSIT_ERRORED],
		meta: {
			accountId,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transfers/deposit`,
			body: {
				transfer: {
					amount,
				},
			},
		},
	});

// Creates a "deposit" to a user's investment

export const POST_DEPOSIT_WITH_INVESTMENT_REQUEST =
	'POST_DEPOSIT_WITH_INVESTMENT';
export const POST_DEPOSIT_WITH_INVESTMENT_SUCCESS =
	'POST_DEPOSIT_WITH_INVESTMENT_SUCCESS';
export const POST_DEPOSIT_WITH_INVESTMENT_ERRORED =
	'POST_DEPOSIT_WITH_INVESTMENT_ERRORED';

export const postDepositWithInvestment = (
	accountId,
	card_id,
	amount,
	contribution_year
) => {
	const body = {
		deposit: {
			amount,
			contribution_year,
		},
	};

	const depositInvestment = card_id && card_id > -1;
	if (depositInvestment) {
		body.buy = {
			card_id,
			amount,
		};
	}

	return fetchRequestWithTypes({
		types: [
			POST_DEPOSIT_WITH_INVESTMENT_REQUEST,
			POST_DEPOSIT_WITH_INVESTMENT_SUCCESS,
			POST_DEPOSIT_WITH_INVESTMENT_ERRORED,
		],
		meta: {
			card_id,
		},
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/transactions/buy_with_deposit`,
			body,
		},
	});
};

// Creates a multi-account funding deposits with recurring transfers.
// https://github.com/StashInvest/stash-transfer-router/blob/edge/docs/api/swagger.yml#L145
export const POST_REGISTRATION_TRANSFER_REQUEST = 'POST_REGISTRATION_TRANSFER';
export const POST_REGISTRATION_TRANSFER_SUCCESS =
	'POST_REGISTRATION_TRANSFER_SUCCESS';
export const POST_REGISTRATION_TRANSFER_ERRORED =
	'POST_REGISTRATION_TRANSFER_ERRORED';

export const postRegistrationTransfer = (accountsToFund) => {
	const initialTransfers = Object.values(accountsToFund)
		.filter((accountToFund) => {
			return parseFloat(accountToFund.amount) > 0;
		})
		.map((accountToFund) => {
			return {
				destinationAccountType: accountToFund.typeEnum,
				frequency: accountToFund.frequency,
				amount: {
					value: accountToFund.amount,
					currency: 'USD',
				},
			};
		});

	const body = {
		initialTransfers,
	};

	return fetchRequestWithTypes({
		types: [
			POST_REGISTRATION_TRANSFER_REQUEST,
			POST_REGISTRATION_TRANSFER_SUCCESS,
			POST_REGISTRATION_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/moneyMovements/api/v1/users/:uuid/transfers/registration/submit`,
			body,
		},
	});
};
