import { combineReducers } from 'redux';
import context from './context';
import disclosure from './disclosure';
import dialog from './dialog';
import dashboard from './dashboard';
import debitSignUp from './debitSignUp';
import deposit from './deposit';
import investments from './investments';
import search from './search';
import nav from './nav';
import nudata from './nudata';
import reactivation from './reactivation';
import signUp from './signUp';
import toast from './toast';
import linkBank from './linkBank';
import drawer from './drawer';
import deferredActions from './deferredActions';
import refreshTokenPending from './refreshTokenPending';
import modal from './modal';
import productEvents from './productEvents';

export default combineReducers({
	context,
	dashboard,
	debitSignUp,
	drawer,
	deposit,
	dialog,
	disclosure,
	investments,
	linkBank,
	nav,
	nudata,
	reactivation,
	refreshTokenPending,
	search,
	signUp,
	toast,
	deferredActions,
	modal,
	productEvents,
});
