import { fetchRequestWithTypes } from './index';

export const GET_LAUNCH_INFO_REQUEST = 'GET_LAUNCH_INFO_REQUEST';
export const GET_LAUNCH_INFO_SUCCESS = 'GET_LAUNCH_INFO_SUCCESS';
export const GET_LAUNCH_INFO_ERRORED = 'GET_LAUNCH_INFO_ERRORED';

export const getLaunchInfo = () =>
	fetchRequestWithTypes({
		types: [
			GET_LAUNCH_INFO_REQUEST,
			GET_LAUNCH_INFO_SUCCESS,
			GET_LAUNCH_INFO_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/onboarding/api/v2/users/:uuid/launch-info`,
		},
	});

export const GET_LAUNCH_LOCATION_INFO_REQUEST =
	'GET_LAUNCH_LOCATION_INFO_REQUEST';
export const GET_LAUNCH_LOCATION_INFO_SUCCESS =
	'GET_LAUNCH_LOCATION_INFO_SUCCESS';
export const GET_LAUNCH_LOCATION_INFO_ERRORED =
	'GET_LAUNCH_LOCATION_INFO_ERRORED';

export const getLaunchLocationInfo = () =>
	fetchRequestWithTypes({
		types: [
			GET_LAUNCH_LOCATION_INFO_REQUEST,
			GET_LAUNCH_LOCATION_INFO_SUCCESS,
			GET_LAUNCH_LOCATION_INFO_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/onboarding/api/v2/users/:uuid/location`,
		},
	});

export const GET_IS_CORE_USER_REQUEST = 'GET_IS_CORE_USER_REQUEST';
export const GET_IS_CORE_USER_SUCCESS = 'GET_IS_CORE_USER_SUCCESS';
export const GET_IS_CORE_USER_ERRORED = 'GET_IS_CORE_USER_ERRORED';

// CORE test flag for Activation team.
export const getIsCoreUser = () =>
	fetchRequestWithTypes({
		types: [
			GET_IS_CORE_USER_REQUEST,
			GET_IS_CORE_USER_SUCCESS,
			GET_IS_CORE_USER_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/onboarding/api/v2/users/:uuid/isCoreUser',
		},
	});
