import {
	GET_FUNDING_SOURCES_SUCCESS,
	POST_DEBIT_SUCCESS,
} from '../../actions/api/fundingSource';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_FUNDING_SOURCES_SUCCESS:
			return action.response.funding_sources.reduce((acc, funding_source) => {
				return {
					...acc,
					[funding_source.provider_type]: funding_source,
				};
			}, {});
		case POST_DEBIT_SUCCESS:
			const funding_source = action.response.funding_source;
			return {
				...state,
				[funding_source.provider_type]: funding_source,
			};
		default:
			return state;
	}
};
