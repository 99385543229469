export const FETCH_REQUEST_WITH_TYPES = 'FETCH_REQUEST_WITH_TYPES';

// Creates an action which is interpreted by /middleware/fetch.js.
// The fetch middleware is passed action types and promises
// to dispatch them when the request is made.
export const fetchRequestWithTypes = (action) => {
	return {
		type: FETCH_REQUEST_WITH_TYPES,
		...action,
	};
};

export * from './account';
export * from './accountVerification';
export * from './addressVerification';
export * from './advisoryAgreement';
export * from './auth';
export * from './autoStash';
export * from './bankAccount';
export * from './bookmarks';
export * from './buffet';
export * from './card';
export * from './cartItems';
export * from './chalet';
export * from './clearing';
export * from './coach';
export * from './drip';
export * from './emailVerification';
export * from './harmonyBank';
export * from './fundingSource';
export * from './gifting';
export * from './groups';
export * from './investorApplication';
export * from './investments';
export * from './iraDistributions';
export * from './onboarding';
export * from './moneyMovements';
export * from './nudata';
export * from './phoneVerification';
export * from './prices';
export * from './profile';
export * from './plans';
export * from './platformTiers';
export * from './quotes';
export * from './referrals';
export * from './retentionOffer';
export * from './search';
export * from './securitiesLending';
export * from './statusMessages';
export * from './subscriptions';
export * from './transactions';
export * from './transfers';
export * from './trustedContact';
export * from './twoFactorAuth';
export * from './user';
export * from './userGuidance';
export * from './updateIncome';
