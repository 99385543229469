import {
	GET_WIMAL_INTRO_SUCCESS,
	GET_WIMAL_NEXT_STEP_SUCCESS,
	GET_VERIFICATION_NEXT_STEP_SUCCESS,
	GET_ACCOUNT_VERIFICATION_SCREENS_SUCCESS,
	GET_WIMAL_INTRO_ERRORED,
} from 'stash/actions/api';
import {
	CONFIRM_VERIFICATION_DETAILS,
	UPLOAD_DOCUMENT_SUCCESS,
	START_VERIFICATION_DETAILS,
} from 'stash/actions';
import { selfCureTransformers } from 'stash/components/account-verify/self-cure/hooks/utils';

export const initialState = {
	intro: {},
	nextStep: {},
	WIMALNextStep: {},
	uploadedDocuments: [],
	verification: {
		screens: [],
		started: false,
	},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_WIMAL_INTRO_SUCCESS: {
			return {
				...state,
				intro: action.response,
			};
		}
		case GET_WIMAL_INTRO_ERRORED: {
			return {
				...state,
				intro: {
					error:
						'There was a problem retrieving your account information, please try again later.',
				},
			};
		}
		case GET_WIMAL_NEXT_STEP_SUCCESS: {
			return {
				...state,
				WIMALNextStep: action.response,
			};
		}
		case GET_VERIFICATION_NEXT_STEP_SUCCESS: {
			return {
				...state,
				nextStep: action.response,
			};
		}
		case UPLOAD_DOCUMENT_SUCCESS: {
			return {
				...state,
				uploadedDocuments: [...state.uploadedDocuments, action.uploadedDocument],
			};
		}
		case GET_ACCOUNT_VERIFICATION_SCREENS_SUCCESS: {
			return {
				...state,
				verification: {
					...state.verification,
					screens: selfCureTransformers.stashGETToRedux(state, action.response),
				},
			};
		}
		case START_VERIFICATION_DETAILS: {
			return {
				...state,
				verification: { ...state.verification, started: true },
			};
		}
		case CONFIRM_VERIFICATION_DETAILS: {
			return {
				...state,
				verification: {
					...state.verification,
					screens: state.verification.screens.map((screen) => {
						return screen.screenId === action.screenData.screenId
							? action.screenData
							: screen;
					}),
				},
			};
		}
		default:
			return state;
	}
};
