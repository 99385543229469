import React from 'react';
import { GlobalStyle, useThemePreferenceContext } from '@stashinvest/ui';
import ErrorBoundary from 'stash/components/error-boundary/ErrorBoundary';
import './App.css';
import GlobalToast from 'stash/components/toast/Toast';

const modes = {
	light: 'light',
	dark: 'dark',
};

function App(props) {
	const { userModePreference } = useThemePreferenceContext();

	return (
		<div className={modes[userModePreference]}>
			<GlobalStyle />
			<ErrorBoundary>
				{props.children}
				<GlobalToast />
			</ErrorBoundary>
		</div>
	);
}

export default App;
