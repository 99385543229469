import { fetchRequestWithTypes } from './index.js';

// Generates a new phone number verification token and sends the new code.
// https://edge.stsh.io/documentation/1.0/phone_number_verifications/send_code.html
export const POST_PHONE_VERIFY_SEND_REQUEST = 'POST_PHONE_VERIFY_SEND_REQUEST';
export const POST_PHONE_VERIFY_SEND_SUCCESS = 'POST_PHONE_VERIFY_SEND_SUCCESS';
export const POST_PHONE_VERIFY_SEND_ERRORED = 'POST_PHONE_VERIFY_SEND_ERRORED';

export const postPhoneVerificationSend = (via) => {
	return fetchRequestWithTypes({
		types: [
			POST_PHONE_VERIFY_SEND_REQUEST,
			POST_PHONE_VERIFY_SEND_SUCCESS,
			POST_PHONE_VERIFY_SEND_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/phone_number_verification/send_code`,
			body: {
				via,
			},
		},
	});
};

// Verifies user email address via token confirmation.

export const POST_PHONE_VERIFICATION_REQUEST =
	'POST_PHONE_VERIFICATION_REQUEST';
export const POST_PHONE_VERIFICATION_SUCCESS =
	'POST_PHONE_VERIFICATION_SUCCESS';
export const POST_PHONE_VERIFICATION_ERRORED =
	'POST_PHONE_VERIFICATION_ERRORED';

export const postPhoneVerificationVerify = (code) => {
	return fetchRequestWithTypes({
		types: [
			POST_PHONE_VERIFICATION_REQUEST,
			POST_PHONE_VERIFICATION_SUCCESS,
			POST_PHONE_VERIFICATION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/phone_number_verification/verify`,
			body: {
				phone_number_verification: {
					code,
				},
			},
		},
	});
};
