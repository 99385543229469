import { fetchRequestWithTypes } from './index.js';

// PATCH a profile.

export const PATCH_PROFILE_REQUEST = 'PATCH_PROFILE_REQUEST';
export const PATCH_PROFILE_SUCCESS = 'PATCH_PROFILE_SUCCESS';
export const PATCH_PROFILE_ERRORED = 'PATCH_PROFILE_ERRORED';

export const patchProfile = (body) => {
	return fetchRequestWithTypes({
		types: [
			PATCH_PROFILE_REQUEST,
			PATCH_PROFILE_SUCCESS,
			PATCH_PROFILE_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: '/apis/api/v1/users/:uuid/profile',
			body,
		},
	});
};
