function safeLocalStorage(method) {
	return function callLocalStorage(...args) {
		try {
			return window.localStorage[method](...args);
		} catch (error) {
			console.warn(`[ERROR] Failed to call localStorage.${method}`, error);
		}
	};
}

const localStorage = {
	getItem: safeLocalStorage('getItem'),
	setItem: safeLocalStorage('setItem'),
	removeItem: safeLocalStorage('removeItem'),
};

export default localStorage;
