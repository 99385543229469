// dispatch action when user sends feedback
export const STASHER_SEND_FEEDBACK = 'STASHER_SEND_FEEDBACK';

export const submitFeedbackAction = (data) => ({
	type: STASHER_SEND_FEEDBACK,
	data: {
		origin: data.origin,
		feedback: data.feedback,
	},
});

// dispatch action when feedback form is shown after a certain action ie: autostash turn-off
export const SHOW_STASHER_FEEDBACK = 'SHOW_STASHER_FEEDBACK';

export const showFeedbackAction = (data) => ({
	type: SHOW_STASHER_FEEDBACK,
	data: {
		origin: data.origin,
	},
});

// dispatch action when feedback form is closed
export const STASHER_CLOSE_FEEDBACK = 'STASHER_CLOSE_FEEDBACK';

export const closeFeedback = (data) => ({
	type: STASHER_CLOSE_FEEDBACK,
	data: {
		origin: data.origin,
	},
});
