export default [
	{ from: '/bank-accounts', to: `/manage-bank-account` },
	{ from: '/bank-accounts/confirm-micros', to: '/manage-bank-account' },
	{ from: '/manage-accounts', to: '/manage-account' },
	{ from: '/manage-bank-accounts', to: '/manage-bank-account' },
	{ from: '/external-bank-link', to: '/bank-accounts/new' },
	{ from: '/instant-verification', to: '/bank-accounts/new/instant' },
	{ from: '/invest-sign-up', to: '/sign-up-invest-add-on' },
	{ from: '/invest-sign-up-add-on', to: '/sign-up-invest-add-on' },
	{ from: '/retire-sign-up', to: '/retire' },
	{ from: '/auto-save', to: '/auto-stash' },
	{ from: '/auto-save/set-schedule', to: '/auto-stash/set-schedule' },
	{ from: '/auto-save/roundups', to: '/auto-stash/round-ups' },
	{ from: '/auto-save/smart-stash', to: '/' },
	{ from: '/auto-stash/smart-stash', to: '/' },
	{ from: '/auto-stashes', to: '/auto-stash' },
	{ from: '/auto-stashes/learn-more', to: '/auto-stash' },
	{ from: '/subscription', to: '/plan-details' },
	{ from: '/manage-subscription/tiers', to: '/manage-subscription' },
	{ from: '/manage-subscription/manage', to: '/plan-details' },

	// These redirects are done manually in index.js to preserve account_id query param
	// { from: '/set-schedule', to: '/auto-stash/set-schedule' },
	// { from: '/set-schedule/setup', to: '/auto-stash/set-schedule/setup' },

	{ from: '/phone-verification', to: '/security' },
	{ from: '/email-verificaton', to: '/security' },
	{ from: '/profile/email', to: '/security' },
	{ from: '/archive', to: `/archive/manage-account` },
	{ from: '/sign-up', to: `/sign-up/get-started` },
	{ from: '/sign-up-invest', to: `/sign-up/get-started` },
	{ from: '/sign-up-invest/*', to: `/sign-up/get-started` }, // NOTE: sign-up-invest-add-on still in-use
	{ from: '/sign-up-custodial', to: `/sign-up/get-started` },
	{ from: '/sign-up-custodial/*', to: `/sign-up/get-started` },
	{ from: '/cards', to: `/investments` },
	{ from: '/cards/all', to: `/investments` },
	{ from: '/cards/companies', to: '/investments?investment_type=STOCK' },
	{ from: '/investments/etfs', to: '/investments?investment_type=ETF' },
	{ from: '/investments/stocks', to: '/investments?investment_type=STOCK' },
	{ from: '/update-card', to: '/payment/add-card' },
	{ from: '/gifting', to: `/` },
	{ from: '/home', to: `/` },
	{ from: '/reopen-account', to: '/reopen-account/start' },
	{ from: '/cancel-stash', to: '/cancel-stash/confirm' },
	{ from: '/account-verifications', to: '/account-verify' },
	{
		from: '/onboarding-account-verifications',
		to: '/account-verify?idvFlowNextStep=documents',
	},
	{
		from: '/reactivation-account-verifications',
		to: '/account-verify?idvFlowNextStep=documents&idvFlowParent=reactivation',
	},
	{
		from: '/reopen-account-verifications',
		to: '/account-verify?idvFlowNextStep=documents&idvFlowParent=reopen',
	},
	{ from: '/document-uploads', to: '/account-verify' },
	{ from: '/smart-invest-sign-up', to: '/smart-invest-sign-up/get-started' },
	{ from: '/custodian-sign-up', to: '/custodial-sign-up/get-started' },
	{ from: '/custodial-sign-up', to: '/custodial-sign-up/get-started' },
	{ from: '/portfolio-builder', to: '/' },
	{ from: '/browse-collection/companies', to: '/browse-collection/stocks' },
	{ from: '/browse-collection/bookmarks', to: '/bookmarks' },
	{ from: '/insurance/home', to: '/solutions/lemonade/home-insurance' },
	{ from: '/debit/settings/statements', to: '/documents/bankStatements' },
	{ from: '/debit/moveMoney', to: '/transfer/sources' },
	// temporary, once all the code related is deleted we can also remove below
	{ from: '/tips/crypto-diversification', to: '/invest' },
	{ from: '/tips/crypto-pros-cons', to: '/invest' },
	{ from: '/crypto-sign-up', to: '/invest' },
	{ from: '/crypto-sign-up/get-started', to: '/invest' },
	{ from: '/crypto-calculator', to: '/invest' },
	{
		from: '/user-profile/debit/settings/changeaddress',
		to: '/debit/settings/changeaddress',
	},

	// old mobile deeplinks to support
	{ from: '/account', to: '/account-settings' },
	{ from: '/discover', to: '/investments' },
	{ from: '/my_stash/balance', to: '/' },
	{ from: '/my_stash/portfolio', to: '/portfolio' },
	{ from: '/account/manage_funds/account_history', to: '/activity' },
	{ from: '/account/manage_funds/auto_stash', to: '/auto-stash' },
	{ from: '/account/manage_funds/deposit_funds', to: '/transfer' },
	{ from: '/account/manage_funds/withdraw_funds', to: '/transfer' },
	{ from: '/account/manage_funds/invite_friends', to: '/referrals' },
	{ from: '/debit/transfer', to: '/transfer' },
	{ from: '/deposits/new', to: '/transfer' },

	//appetizer redirects
	{ from: '/appetizer/billPay', to: '/debit' },
	{ from: '/appetizer/eCash', to: '/debit/eCash' },
	{ from: '/appetizer/stockback', to: '/debit/stockBack' },
	{ from: '/appetizer/stockback/roundups', to: '/debit/stockBack/roundups' },
	{ from: '/appetizer/stockback/set-default', to: '/debit/stockBack/custom' },

	// This redirect is handled in the `BrowseRedirect.js` file
	// { from: '/browse-collection', to: '/browse-collection/companies' },

	// This does not exist on native or web - do not want to show unavailable messaging
	{ from: '/debit/billpay', to: '/debit' },

	{ from: '/debit/onboarding/card', to: '/debit/onboarding' },
	{ from: '/invest', to: '/my-stash' },
];
