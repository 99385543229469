import {
	SET_CONTINUATION_PATH,
	CLEAR_CONTINUATION_PATH,
} from '../../actions/application.js';

export default (state = false, action) => {
	switch (action.type) {
		case SET_CONTINUATION_PATH:
			return {
				continuationPath: action.continuationPath,
			};
		case CLEAR_CONTINUATION_PATH:
			return {};
		default:
			return { ...state };
	}
};
