import { useState, useEffect, useRef } from 'react';
import safeLocalStorage from './localStorage';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';

// From https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export function useInterval(callback, delay) {
	const savedCallback = useRef();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

export function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

export function useOnChange(value, callback) {
	const previousValue = usePrevious(value);
	if (previousValue !== value) {
		callback(previousValue);
	}
}

export function useOnMount(callback) {
	useEffect(callback, []);
}
export function useOnUnMount(callback) {
	useEffect(() => {
		return callback;
	}, []);
}

// From https://usehooks.com/useDebounce/
export function useDebounce(value, delay = 300) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
}

export function useScrollTop(x = 0, y = 0) {
	useEffect(() => {
		window.scrollTo && window.scrollTo(x, y);
	}, [x, y]);
}

export function useDismissible(itemKey) {
	const LOCAL_STORAGE_KEY = 'stashDismissed';
	const [dismissed, setDismissed] = useState(getDismissedItems().includes(itemKey));

	function getDismissedItems() {
		try {
			const data = JSON.parse(safeLocalStorage.getItem(LOCAL_STORAGE_KEY));
			return Array.isArray(data) ? data : [];
		} catch (err) {
			return [];
		}
	}

	function dismiss() {
		const dismissedItems = getDismissedItems().concat([itemKey]);
		safeLocalStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dismissedItems));
		setDismissed(true);
	}

	return [!dismissed, dismiss];
}
export function useModal() {
	const [isOpen, setIsOpen] = useState(false);
	const open = () => setIsOpen(true);
	const close = () => setIsOpen(false);

	return { isOpen, open, close };
}

export function useQueryParams() {
	const search = useLocation().search;
	const urlSearchParams = new URLSearchParams(search);
	const params = Object.fromEntries(urlSearchParams.entries());
	return params;
}

export function useReplaceQueryParams() {
	const history = useHistory();
	const query = useQueryParams();
	return (params) => {
		history.replace({
			search: queryString.stringify({ ...query, ...params }),
		});
	};
}
