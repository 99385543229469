import { TRACK_CARD_DETAILS_PAGE_TIME } from 'stash/actions';

export default (dataLayer, getState, action) => {
	if (action.type === TRACK_CARD_DETAILS_PAGE_TIME) {
		dataLayer.push({
			event: 'ReadInvestmentTile',
			properties: {
				CardId: action.card.id,
				Duration: action.duration,
			},
		});
	}
};
