import { GET_EXTERNAL_ACCOUNT_SUCCESS } from 'stash/actions/api/bankAccount';

function externalAccountReducer(state = {}, action) {
	switch (action.type) {
		case GET_EXTERNAL_ACCOUNT_SUCCESS:
			return action.response.manageExternalBankAccount || state;
		default:
			return state;
	}
}

export default externalAccountReducer;
