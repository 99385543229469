import { StashEnvironment } from 'stash/types/StashEnvironment';

export const SEGMENT_WRITE_KEYS: Record<StashEnvironment, string> = {
	production: 'y1IRbCVDdBnvyJ5OH0DShNFjMh6Kj9jO',

	// There is a single segment environment for non-production environments
	__STASH_ENV__: 'tmccVDzm0quPFpPmj0BlaMNnFGflU1A7',
	edge: 'tmccVDzm0quPFpPmj0BlaMNnFGflU1A7',
	staging: 'tmccVDzm0quPFpPmj0BlaMNnFGflU1A7',
};

export const SEGMENT_CDN_PROXY_URL = 'https://analytics.stash.com/load';
