import { POST_LEMONADE_QUOTE_SUCCESS } from '../../actions/api/lemonade';

export default (state = {}, action) => {
	switch (action.type) {
		case POST_LEMONADE_QUOTE_SUCCESS:
			return action.response.quote;
		default:
			return state;
	}
};
