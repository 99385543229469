import { fetchRequestWithTypes } from '../index';
import produce from 'immer';
export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_ERRORED = 'GET_NOTIFICATIONS_ERRORED';

export const getNotificationsSettings = () => {
	return fetchRequestWithTypes({
		types: [
			GET_NOTIFICATIONS_REQUEST,
			GET_NOTIFICATIONS_SUCCESS,
			GET_NOTIFICATIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/user/api/v1/users/:uuid/notificationPreferences`,
		},
	});
};

export const PATCH_NOTIFICATIONS_REQUEST = 'PATCH_NOTIFICATIONS_REQUEST';
export const PATCH_NOTIFICATIONS_SUCCESS = 'PATCH_NOTIFICATIONS_SUCCESS';
export const PATCH_NOTIFICATIONS_ERRORED = 'PATCH_NOTIFICATIONS_ERRORED';

export function createUpdatedNotificationPreference(data, notifications) {
	const notificationPreferences = produce(
		notifications.notificationPreferences.userPreferences.find(
			(obj) => obj.name === 'marketing'
		).preferences,
		(draft) => {
			draft.forEach((preference) => {
				preference.channels.forEach((channel) => {
					if (data.preferences[preference.name]) {
						channel.isEnabled = data.preferences[preference.name][channel.name];
					}
				});
			});
		}
	);
	const globalNotificationPreferences = produce(
		notifications.globalNotificationPreferences,
		(draft) => {
			draft.channels.forEach((channel) => {
				channel.unsubscribed = !data.global[channel.name];
			});
		}
	);

	return {
		globalNotificationPreferences,
		notificationPreferences,
	};
}

export const patchNotificationsSettings = (data, notifications) => (dispatch) => {
	const requestBody = createUpdatedNotificationPreference(data, notifications);
	const requestAction = fetchRequestWithTypes({
		types: [
			PATCH_NOTIFICATIONS_REQUEST,
			PATCH_NOTIFICATIONS_SUCCESS,
			PATCH_NOTIFICATIONS_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/user/api/v1/users/:uuid/notificationPreferences`,
			body: requestBody,
		},
	});

	dispatch(requestAction);
};
