import {
	GET_GUIDANCE_QUESTIONS_SUCCESS,
	GET_USER_GUIDANCE_SUCCESS,
	PATCH_USER_GUIDANCE_SUCCESS,
} from 'stash/actions/api/userGuidance';

const initialState = {
	questions: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_GUIDANCE_QUESTIONS_SUCCESS:
			const questions = {};
			const questionsRaw = action.response.guidance_questions;

			questionsRaw.forEach((questionData) => {
				questions[questionData.key] = {
					...questionData,
					answers: questionData.answers.map((a) => ({
						...a,
						selected: false,
					})),
				};
			});

			return {
				questions,
			};
		case GET_USER_GUIDANCE_SUCCESS:
		case PATCH_USER_GUIDANCE_SUCCESS:
			let updatedQuestionsWithAnswers = {};
			let updatedQuestion;

			const response =
				action.response.user_guidance.user_guidance_question_answers;

			response.forEach((questionToUpdate) => {
				const questionKey = questionToUpdate.question_key;
				const questionFromState = state.questions[questionKey];

				if (questionFromState) {
					updatedQuestion = {
						...questionFromState,
						answers: questionFromState.answers.map((answer) => ({
							...answer,
							selected: questionToUpdate.answers.some(
								(a) => a.key === answer.key
							),
						})),
					};

					updatedQuestionsWithAnswers[questionKey] = updatedQuestion;
				}
			});

			return {
				...state,
				questions: {
					...state.questions,
					...updatedQuestionsWithAnswers,
				},
			};
		default:
			return state;
	}
};
