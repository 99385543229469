import {
	SET_RETURN_URL,
	GET_RECURRING_TRANSFER_SUCCESS,
	POST_CONFIRM_RECURRING_TRANSFER_SUCCESS,
	POST_RECURRING_TRANSFER_SUCCESS,
	PUT_RECURRING_TRANSFER_SUCCESS,
} from '../../actions/api/recurringTransfer';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_RETURN_URL: {
			return {
				returnUrl: action.url['return-url'],
			};
		}

		case POST_CONFIRM_RECURRING_TRANSFER_SUCCESS: {
			return {
				recurringTransfer: {
					...state.recurringTransfer,
					...action.response.recurringTransferConfirmation,
				},
				returnUrl: state.returnUrl,
			};
		}

		case GET_RECURRING_TRANSFER_SUCCESS:
			return {
				recurringTransfer: {
					...state.recurringTransfer,
					...action.response.recurringTransfer,
				},
				returnUrl: state.returnUrl,
			};

		case PUT_RECURRING_TRANSFER_SUCCESS:
		case POST_RECURRING_TRANSFER_SUCCESS: {
			return {
				...state,
				recurringTransfer: {
					...state.recurringTransfer,
					...action.response.recurringTransfer,
				},
			};
		}

		default:
			return state;
	}
};
