import { GET_MONTHLY_MARKET_REPORTS_SUCCESS } from 'stash/actions/api';

export const initialState = {};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_MONTHLY_MARKET_REPORTS_SUCCESS:
			return {
				...state,
				is_available: action.response.is_available,
				reports: action.response.reports,
			};
		default:
			return state;
	}
};
