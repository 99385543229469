import { fetchRequestWithTypes } from './index';

export const SET_RETURN_URL = 'SET_RETURN_URL';

export function setReturnUrl(url) {
	return {
		type: SET_RETURN_URL,
		url,
	};
}

export const POST_CONFIRM_RECURRING_TRANSFER_REQUEST =
	'POST_CONFIRM_RECURRING_TRANSFER_REQUEST';
export const POST_CONFIRM_RECURRING_TRANSFER_SUCCESS =
	'POST_CONFIRM_RECURRING_TRANSFER_SUCCESS';
export const POST_CONFIRM_RECURRING_TRANSFER_ERRORED =
	'POST_CONFIRM_RECURRING_TRANSFER_ERRORED';

export const confirmRecurringTransfer = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			POST_CONFIRM_RECURRING_TRANSFER_REQUEST,
			POST_CONFIRM_RECURRING_TRANSFER_SUCCESS,
			POST_CONFIRM_RECURRING_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/recurringTransfer/confirm`,
			body,
		},
	});

export const GET_RECURRING_TRANSFER_REQUEST = 'GET_RECURRING_TRANSFER_REQUEST';
export const GET_RECURRING_TRANSFER_SUCCESS = 'GET_RECURRING_TRANSFER_SUCCESS';
export const GET_RECURRING_TRANSFER_ERRORED = 'GET_RECURRING_TRANSFER_ERRORED';

export const getRecurringTransfer = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_RECURRING_TRANSFER_REQUEST,
			GET_RECURRING_TRANSFER_SUCCESS,
			GET_RECURRING_TRANSFER_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/recurringTransfer`,
		},
	});

export const POST_RECURRING_TRANSFER_REQUEST =
	'POST_RECURRING_TRANSFER_REQUEST';
export const POST_RECURRING_TRANSFER_SUCCESS =
	'POST_RECURRING_TRANSFER_SUCCESS';
export const POST_RECURRING_TRANSFER_ERRORED =
	'POST_RECURRING_TRANSFER_ERRORED';

export const postRecurringTransfer = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			POST_RECURRING_TRANSFER_REQUEST,
			POST_RECURRING_TRANSFER_SUCCESS,
			POST_RECURRING_TRANSFER_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/recurringTransfer`,
			body,
		},
	});

export const PUT_RECURRING_TRANSFER_REQUEST = 'PUT_RECURRING_TRANSFER_REQUEST';
export const PUT_RECURRING_TRANSFER_SUCCESS = 'PUT_RECURRING_TRANSFER_SUCCESS';
export const PUT_RECURRING_TRANSFER_ERRORED = 'PUT_RECURRING_TRANSFER_ERRORED';

export const putRecurringTransfer = (accountId, body) =>
	fetchRequestWithTypes({
		types: [
			PUT_RECURRING_TRANSFER_REQUEST,
			PUT_RECURRING_TRANSFER_SUCCESS,
			PUT_RECURRING_TRANSFER_ERRORED,
		],
		request: {
			method: 'PUT',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/recurringTransfer`,
			body,
		},
	});
