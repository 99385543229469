export const UPLOAD_DOCUMENT_SUCCESS = 'UPLOAD_DOCUMENT_SUCCESS';
export const START_VERIFICATION_DETAILS = 'START_VERIFICATION_DETAILS';
export const CONFIRM_VERIFICATION_DETAILS = 'CONFIRM_VERIFICATION_DETAILS';

export const uploadDocumentSuccess = (uploadedDocument) => ({
	type: UPLOAD_DOCUMENT_SUCCESS,
	uploadedDocument,
});

export const startVerificationDetails = () => ({
	type: START_VERIFICATION_DETAILS,
});
export const confirmVerificationDetails = (screenData) => ({
	type: CONFIRM_VERIFICATION_DETAILS,
	screenData,
});
