import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { getSettingsPages } from 'stash/components/settings/SettingRoutes';

import {
	UserAvatar,
	Text,
	Link,
	FormattedDate,
	useTheme,
	SmartLink,
	ExternalLinkIcon,
} from '@stashinvest/ui';
import {
	getDisabledFeatures,
	selectIsContentMoat,
	selectAdvisoryAgreement,
} from 'stash/selectors';
import { getAdvisorySignature } from 'stash/actions/api/advisoryAgreement';
import { useIsPaywalled } from '../north-star-home/utils/utils';
import StashWorksBanner from '../banners/stash-works/StashWorksBanner';

const DrawerWrap = styled.div``;

const Mask = styled.div`
	position: fixed;
	background: ${(p) => p.theme.colors.overlay};
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	z-index: 14;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s 0.3s;

	${({ active }) =>
		active &&
		`
		opacity: 1;
		visibility: visible;
		transition: opacity 0.15s, visibility 0s 0s;
	`};
`;

const Navigation = styled.nav`
	position: fixed;
	width: 80%;
	height: 100%;
	max-width: 25rem;
	right: 0;
	top: 0;
	z-index: 14;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	background: ${(p) => p.theme.colors.bgPrimary};
	transition: transform 0.4s;
	transform: ${({ active }) => !active && 'translate(100%, 0)'};
	display: block;
`;

const DrawerItemLink = styled(Link)`
	text-align: right;
	display: block;
	padding: ${({ theme }) => `${theme.space.xs} ${theme.space.m}`};
	text-decoration: none;
`;

const DrawerHeaderWrap = styled.div`
	padding: 1.25rem;
	text-align: right;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const UserInfo = styled.div`
	display: inline-block;
	font-weight: 700;
	margin-right: 0.625rem;
	vertical-align: middle;
`;

const StyledAvatar = styled(UserAvatar).attrs({ mt: 'xxs' })`
	border: 2px solid ${({ theme }) => theme.colors.borderPrimary};
	width: 2.8rem;
	height: 2.8rem;
`;

export const ExternalIcon = () => (
	<ExternalLinkIcon verticalAlign="middle" size="1.1em" />
);

const mapStateToProps = (state) => ({
	isContentMoat: selectIsContentMoat(state),
	hasAdvisorySignature: selectAdvisoryAgreement(state),
});

const DrawerHeader = (props) => (
	<DrawerHeaderWrap>
		<UserInfo>
			<Text.H3>{`${props.user.first_name} ${props.user.last_name}`}</Text.H3>
			<Text.Title>
				Stasher since{' '}
				<FormattedDate format="MM/yyyy">{props.user.created_at}</FormattedDate>
			</Text.Title>
		</UserInfo>
		<StyledAvatar backgroundImage={props.avatar} firstName={props.user.first_name} />
		{!props?.isContentMoat && (
			<div className="mt-content2XS block gridMedium:hidden">
				<StashWorksBanner />
			</div>
		)}
	</DrawerHeaderWrap>
);

DrawerHeader.propTypes = {
	avatar: PropTypes.string,
	user: PropTypes.shape({
		first_name: PropTypes.string,
		last_name: PropTypes.string,
		created_at: PropTypes.instanceOf(Date),
	}),
	isContentMoat: PropTypes.bool,
};

const LogOutItem = (props) => {
	const { colors } = useTheme();
	return (
		<div>
			<DrawerItemLink to="/" onClick={props.logOut}>
				<Text color={colors.textNegativePrimary}>
					Log out <ExternalLinkIcon />
				</Text>
			</DrawerItemLink>
		</div>
	);
};

LogOutItem.propTypes = {
	logOut: PropTypes.func,
};

const SupportItem = () => (
	<div>
		<DrawerItemLink
			href="https://ask.stash.com"
			target="_blank"
			rel="noopener noreferrer"
		>
			<Text>
				Support <ExternalLinkIcon />
			</Text>
		</DrawerItemLink>
	</div>
);

const DrawerItem = (props) => {
	const isReferral = props.pathname.indexOf('referrals') > -1;

	return (
		<div>
			<DrawerItemLink as={SmartLink} href={props.pathname} onClick={props.close}>
				<Text fontWeight={isReferral ? 'bold' : 'normal'}>{props.title}</Text>
			</DrawerItemLink>
		</div>
	);
};

DrawerItem.propTypes = {
	close: PropTypes.func,
	pathname: PropTypes.string,
	title: PropTypes.string,
};

function Drawer(props) {
	const { active, close, logOut, isContentMoat, hasAdvisorySignature } = props;
	const dispatch = useDispatch();
	const isPaywalled = useIsPaywalled();
	const disabledFeatures = useSelector(getDisabledFeatures);

	useEffect(() => {
		dispatch(getAdvisorySignature());
	}, [dispatch]);

	const pages = getSettingsPages({
		disabledFeatures,
		archive: /archive/.test(window.location.href) ? '/archive' : '',
		isContentMoat,
		hasAdvisorySignature,
		isPaywalled,
	});

	return (
		<DrawerWrap>
			<Mask active={active} onClick={close} />
			<Navigation active={active}>
				<DrawerHeader {...props} />
				{pages.map((page) => (
					<DrawerItem
						key={page.pathname}
						title={page.title}
						pathname={page.pathname}
						close={close}
					/>
				))}
				<SupportItem />
				<LogOutItem logOut={logOut} />
			</Navigation>
		</DrawerWrap>
	);
}

Drawer.propTypes = {
	active: PropTypes.bool,
	close: PropTypes.func,
	logOut: PropTypes.func,
	isContentMoat: PropTypes.bool,
	hasAdvisorySignature: PropTypes.bool,
};

export default connect(mapStateToProps, null)(Drawer);
