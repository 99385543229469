import {
	GET_INVESTOR_APPLICATION_QUESTIONS_SUCCESS,
	GET_RETIRE_APPLICATION_SUCCESS,
} from 'stash/actions/api/investorApplication';

const initialState = {
	section1: [],
	section2: [],
	retire: [],
};
export default (state = initialState, action) => {
	switch (action.type) {
		case GET_INVESTOR_APPLICATION_QUESTIONS_SUCCESS:
			return {
				...state,
				section1: action.response.questions.section1,
				section2: action.response.questions.section2,
			};
		case GET_RETIRE_APPLICATION_SUCCESS:
			return {
				...state,
				retire: action.response.retire_questions,
			};
		default:
			return state;
	}
};
