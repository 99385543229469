import { sendMixpanel } from './utils';

export function lockUnlockAccountManagement() {
	sendMixpanel('AccountManagement', {
		ScreenName: '',
		Action: 'AccountManagementCardManagement',
	});
}

export function cardManagementView() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
	});
}

export function cardManagementVirtualCard() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementVirtualCard',
	});
}

export function lockUnlockToggleSwitch() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementToggleLock',
	});
}

export function lockUnlockToggleSwitchConfirm() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementConfirm',
	});
}

export function cardManagementTravel() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementTravel',
	});
}

export function cardManagementResetPin() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementResetPin',
	});
}

export function cardManagementReplaceCard() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementReplaceCard',
	});
}

export function cardManagementFileDispute() {
	sendMixpanel('CardManagement', {
		ScreenName: 'CardManagement',
		Action: 'CardManagementFileDispute',
	});
}

export function trackAtmLocatorCTA() {
	sendMixpanel('ATMLocator', {
		ScreenName: '',
		Action: 'ATMLocatorCTA',
	});
}
