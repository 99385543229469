import { AnalyticsBrowser } from '@segment/analytics-next';
import { SEGMENT_CDN_PROXY_URL, SEGMENT_WRITE_KEYS } from './constants';

let analyticsInstance: AnalyticsBrowser;

export function getAnalyticsInstance() {
	if (!analyticsInstance) {
		analyticsInstance = AnalyticsBrowser.load({
			writeKey: SEGMENT_WRITE_KEYS[window.Stash.env],
			cdnURL: SEGMENT_CDN_PROXY_URL,
		});
	}

	return analyticsInstance;
}
