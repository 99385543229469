import React from 'react';
import propTypes from 'prop-types';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import {
	closeDrawer,
	logOut,
	logOutReasons,
	openDialog,
	openDrawer,
	trackEvent,
} from 'stash/actions';
import DrawerPortal from 'stash/components/drawer/DrawerPortal';
import { Logo, SmartLink } from '@stashinvest/ui';
import { BanjoString } from '@stashinvest/react-banjo';

import { getDisabledFeatures } from 'stash/selectors';
import defaultAvatar from 'stash/assets/top-nav/default-avatar.svg';
import { ReactComponent as Refer } from 'stash/assets/top-nav/refer-tab.svg';
import { Rail } from '@stashinvest/react-components';
import { track } from 'stash/events/utils';
import {
	v1_ReferralHome_Clicked,
	v1_UserProfile_Clicked,
} from '@stashinvest/product-events-javascript/lib/v1/event_static_methods';
import {
	v1_ReferralHome_ClickedProperties,
	v1_UserProfile_ClickedProperties,
} from '@stashinvest/product-events-javascript/lib/v1/properties';
import { Origin } from '@stashinvest/product-events-javascript/lib/enum_properties';

const mapStateToProps = (state) => ({
	drawerOpen: state.application.drawer,
	user: state.entities.user,
	avatar: state.entities.profile.avatar_url || defaultAvatar,
	disabledFeatures: getDisabledFeatures(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	open: (e, isHome) => {
		const openSideDrawer = () => {
			e.preventDefault();
			dispatch(openDrawer());
		};

		track(
			v1_UserProfile_Clicked({
				origin: Origin?.toolbar,
				screen: isHome ? v1_UserProfile_ClickedProperties?.Screen?.home : null,
			})
		);

		return window.innerWidth > 767 || openSideDrawer();
	},

	close: () => {
		dispatch(closeDrawer());
	},

	logOut: (e) => {
		e.preventDefault();
		return dispatch(
			openDialog({
				prompt: 'Are you sure you want to log out?',
				confirmText: 'Log me out',
				cancelText: 'Cancel',
			})
		)
			.then(() => dispatch(logOut(logOutReasons.LOGGED_OUT)))
			.catch(() => {});
	},

	goToReferral: (isHome) => {
		track(
			v1_ReferralHome_Clicked({
				origin: Origin?.toolbar,
				screen: isHome ? v1_ReferralHome_ClickedProperties?.Screen?.home : null,
			})
		);

		dispatch(
			trackEvent('SendReferral', {
				Action: 'ReferralNavHeader',
				Origin: 'WebNavMenu',
			})
		);
	},
});

const NavigationBar = styled.header`
	background: rgba(0, 0, 0, 0.3);
	color: ${(p) => p.theme.colors.textPrimary};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	margin-bottom: 1.5em;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 11;

	&:before {
		content: '';
		position: absolute;
		background: ${(p) => p.theme.colors.bgPrimary};
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity 0.35s, background 0.2s ease-out;
	}

	&.transparent {
		box-shadow: none;
	}

	&.transparent:before {
		opacity: 0;
	}
`;

const navItemMixin = css`
	text-decoration: none;
	color: ${(p) => p.theme.colors.textPrimary};
	text-rendering: optimizeLegibility;
`;

const TopNavItemLink = styled(NavLink)`
	${navItemMixin}
	display: flex;
	box-sizing: border-box;
	align-items: center;
	min-height: 4rem;
	border-bottom: 2px solid transparent;
	transition: color 0.2s, border-bottom-color 0.2s;
	white-space: nowrap;

	&.active {
		border-bottom-color: ${(p) => p.theme.colors.iconSelected};

		svg {
			color: ${(p) => p.theme.colors.iconSelected};
		}
	}
`;

const StyledUserName = styled.span`
	${navItemMixin}
	margin-left: 0.75rem;
`;

const NavUser = styled.div`
	position: relative;
	cursor: pointer;
`;

const NavUserInfo = styled.div`
	display: flex;
	flex: 1 1 auto;
	align-items: center;
	justify-content: space-between;
`;

const NavUserAvatar = styled.div`
	background-image: url(${defaultAvatar});
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	text-indent: -9999px;
`;

export const URL_MATCHES = {
	home: ['/'],
	invest: [
		'/browse-collection/stocks',
		'/browse-collection/etfs',
		'/browse-collection/bookmarks',
		'/bookmarks',
		'/invest',
		'/portfolio',
		'/investments',
		'/investments/search',
		'/cards/search',
	],
	bankNav: [
		'/debit',
		'/debit/cardManagement',
		'/debit/recurringTransfers',
		'/debit/settings/virtualCard',
		'/debit/settings/travel',
		'/debit/resetPin',
	],
	plan: [''],
	transfers: ['transfer*'],
};

const TopBar = ({
	open,
	close,
	drawerOpen,
	logOut,
	user,
	avatar,
	hideNav,
	goToReferral,
	navOptions = [],
	selectedIndex,
	onNavLinkClick = () => {},
	settingsUrl = '/account-settings',
	stashLogoUrl = '/',
}) => {
	const { pathname } = useLocation();
	const history = useHistory();
	const isHome = pathname === '/';

	return (
		<>
			<NavigationBar className="flex justify-between shrink">
				<Logo
					className="flex gridLarge:hidden px-layout2XS shrink text-brandFill my-contentMedium"
					as={SmartLink}
					to={stashLogoUrl}
					color="text-brandFill"
					zIndex="1"
					height="1.3rem"
					onClick={() => {
						onNavLinkClick(0);
					}}
				/>
				<Rail
					className="hidden gridLarge:flex shrink z-20 pb-0"
					selectedIndex={selectedIndex}
					onIndexSelect={(index) => {
						onNavLinkClick(index);
					}}
					navItems={navOptions}
					onLogoClick={() => {
						history.push(stashLogoUrl);
					}}
				/>
				<div className="flex shrink gap-layoutXS items-center pr-contentSmall captionSmallEmphasize">
					<NavUser>
						<NavUserInfo className="labelMediumStandard gridLarge:labelSmallStandard gridXL:labelMediumStandard">
							{!hideNav && (
								<TopNavItemLink
									data-testid="refer-a-friend"
									to="/referrals"
									//Adding !important here as there is a conflicting css on TopNavItemLink which is used in multiple places
									className="!whitespace-normal text-sm mr-layout3XS gridMedium:text-base gridMedium:mr-layout2XS"
									onClick={(e) => {
										goToReferral(isHome);
										onNavLinkClick(-1);
									}}
								>
									<Refer
										height="1rem"
										width="1rem"
										className="hidden gridMedium:block mr-content2XS"
									/>
									<BanjoString
										data-testid="action/startTask/referAFriend"
										stringId="action/startTask/referAFriend"
									/>
								</TopNavItemLink>
							)}
							<TopNavItemLink
								to={settingsUrl}
								className="justify-center"
								onClick={(e) => {
									onNavLinkClick(-1);
									open(e, isHome);
								}}
								aria-label="Account settings"
							>
								<NavUserAvatar style={{ backgroundImage: `url(${avatar})` }}>
									User Settings
								</NavUserAvatar>
								<StyledUserName className="hidden gridXL:block">
									{user.first_name || ''}
								</StyledUserName>
							</TopNavItemLink>
						</NavUserInfo>
					</NavUser>
				</div>
			</NavigationBar>

			<DrawerPortal
				active={drawerOpen}
				close={close}
				logOut={logOut}
				user={user}
				avatar={avatar}
			/>
		</>
	);
};

TopBar.propTypes = {
	open: propTypes.func,
	close: propTypes.func,
	drawerOpen: propTypes.bool,
	logOut: propTypes.func,
	user: propTypes.object,
	avatar: propTypes.string,
	goToReferral: propTypes.func,
	hideNav: propTypes.bool,
	navOptions: propTypes.array,
	selectedIndex: propTypes.number,
	onNavLinkClick: propTypes.func,
	settingsUrl: propTypes.string,
	stashLogoUrl: propTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
