import { GET_STATEMENTS_SUCCESS } from '../../actions/api/clearing';

const getAccountIdFromPath = (path) => {
	return path.match(/accounts\/([a-f0-9-]+)\//)[1];
};

export default (state = {}, action) => {
	switch (action.type) {
		case GET_STATEMENTS_SUCCESS:
			return {
				...state,
				[getAccountIdFromPath(action.request.path)]: action.response.statements,
			};
		default:
			return state;
	}
};
