import { fetchRequestWithTypes } from './index.js';

export const GET_DRIP_REQUEST = 'GET_DRIP_REQUEST';
export const GET_DRIP_SUCCESS = 'GET_DRIP_SUCCESS';
export const GET_DRIP_ERRORED = 'GET_DRIP_ERRORED';

export const getDrip = (accountId) => {
	return fetchRequestWithTypes({
		types: [GET_DRIP_REQUEST, GET_DRIP_SUCCESS, GET_DRIP_ERRORED],
		accountId,
		request: {
			method: 'GET',
			path: `/apis/brokerage/users/:uuid/accounts/${accountId}/dividendReinvestment`,
		},
	});
};

export const POST_DRIP_REQUEST = 'POST_DRIP_REQUEST';
export const POST_DRIP_SUCCESS = 'POST_DRIP_SUCCESS';
export const POST_DRIP_ERRORED = 'POST_DRIP_ERRORED';

export const postDrip = (accountId, setting) => {
	return fetchRequestWithTypes({
		types: [POST_DRIP_REQUEST, POST_DRIP_SUCCESS, POST_DRIP_ERRORED],
		request: {
			method: 'POST',
			path: `/apis/brokerage/users/:uuid/accounts/${accountId}/dividendReinvestment`,
			body: { setting },
		},
	});
};
