import { fetchRequestWithTypes } from './index.js';

export const GET_CARD_PRICES_REQUEST = 'GET_CARD_PRICES_REQUEST';
export const GET_CARD_PRICES_SUCCESS = 'GET_CARD_PRICES_SUCCESS';
export const GET_CARD_PRICES_ERRORED = 'GET_CARD_PRICES_ERRORED';

export const getCardPrices = (cardUuid, range) => {
	return fetchRequestWithTypes({
		types: [
			GET_CARD_PRICES_REQUEST,
			GET_CARD_PRICES_SUCCESS,
			GET_CARD_PRICES_ERRORED,
		],
		range,
		request: {
			method: 'GET',
			path: `/apis/brokerage/cards/${cardUuid}/prices?range=${range}`,
		},
	});
};
