import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const RouterHelmet = ({ title, content }) => {
	return (
		<Helmet>
			<title>{title} | Stash</title>
			<meta name="description" content={content} />
		</Helmet>
	);
};

RouterHelmet.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
};

RouterHelmet.defaultProps = {
	title: 'Your Stash Account',
	content: 'Your Stash account',
};

export default RouterHelmet;
