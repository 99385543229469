import {
	GET_TRUSTED_CONTACT_SUCCESS,
	POST_TRUSTED_CONTACT_SUCCESS,
} from 'stash/actions/api/trustedContact';
import {
	PATCH_REACTIVATE_ACCOUNT_SUCCESS,
	POST_USER_PLATFORM_TIER_SUCCESS,
	GET_ACTIVE_PLATFORM_TIER_SUCCESS,
	GET_USER_SUBSCRIPTION_BILLING_PERIODS_SUCCESS,
	SET_USER_IRA_ELIGIBILITY,
	GET_BILLING_FREQUENCY_OPTIONS_SUCCESS,
	GET_REFUND_POLICY_SUCCESS,
	GET_USER_SUCCESS,
	POST_USER_SUCCESS,
	PATCH_USER_SUCCESS,
	GET_LAUNCH_INFO_SUCCESS,
	GET_USER_BILLING_SUMMARY_SUCCESS,
	GET_USER_BILLING_SUMMARY_ERRORED,
	GET_USER_PAYMENT_METHODS_SUCCESS,
} from 'stash/actions/api';

import { SET_BILLING_PERIOD } from 'stash/actions/signUp';

const capitalizeName = (name) => {
	if (typeof name !== 'string') return '';
	if (name.toLowerCase() !== name && name.toUpperCase() !== name) {
		return name;
	}
	return name.replace(/\w\S*/g, (txt) => {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

const formatNames = (user) => {
	return {
		first_name: capitalizeName(user.first_name),
		last_name: capitalizeName(user.last_name),
	};
};

export default (state = {}, action) => {
	switch (action.type) {
		case PATCH_USER_SUCCESS:
		case GET_USER_SUCCESS:
		case GET_LAUNCH_INFO_SUCCESS:
		case POST_USER_SUCCESS:
			return {
				...state,
				...action.response.user,
				...formatNames(action.response.user),
			};

		case POST_TRUSTED_CONTACT_SUCCESS:
			return {
				...state,
				trusted_contact: action.response.trusted_contact,
			};

		case POST_USER_PLATFORM_TIER_SUCCESS:
			const platform_tier = action.response.user_platform_tier.platform_tier;

			return {
				...state,
				platform_tier,
			};

		case GET_ACTIVE_PLATFORM_TIER_SUCCESS:
			const active_platform_tier = action.response.active_platform_tier;

			return {
				...state,
				platform_tier: active_platform_tier && active_platform_tier.platform_tier,
			};

		case GET_USER_SUBSCRIPTION_BILLING_PERIODS_SUCCESS:
			const billingPeriods = action.response.billingPeriods.reduce(
				(billingPeriodByType, billingPeriod) => {
					billingPeriodByType[billingPeriod.unit.key] = billingPeriod;
					return billingPeriodByType;
				},
				{}
			);

			return {
				...state,
				platform_tier: {
					...state.platform_tier,
					billing_periods: billingPeriods,
				},
			};

		case GET_BILLING_FREQUENCY_OPTIONS_SUCCESS:
			return {
				...state,
				billing_frequency_options: action.response.billingFrequencies,
			};

		case GET_USER_BILLING_SUMMARY_SUCCESS:
			return {
				...state,
				billing_summary: action.response.billingSummary,
			};

		case GET_USER_BILLING_SUMMARY_ERRORED:
			return {
				...state,
				billing_summary_error: action.response.error,
			};

		case GET_REFUND_POLICY_SUCCESS:
			return {
				...state,
				refund_policy: action.response.refundPolicy,
			};

		case SET_BILLING_PERIOD:
			return {
				...state,
				billing_period: action.billing_period,
			};
		case SET_USER_IRA_ELIGIBILITY:
			return {
				...state,
				ira_eligible: action.ira_eligible,
			};
		case GET_TRUSTED_CONTACT_SUCCESS:
			return action.response.status !== 200
				? state
				: {
						...state,
						trusted_contact: action.response.trusted_contact,
				  };

		case PATCH_REACTIVATE_ACCOUNT_SUCCESS:
			return {
				...state,
				aasm_state: 'in_activation_process',
			};

		case GET_USER_PAYMENT_METHODS_SUCCESS:
			return {
				...state,
				payment_methods: action.response.paymentMethods,
			};

		default:
			return state;
	}
};
