import { fetchRequestWithTypes } from './index.js';

export const POST_ADDRESS_VERIFICATION_REQUEST =
	'POST_ADDRESS_VERIFICATION_REQUEST';
export const POST_ADDRESS_VERIFICATION_SUCCESS =
	'POST_ADDRESS_VERIFICATION_SUCCESS';
export const POST_ADDRESS_VERIFICATION_ERRORED =
	'POST_ADDRESS_VERIFICATION_ERRORED';

export const postAddressVerification = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_ADDRESS_VERIFICATION_REQUEST,
			POST_ADDRESS_VERIFICATION_SUCCESS,
			POST_ADDRESS_VERIFICATION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/api/v1/address/verify_entire`,
			body,
		},
	});
