import { stringifyParams } from 'stash/components/investments/FilterUtils';

// Filter types
export const FILTER_SORT = 'sort';
export const FILTER_INVESTMENT_TYPE = 'investment_type';
export const FILTER_RISK_LEVEL = 'risk_level';
export const FILTER_GROUP_SLUG = 'investment_group_slug';

// order to show filters
export const FILTER_LAYOUT_ETF = [
	FILTER_INVESTMENT_TYPE,
	FILTER_SORT,
	FILTER_GROUP_SLUG,
];

export const FILTER_LAYOUT_STOCK = [
	FILTER_INVESTMENT_TYPE,
	FILTER_SORT,
	FILTER_GROUP_SLUG,
];

// browse api defaults
export const DEFAULT_PAGE_SIZE = 24;
export const DEFAULT_SORT = 'name_asc';
export const DEFAULT_INVESTMENT_TYPE = 'STOCK';

// default search (fallback if no query params exist)
export const DEFAULT_SEARCH = {
	[FILTER_SORT]: DEFAULT_SORT,
	[FILTER_INVESTMENT_TYPE]: DEFAULT_INVESTMENT_TYPE,
};
export const DEFAULT_SEARCH_STRING = stringifyParams(DEFAULT_SEARCH);
