import { fetchRequestWithTypes } from './index.js';

// Returns eligibility state of whether an account can opt in to securities lending
// https://edge.stsh.io/documentation/1.0/security_lending_agreements/show.html

export const GET_SECURITIES_LENDING_ELIGIBILITY_REQUEST =
	'GET_SECURITIES_LENDING_ELIGIBILITY_REQUEST';
export const GET_SECURITIES_LENDING_ELIGIBILITY_SUCCESS =
	'GET_SECURITIES_LENDING_ELIGIBILITY_SUCCESS';
export const GET_SECURITIES_LENDING_ELIGIBILITY_ERRORED =
	'GET_SECURITIES_LENDING_ELIGIBILITY_ERRORED';

export const getSecuritiesEligibility = (accountId) => {
	return fetchRequestWithTypes({
		types: [
			GET_SECURITIES_LENDING_ELIGIBILITY_REQUEST,
			GET_SECURITIES_LENDING_ELIGIBILITY_SUCCESS,
			GET_SECURITIES_LENDING_ELIGIBILITY_ERRORED,
		],
		meta: { accountId },
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/security_lending_agreements`,
		},
	});
};

// Opts an account in or out of the security lending program
// https://edge.stsh.io/documentation/1.0/security_lending_agreements/create.html

export const POST_SECURITIES_LENDING_REQUEST =
	'POST_SECURITIES_LENDING_REQUEST';
export const POST_SECURITIES_LENDING_SUCCESS =
	'POST_SECURITIES_LENDING_SUCCESS';
export const POST_SECURITIES_LENDING_ERRORED =
	'POST_SECURITIES_LENDING_ERRORED';

export const postSecuritiesEligibility = (accountId, body) => {
	return fetchRequestWithTypes({
		types: [
			POST_SECURITIES_LENDING_REQUEST,
			POST_SECURITIES_LENDING_SUCCESS,
			POST_SECURITIES_LENDING_ERRORED,
		],
		meta: { accountId },
		request: {
			method: 'POST',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/security_lending_agreements`,
			body,
		},
	});
};

// Securities lending event action

export const TRACK_SECURITIES_LENDING = 'TRACK_SECURITIES_LENDING';

export const trackSecuritiesEligibility = (properties) => ({
	type: TRACK_SECURITIES_LENDING,
	properties,
});
