import { fetchRequestWithTypes } from './index.js';

// Returns the gifting URL for the account provided
// https://staging.stsh.io/documentation/1.0/gifting_urls/show.html

export const GET_GIFTING_URL_REQUEST = 'GET_GIFTING_URL_REQUEST';
export const GET_GIFTING_URL_SUCCESS = 'GET_GIFTING_URL_SUCCESS';
export const GET_GIFTING_URL_ERRORED = 'GET_GIFTING_URL_ERRORED';

export const getGiftingUrl = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_GIFTING_URL_REQUEST,
			GET_GIFTING_URL_SUCCESS,
			GET_GIFTING_URL_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/api/v1/users/:uuid/accounts/${accountId}/gifting_urls `,
		},
	});
