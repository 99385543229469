import {
	GET_USER_SUCCESS,
	POST_USER_SUCCESS,
	GET_LAUNCH_INFO_SUCCESS,
} from 'stash/actions/api';
import { PATCH_PROFILE_SUCCESS } from 'stash/actions/api/profile';

export default (state = {}, action) => {
	switch (action.type) {
		case PATCH_PROFILE_SUCCESS:
			return action.response.user_profile;
		case POST_USER_SUCCESS:
		case GET_USER_SUCCESS:
		case GET_LAUNCH_INFO_SUCCESS:
			return action.response.profile;
		default:
			return state;
	}
};
