import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Logo } from '@stashinvest/ui';

const NavHolder = styled.header`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.813em 3em;
	background-color: ${({ theme }) => theme.colors.bgPrimary};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

	@media only screen and (max-width: 767px) {
		padding: 0.6em 1em;
	}
`;

export const NoAuthNavBar = (props) => (
	<NavHolder>
		<Logo zIndex="1" height="1.3rem" mr="2rem" />
		{props.children}
	</NavHolder>
);

NoAuthNavBar.propTypes = {
	children: PropTypes.any,
};
