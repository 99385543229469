import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Rail } from '@stashinvest/react-components';

const RailBottomNav = styled.nav`
	position: fixed;
	z-index: 11;
	bottom: 0;
	background: ${(p) => p.theme.colors.bgPrimary};
	width: 100%;
	height: 62px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
`;

export const MobileNav = (props) => {
	return (
		<RailBottomNav className="block gridLarge:hidden">
			<Rail
				selectedIndex={props?.selectedIndex}
				onIndexSelect={(index) => {
					props?.onNavLinkClick(index);
				}}
				navItems={props?.navOptions}
			/>
		</RailBottomNav>
	);
};

MobileNav.propTypes = {
	navOptions: PropTypes.array,
	selectedIndex: PropTypes.number,
	onNavLinkClick: PropTypes.func,
};

export default MobileNav;
