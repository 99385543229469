import { fetchRequestWithTypes } from './index.js';

// Returns copy to be displayed on the app's RESTRICTED screen
// https://staging.stsh.io/documentation/1.0/status_messages/restricted.html

export const GET_RESTRICTED_MESSAGE_REQUEST = 'GET_RESTRICTED_MESSAGE_REQUEST';
export const GET_RESTRICTED_MESSAGE_SUCCESS = 'GET_RESTRICTED_MESSAGE_SUCCESS';
export const GET_RESTRICTED_MESSAGE_ERRORED = 'GET_RESTRICTED_MESSAGE_ERRORED';

export const getRestrictedMessage = () =>
	fetchRequestWithTypes({
		types: [
			GET_RESTRICTED_MESSAGE_REQUEST,
			GET_RESTRICTED_MESSAGE_SUCCESS,
			GET_RESTRICTED_MESSAGE_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/status_messages/restricted',
		},
	});
