import { GET_GIFTING_URL_SUCCESS } from '../../actions/api';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_GIFTING_URL_SUCCESS:
			return {
				...state,
				[action.response.account_id]: action.response.url,
			};
		default:
			return state;
	}
};
