/**
 * This file composes middleware before creating the store.
 * Middleware provides a third-party extension point between
 * dispatching an action, and the moment it reaches the reducer.
 *
 * Read more about middleware here:
 * http://redux.js.org/docs/advanced/Middleware.html
 *
 */

import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import promo from './promo';
import braze from './braze';
import nudata from './nudata';
import userleap from './userleap';
import { routerMiddleware } from 'connected-react-router';
import session from './session';
import events from './events';
import thunk from './thunk';
import fetchMiddleware from './fetch';
import dialog from './dialog';
import recaptcha from './recaptcha';
import segment from './segment';
import datadogRumMiddleware from './datadog';
import type { History } from 'history';
import type { AnalyticsBrowser } from '@segment/analytics-next';

type MiddlewareDepencies = {
	history: History
	analytics: AnalyticsBrowser
};


export function createMiddleware({
	history,
	analytics,
}: MiddlewareDepencies) {
	const middleware = [
		routerMiddleware(history),
		promo,
		recaptcha,
		session,
		events,
		dialog,
		braze,
		nudata,
		userleap,
		fetchMiddleware,
		thunk,
		segment(analytics),
		datadogRumMiddleware,
	];

	return composeWithDevTools(applyMiddleware(...middleware));
}
