import { GET_IS_CORE_USER_SUCCESS } from 'stash/actions/api/onboarding';

const initialState = {};

const core = (state = initialState, action) => {
	switch (action.type) {
		case GET_IS_CORE_USER_SUCCESS:
			return {
				isCoreUser: action.response.isCoreUser || false,
			};
		default:
			return state;
	}
};

export default core;
