import {
	GET_BOOKMARKS_SUCCESS,
	POST_BOOKMARK_SUCCESS,
	DELETE_BOOKMARK_SUCCESS,
} from '../../actions/api/bookmarks';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_BOOKMARKS_SUCCESS:
			return action.response.cards.reduce((acc, card) => {
				acc[card.id] = card;
				return acc;
			}, {});

		// this one's a little weird since the data returned is not standard card
		// json so just save a card object with card_id
		case POST_BOOKMARK_SUCCESS: {
			const bookmark = action.response.bookmark;
			return {
				...state,
				[bookmark.card_id]: { id: bookmark.card_id },
			};
		}

		case DELETE_BOOKMARK_SUCCESS: {
			return Object.values(state).reduce((acc, card) => {
				if (action.meta.cardId === card.id) {
					return acc;
				}

				return { ...acc, [card.id]: card };
			}, {});
		}

		default:
			return state;
	}
};
