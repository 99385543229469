import { useSelector } from 'react-redux';
import BANK_ACCOUNT_PARTNER_TYPE from 'stash/constants/partnerTypes';

export function getBankAccount(state) {
	return state?.entities?.bank?.debit_accounts?.[0];
}

export function getBankAccountStatus(state) {
	return state?.entities?.bank?.debit_accounts?.[0]?.status;
}

export function getBankAccountBalance(state) {
	const statusTile = state.entities.bank?.tiles?.find(
		(tile) => tile.id === 'accountStatus'
	);

	return statusTile?.content?.value?.balance;
}

export function getBankAccountId(state) {
	return getBankAccount(state)?.id;
}

export function getDisabledFeatures(state) {
	return getBankAccount(state)?.disabledFeatures;
}

export function useBankAccount() {
	return useSelector((state) => state?.entities?.bank?.debit_accounts?.[0]);
}

export function useUUID() {
	return useSelector((state) => state?.entities?.api_key?.uuid);
}

export function useBankAccountType() {
	return useSelector(
		(state) => state?.entities?.bank?.debit_accounts?.[0]?.partnerType
	);
}

export function useIsCoreBank() {
	return useSelector(
		(state) =>
			state?.entities?.bank?.debit_accounts?.[0]?.partnerType !==
			BANK_ACCOUNT_PARTNER_TYPE.GREENDOT
	);
}
