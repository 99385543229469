import { sendMixpanel } from './utils';

export function trackSettingsView(hasStashworksBanner) {
	sendMixpanel('Impression', {
		ScreenName: 'UserProfile',
		Stashworks: hasStashworksBanner ? 'Y' : 'N',
	});
}

export function trackStashWorksBannerClick() {
	sendMixpanel('ButtonPress', {
		ScreenName: 'UserProfile',
		Action: 'Clicked',
	});
}
