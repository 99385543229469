export const LOG_IN = 'LOG_IN';

export const logIn = (authToken, refreshToken, uuid) => ({
	type: LOG_IN,
	authToken,
	refreshToken,
	uuid,
});

export const LOG_IN_THIRD_PARTY = 'LOG_IN_THIRD_PARTY';
export const logInThirdParty = (authToken, uuid) => ({
	type: LOG_IN_THIRD_PARTY,
	authToken,
	uuid,
});

// Logs a user out. We specify a 'reason' (e.g. session timed out)
// so the next screen can display a confirmation message of
// why they were logged out.

export const LOG_OUT = 'LOG_OUT';
export const logOutReasons = {
	TIMED_OUT: 'timedout',
	LOGGED_OUT: 'loggedout',
	DUPLICATE_LOGIN: 'duplicatelogin',
};

export const logOut = (reason) => ({
	type: LOG_OUT,
	reason,
});

// Fired when an authenticated session begins. Note this differs
// from a LOG_IN, for example, this will fire if an already
// logged-in user has refreshed the page.

export const AUTH_SESSION_START = 'AUTH_SESSION_START';

export const authSessionStart = (uuid) => ({
	type: AUTH_SESSION_START,
	uuid,
});
