import { useCachedGet, setDedupingIntervals } from '@stashinvest/use-fetch';

export const stashWorksStatus = {
	ACTIVE: 'ACTIVE',
	INACTIVE: 'INACTIVE',
	OFFBOARDING: 'OFFBOARDING',
	NOT_FOUND: 'NOT_FOUND',
};
const stashWorksStatusPath = '/apis/works/v1/users/:uuid/status';

setDedupingIntervals({
	[stashWorksStatusPath]: 1000 * 60 * 60,
});

export const useStashWorksStatus = () => {
	const { data: response, loading: isLoading } = useCachedGet({
		path: stashWorksStatusPath,
		onMount: true,
	});

	const status = response?.data?.status;

	const hasStashWorks =
		status === stashWorksStatus.ACTIVE || status === stashWorksStatus.OFFBOARDING;

	return {
		status,
		hasStashWorks,
		isLoading,
	};
};
