import { GET_INVESTMENTS_BY_GROUP_SUCCESS } from 'stash/actions/api';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_INVESTMENTS_BY_GROUP_SUCCESS:
			return {
				...state,
				[action.meta.group]: action.response.cards.map((c) => c.id),
			};

		default:
			return state;
	}
};
