import { createSelector } from 'reselect';

export const selectSignUpState = (state) => {
	return {
		application: state.application,
		entities: state.entities,
	};
};

export const selectSignUpBankSearchQuery = (state) => {
	return state?.form?.SignUpLinkBankSearchForm?.values?.query ?? false;
};

export const selectSignUpBankSearch = createSelector(
	selectSignUpBankSearchQuery,
	(searchQuery) => {
		return searchQuery && !!searchQuery.length;
	}
);

export const selectAddPaymentCompleted = (state) => {
	return state.application.signUp.completed['add-payment-completed'] || false;
};
