/*
 * A Redux middleware that interprets FETCH_REQUEST_WITH_TYPES actions.
 * Performs the call and promises to dispatch three actions:
 * A "Request" action, a "Success" action, and an "Errored" action.
 */

import {
	FETCH_REQUEST_WITH_TYPES,
	REFRESH_TOKEN_REQUEST,
	refreshTokenRequest,
} from '../actions/api/index.js';
import { logOut } from '../actions/session';
import { selectIsAuthenticated, selectRefreshTokenPending } from '../selectors/session';
import { isProdOrStaging } from 'stash/utils';
import { getCookie } from 'stash/utils/cookies';

import { getErrorMessage } from 'stash/utils/errors';
import {
	ACCESS_TOKEN_COOKIE_NAME,
	REFRESH_TOKEN_COOKIE_NAME,
	UUID_COOKIE_NAME,
} from '../constants/session';

const FETCH_MW_KEY = '__fetchMiddleware';
export const MOCK_MODE = 'mockMode';
export const CORS_MODE = 'cors';

// Validate that the FETCH_REQUEST_WITH_TYPES action has everything
// it needs to actually make the call and dispatch the three actions.

const validateFetch = ({ request, types }) => {
	if (!types || types.length !== 3) throw new Error('Fetch: Expected three action types');
	if (!request) throw new Error('Fetch: No request key specified.');
	if (!request.method) throw new Error('Fetch: No HTTP method specified.');
	if (!request.path) throw new Error('Fetch: No URL path specified.');
};

// The three actions which make up an async request (request, success, errored)
// are unpacked in the middleware and dispatched to Redux from here.

const dispatchFetchAction = (dispatch, type, { types, ...action }, response) => {
	return dispatch({
		...action,
		type,
		response,
	});
};

// Build the URL for the request. Allow for common params to be injected from
// state (such as :user_id)

export const getRequestURL = (apiKey, path) => {
	let newPath = path;

	if (newPath.includes(':uuid')) {
		const uuid = apiKey && apiKey.uuid ? apiKey.uuid : getCookie(UUID_COOKIE_NAME);
		if (Boolean(uuid)) {
			newPath = newPath.replace(':uuid', uuid);
		} else {
			return null;
		}
	}

	return newPath;
};

export const handleFetchRequest = (url, options, action) => {
	if (options.mode === MOCK_MODE) {
		return Promise.resolve(action.mockResponse);
	}

	return fetch(url, options).then((res) => {
		if (res.status === 204) return { success: true, status: 204 };
		return res
			.json()
			.catch()
			.then((json) => {
				if (json instanceof Array || Array.isArray(json)) {
					return { body: json, status: res.status };
				} else {
					return { ...json, status: res.status };
				}
			});
	});
};

export const sendRequest = ({ url, options, dispatch, action, state, getState }) => {
	// Make the HTTP request. A Promise is returned so that dispatch
	// is 'thennable'
	return handleFetchRequest(url, options, action)
		.then((json) => {
			if (json.status >= 400) throw json;

			// Dispatch the "Success" action
			dispatchFetchAction(
				dispatch,
				action[FETCH_MW_KEY].successType,
				{ ...action, [FETCH_MW_KEY]: undefined },
				json
			);

			return Promise.resolve(json);
		})
		.catch((json) => {
			dispatchFetchAction(
				dispatch,
				action[FETCH_MW_KEY].erroredType,
				{ ...action, [FETCH_MW_KEY]: undefined },
				json
			);

			// If the refresh token is expired, log out
			if (
				(json.status === 400 || json.status === 401) &&
				action.type === REFRESH_TOKEN_REQUEST
			) {
				dispatch(logOut());
			}

			// If we ever get a 401 from the API, refresh the token under auth v2, log the user out under legacy auth.
			if (json.status === 401 && selectIsAuthenticated(state)) {
				const token = getCookie(REFRESH_TOKEN_COOKIE_NAME);
				if (token) {
					// don't try to refresh the token if it's currently being refreshed
					if (selectRefreshTokenPending(getState())) {
						dispatch(action);
					} else {
						dispatch(refreshTokenRequest(token)).then((res) => {
							dispatch(action);
						});
					}
				} else {
					dispatch(logOut());
				}
			} else {
				const rawErrorMsg =
					json.errors ||
					json.message ||
					json.description ||
					'API returned a server error';

				const stringifiedErrorMsg =
					typeof rawErrorMsg === 'string' ? rawErrorMsg : JSON.stringify(rawErrorMsg);

				throw {
					...json,
					displayError: getErrorMessage(json),
					customDeveloperMessage: 'Middleware Request Failed',
					url,
					stringifiedErrorMessage: stringifiedErrorMsg,
				};
			}
		});
};

// The actual middleware. Any action with type FETCH_REQUEST_WITH_TYPES
// will trigger this middleware. The HTTP call is made and the three
// actions are unpacked and promised.

const fetchMiddleware =
	({ dispatch, getState }) =>
	(next) =>
	(action) => {
		if (action.type === FETCH_REQUEST_WITH_TYPES) {
			// Validate the fetch action
			validateFetch(action);

			// Get the specified action types
			const [requestType, successType, erroredType] = action.types;

			// Dispatch the "Request" action immediately.
			return dispatchFetchAction(dispatch, requestType, {
				...action,
				[FETCH_MW_KEY]: { successType, erroredType },
			});
		}

		if (action[FETCH_MW_KEY]) {
			// Prepare headers for the network request
			const state = getState();
			const apiKey = state.entities && state.entities.api_key;
			const basePath = action.request.basePath || window.Stash.CUSTOM_API_PATH || '';
			const path = getRequestURL(apiKey, action.request.path);
			if (!path) {
				dispatch(logOut());
			}
			const url = `${basePath}${path}`;
			let accessToken =
				action.type !== REFRESH_TOKEN_REQUEST &&
				decodeURIComponent(getCookie(ACCESS_TOKEN_COOKIE_NAME));
			const mode = !isProdOrStaging() && action.mockResponse ? MOCK_MODE : CORS_MODE;

			const options = {
				mode,
				body:
					action.request.method !== 'GET'
						? JSON.stringify(action.request.body)
						: undefined,
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					/**
					 * If the action is a refresh token request, do not include the access token
					 * or smaug will throw a 401 error if the token is expired.
					 */
					AUTHORIZATION:
						action.type === REFRESH_TOKEN_REQUEST ? undefined : accessToken || undefined,
					...action.request.headers,
				},
				method: action.request.method,
				...action.request.options,
			};

			// Continue dispatching the request action
			next({ ...action, [FETCH_MW_KEY]: undefined });

			return sendRequest({ url, options, dispatch, action, state, getState });
		}

		return next(action);
	};

export default fetchMiddleware;
