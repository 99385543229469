import {
	GET_USER_INCOME_QUESTION_SUCCESS,
	GET_USER_CURRENT_INCOME_SUCCESS,
} from '../../actions/api/updateIncome';

export const INCOME_KEY = 'household_income_bracket';

const userIncome = (state = {}, action) => {
	switch (action.type) {
		case GET_USER_INCOME_QUESTION_SUCCESS:
			const data = action.response.response.questions.find(
				(item) => item.key === INCOME_KEY
			);
			return {
				...state,
				data: data,
			};
		case GET_USER_CURRENT_INCOME_SUCCESS:
			return {
				...state,
				currentIncome: action.response.questions[INCOME_KEY].answers[0],
			};
		default:
			return state;
	}
};

export default userIncome;
