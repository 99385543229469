import {
	EXPAND_INVEST_OVERVIEW_LIST,
	COLLAPSE_INVEST_OVERVIEW_LIST,
	HIDE_LINK_BANK_SUCCESS,
} from '../../actions/disclosure.js';

export default (state = { isCollapsed: true, isHidden: false }, action) => {
	switch (action.type) {
		case EXPAND_INVEST_OVERVIEW_LIST:
			return {
				...state,
				isCollapsed: false,
			};
		case COLLAPSE_INVEST_OVERVIEW_LIST:
			return {
				...state,
				isCollapsed: true,
			};
		case HIDE_LINK_BANK_SUCCESS:
			return {
				...state,
				isHidden: true,
			};
		default:
			return state;
	}
};
