import type { AnalyticsBrowser } from '@segment/analytics-next';
import { AUTH_SESSION_START, LOG_OUT } from '../actions';
import type { Middleware } from 'redux';

const middleware =
	(analytics: AnalyticsBrowser): Middleware =>
	() =>
	(next) =>
	async (action) => {
		switch (action.type) {
			case AUTH_SESSION_START:
				const user = await analytics.user();
				
				user.traits({});
				analytics.identify(action.uuid);
				break;
			case LOG_OUT:
				analytics.reset();
				break;
			default:
				break;
		}

		return next(action);
	};
export default middleware;
