// https://stackoverflow.com/a/901144
export const getURLParam = (paramName, href = window.location.href) => {
	const name = paramName.replace(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(href);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isUrlInternalApp = (url) => {
	const appStashRegex = new RegExp(/https:\/\/[app]*\.(?:stash|stashinvest)\.com/);
	return appStashRegex.test(url);
};

export const isUrlAbsolute = (url) => {
	try {
		return !!new URL(url);
	} catch {
		return false;
	}
};
