import React from 'react';
import styled from 'styled-components/macro';
import { Text, Layout } from '@stashinvest/ui';
import { BanjoString } from '@stashinvest/react-banjo';

const DisclosureText = styled(Text.Caption).attrs({
	mb: 'xxs',
})``;

export function DisclosureSection(props) {
	return (
		<Layout.ContentWrap pb="xxs">
			<>
				<DisclosureText>
					<BanjoString stringId="information/disclose/bankMentionWithCard" />
				</DisclosureText>
				<DisclosureText>
					<Text.Sup>1</Text.Sup>
					<BanjoString stringId="information/disclose/stockBackRewards" />
				</DisclosureText>
				<DisclosureText>
					<Text.Sup>2</Text.Sup>
					<BanjoString stringId="information/disclose/noBankFees" />
				</DisclosureText>
				<DisclosureText>
					<Text.Sup>3</Text.Sup>
					<BanjoString stringId="information/disclose/earlyDirectDeposit" />
				</DisclosureText>
				<DisclosureText>Debit Insights provided by Stash.</DisclosureText>
				<DisclosureText>
					<BanjoString stringId="information/disclose/bankActivationLegacyStashMention" />
				</DisclosureText>
			</>
		</Layout.ContentWrap>
	);
}
export default DisclosureSection;
