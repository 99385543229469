import { fetchRequestWithTypes } from './index.js';

// GETs a user's Account Verification settings

export const GET_ACCOUNT_VERIFICATION_REQUEST = 'GET_ACCOUNT_VERIFICATION_REQUEST';
export const GET_ACCOUNT_VERIFICATION_SUCCESS = 'GET_ACCOUNT_VERIFICATION_SUCCESS';
export const GET_ACCOUNT_VERIFICATION_ERRORED = 'GET_ACCOUNT_VERIFICATION_ERRORED';

export const getAccountVerification = () =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_VERIFICATION_REQUEST,
			GET_ACCOUNT_VERIFICATION_SUCCESS,
			GET_ACCOUNT_VERIFICATION_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/verification',
			options: { credentials: 'omit' },
		},
	});

// GETs the new version of user's Account Verification settings
// where fields are broken down into upto 4 screens

export const GET_ACCOUNT_VERIFICATION_SCREENS_REQUEST =
	'GET_ACCOUNT_VERIFICATION_SCREENS_REQUEST';
export const GET_ACCOUNT_VERIFICATION_SCREENS_SUCCESS =
	'GET_ACCOUNT_VERIFICATION_SCREENS_SUCCESS';
export const GET_ACCOUNT_VERIFICATION_SCREENS_ERRORED =
	'GET_ACCOUNT_VERIFICATION_SCREENS_ERRORED';

export const getAccountVerificationScreens = (idvFlow) =>
	fetchRequestWithTypes({
		types: [
			GET_ACCOUNT_VERIFICATION_SCREENS_REQUEST,
			GET_ACCOUNT_VERIFICATION_SCREENS_SUCCESS,
			GET_ACCOUNT_VERIFICATION_SCREENS_ERRORED,
		],
		request: {
			method: 'GET',
			path: idvFlow
				? '/apis/customers/v1/users/:uuid/identities/verifications/fields'
				: '/apis/api/v1/users/:uuid/verification_field_screens',
			options: { credentials: 'omit' },
		},
	});

// Returns the next step in the account verification process.
// https://staging.stsh.io/documentation/1.0/account_verifications/next_step.html

export const GET_VERIFICATION_NEXT_STEP_REQUEST = 'GET_VERIFICATION_NEXT_STEP_REQUEST';
export const GET_VERIFICATION_NEXT_STEP_SUCCESS = 'GET_VERIFICATION_NEXT_STEP_SUCCESS';
export const GET_VERIFICATION_NEXT_STEP_ERRORED = 'GET_VERIFICATION_NEXT_STEP_ERRORED';

export const getVerificationNextStep = () =>
	fetchRequestWithTypes({
		types: [
			GET_VERIFICATION_NEXT_STEP_REQUEST,
			GET_VERIFICATION_NEXT_STEP_SUCCESS,
			GET_VERIFICATION_NEXT_STEP_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/api/v1/users/:uuid/verification/next_step',
			options: { credentials: 'omit' },
		},
	});

// POST a user's Account Verification settings - onboarding flow

export const PUT_ONBOARDING_ACCOUNT_VERIFICATION_REQUEST =
	'PUT_ONBOARDING_ACCOUNT_VERIFICATION_REQUEST';
export const PUT_ONBOARDING_ACCOUNT_VERIFICATION_SUCCESS =
	'PUT_ONBOARDING_ACCOUNT_VERIFICATION_SUCCESS';
export const PUT_ONBOARDING_ACCOUNT_VERIFICATION_ERRORED =
	'PUT_ONBOARDING_ACCOUNT_VERIFICATION_ERRORED';

export const putOnboardingAccountVerification = (body) =>
	fetchRequestWithTypes({
		types: [
			PUT_ONBOARDING_ACCOUNT_VERIFICATION_REQUEST,
			PUT_ONBOARDING_ACCOUNT_VERIFICATION_SUCCESS,
			PUT_ONBOARDING_ACCOUNT_VERIFICATION_ERRORED,
		],
		request: {
			method: 'PUT',
			path: '/apis/customers/v1/users/:uuid/identities',
			body,
			options: { credentials: 'omit' },
		},
	});

// POST a user's Account Verification settings

export const POST_ACCOUNT_VERIFICATION_REQUEST = 'POST_ACCOUNT_VERIFICATION_REQUEST';
export const POST_ACCOUNT_VERIFICATION_SUCCESS = 'POST_ACCOUNT_VERIFICATION_SUCCESS';
export const POST_ACCOUNT_VERIFICATION_ERRORED = 'POST_ACCOUNT_VERIFICATION_ERRORED';

export const postAccountVerification = (body) =>
	fetchRequestWithTypes({
		types: [
			POST_ACCOUNT_VERIFICATION_REQUEST,
			POST_ACCOUNT_VERIFICATION_SUCCESS,
			POST_ACCOUNT_VERIFICATION_ERRORED,
		],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/verification',
			body,
			options: { credentials: 'omit' },
		},
	});

// POST a user's Account Verification settings

export const POST_DOCUMENT_REQUEST = 'POST_DOCUMENT_REQUEST';
export const POST_DOCUMENT_SUCCESS = 'POST_DOCUMENT_SUCCESS';
export const POST_DOCUMENT_ERRORED = 'POST_DOCUMENT_ERRORED';

export const postDocument = (body) =>
	fetchRequestWithTypes({
		types: [POST_DOCUMENT_REQUEST, POST_DOCUMENT_SUCCESS, POST_DOCUMENT_ERRORED],
		request: {
			method: 'POST',
			path: '/apis/api/v1/users/:uuid/verification/document',
			body,
		},
	});

export const GET_WIMAL_NEXT_STEP_REQUEST = 'GET_WIMAL_NEXT_STEP_REQUEST';
export const GET_WIMAL_NEXT_STEP_SUCCESS = 'GET_WIMAL_NEXT_STEP_SUCCESS';
export const GET_WIMAL_NEXT_STEP_ERRORED = 'GET_WIMAL_NEXT_STEP_ERRORED';

export const getWIMALNextStep = () =>
	fetchRequestWithTypes({
		types: [
			GET_WIMAL_NEXT_STEP_REQUEST,
			GET_WIMAL_NEXT_STEP_SUCCESS,
			GET_WIMAL_NEXT_STEP_ERRORED,
		],
		request: {
			method: 'GET',
			path: '/apis/identity/api/v1/users/:uuid/proofRequests/nextStep',
			options: { credentials: 'omit' },
		},
	});

export const GET_WIMAL_INTRO_REQUEST = 'GET_WIMAL_INTRO_REQUEST';
export const GET_WIMAL_INTRO_SUCCESS = 'GET_WIMAL_INTRO_SUCCESS';
export const GET_WIMAL_INTRO_ERRORED = 'GET_WIMAL_INTRO_ERRORED';

export const getWIMALIntro = () =>
	fetchRequestWithTypes({
		types: [GET_WIMAL_INTRO_REQUEST, GET_WIMAL_INTRO_SUCCESS, GET_WIMAL_INTRO_ERRORED],
		request: {
			method: 'GET',
			path: '/apis/identity/api/v1/users/:uuid/proofRequests/intro',
			options: { credentials: 'omit' },
		},
	});
