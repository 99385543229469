import {
	GET_TRANSFER_SOURCES_SUCCESS,
	GET_TRANSFER_DESTINATIONS_SUCCESS,
	GET_TRANSFER_DESTINATIONS_REQUEST,
	GET_TRANSFER_PREFILL_SUCCESS,
	CONFIRM_TRANSFER_SUCCESS,
} from '../../actions/api';

export default (state = {}, action) => {
	switch (action.type) {
		case GET_TRANSFER_SOURCES_SUCCESS:
			return {
				...state,
				sources: action.response.sources,
			};
		case GET_TRANSFER_DESTINATIONS_REQUEST:
			return {
				...state,
				destinations: null,
			};
		case GET_TRANSFER_DESTINATIONS_SUCCESS:
			return {
				...state,
				destinations: action.response.destinations,
			};
		case GET_TRANSFER_PREFILL_SUCCESS:
			return {
				...state,
				prefillTransfer: action.response.prefillTransfer,
			};
		case CONFIRM_TRANSFER_SUCCESS:
			return {
				...state,
				transferConfirmation: action.response.transferConfirmation,
			};

		default:
			return state;
	}
};
