import { CheckCircleIcon, XCircleIcon } from '@stashinvest/react-assets';
import { Toast } from '@stashinvest/react-components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearToast } from 'stash/actions';
import { selectToast } from 'stash/selectors';

/**
 * Toast relies on application redux state to display a message to the user.
 * There can only ever be 1 toast at a time.
 * Call dispatch(toastSuccess('yayy!') or dispatch(toastError('oh no!') to display a toast.
 */
const GlobalToast = () => {
	const dispatch = useDispatch();
	const toast = useSelector((state) => selectToast(state));

	return !!toast ? (
		<Toast
			variant={toast.type}
			Icon={
				!!toast.icon
					? toast.icon
					: toast.type === 'success'
					? CheckCircleIcon
					: XCircleIcon
			}
			text={toast.message}
			onDismiss={() =>
				/**
				 * Timeout allows the toast time to animate away instead of abruptly being removed from DOM.
				 */
				setTimeout(() => {
					dispatch(clearToast());
				}, 1000)
			}
		/>
	) : null;
};

export default GlobalToast;
