import { isValidElement } from 'react';

// Opens a dialog window with a given text.
// This action returns a promise from middleware/dialog.js

export const OPEN_DIALOG = 'OPEN_DIALOG';

export const openDialog = (prompt = {}, ...args) => {
	// Support legacy dialog API in outdated branches
	if (typeof prompt !== 'object' || isValidElement(prompt)) {
		return {
			type: OPEN_DIALOG,
			prompt,
			confirmText: args[0],
			cancelText: args[1],
			renderCancelLink: args[2],
			shouldRenderHelpLink: args[3],
		};
	}

	return {
		type: OPEN_DIALOG,
		...prompt,
	};
};

// Confirms a modal window
// This action resolves the promise.

export const CONFIRM_DIALOG = 'CONFIRM_DIALOG';

export const confirmDialog = () => ({
	type: CONFIRM_DIALOG,
});

// Cancels a modal window
// This action resolves the promise.

export const CANCEL_DIALOG = 'CANCEL_DIALOG';

export const cancelDialog = () => ({
	type: CANCEL_DIALOG,
});
