import { createSelector } from 'reselect';

export const selectPreviousNonModalLocation = (state) => {
	const historyEntry = state.application.modal.slice(1).find(({ isModal }) => {
		return !isModal;
	});
	return (historyEntry || {}).location;
};

export const selectIsModalGoBackFunctionUsable = createSelector(
	selectPreviousNonModalLocation,
	(state) => state.application.modal,
	(previousNonModalLocation, modal) => {
		const previousLocation = (modal[1] || {}).location;
		if (!previousLocation) return false;
		if (!previousLocation || !previousNonModalLocation) return false;

		const previousNonModalPath =
			previousNonModalLocation.pathname + previousNonModalLocation.search;
		const currentPath = previousLocation.pathname + previousLocation.search;

		const goBackFunctionIsUsable = previousNonModalPath === currentPath;
		return goBackFunctionIsUsable;
	}
);
