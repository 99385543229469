import { combineReducers } from 'redux';
import * as signUpActions from 'stash/actions/signUp';

// For temporary sign up state (milestones, skips).
export const initCompletedState = {
	'confirm-transfer': false,
};

export const completed = (state = initCompletedState, action) => {
	switch (action.type) {
		case signUpActions.SET_INVEST_ACCOUNT:
		case signUpActions.SET_RETIRE_ACCOUNT:
		case signUpActions.SET_SKIP_AUTOSTASH:
		case signUpActions.SET_DEBIT_CONFIRM_ADDRESS:
		case signUpActions.SET_DEBIT_CONFIRM_INFO:
		case signUpActions.SET_INVEST_ADDON_VALUE_PROP:
		case signUpActions.SET_ADD_PAYMENT_COMPLETED:
			return {
				...state,
				[action.name]: action.value,
			};

		case signUpActions.FLUSH_SIGN_UP:
			return {
				...initCompletedState,
			};

		default:
			return state;
	}
};

export const initDataState = {};

const data = (state = initDataState, action) => {
	switch (action.type) {
		case signUpActions.SET_SIGN_UP_DATA:
		case signUpActions.SET_SIGN_UP_ACCOUNT_TYPE:
		case signUpActions.SET_SIGN_UP_AUTO_STASH:
			return {
				...state,
				...action.data,
			};
		case signUpActions.SET_SIGNUP_ACCOUNTS_FOR_AUTOSTASH:
			return {
				...state,
				autostash_accounts: action.data,
			};
		case signUpActions.FLUSH_SIGN_UP:
			return {
				...initDataState,
			};
		default:
			return state;
	}
};

export default combineReducers({
	completed,
	data,
});
