import React, { lazy, Suspense } from 'react';
import { Loader } from '@stashinvest/ui';
import { NoAuthNavBar } from 'stash/components/global/NoAuthNavBar';
import { LoaderContainer } from './LoaderContainer';

const retryCount = 1;

const pause = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

export const retryAsyncFunction = async (
	fn,
	remainingRetries = retryCount + 1,
	interval = 750
) => {
	while (remainingRetries) {
		try {
			const result = await fn();
			return result;
		} catch (e) {
			await pause(interval);
			remainingRetries -= 1;
			if (!remainingRetries) {
				throw e;
			}
		}
	}
};

const LazyLoadComponent = (importFunc, { showNav } = {}) => {
	const LazyLoadedComponent = lazy(() => retryAsyncFunction(importFunc));
	return (props) => (
		<Suspense
			fallback={
				<div>
					{Boolean(showNav) && <NoAuthNavBar />}
					<LoaderContainer>
						<Loader center />
					</LoaderContainer>
				</div>
			}
		>
			<LazyLoadedComponent {...props} />
		</Suspense>
	);
};

export default LazyLoadComponent;
