//	----------------------------------------------------------------------------
//	General action to track ui interaction events.
//	NOTE: use only if data is essential and will be tracked
//	----------------------------------------------------------------------------
//	Example:
//		dispatch(trackInteraction('SwitchIRAType', {
//			IRAType: accountType
//		}));
//
//	This will send something like:
//	Event: 'Interaction', properties: {
//		Action: 'SwitchIRAType',
//		IRAType: 'TRADITIONAL_IRA',
//	}

export const TRACK_INTERACTION = 'TRACK_INTERACTION';

export const trackInteraction = (action, options = {}) => ({
	type: TRACK_INTERACTION,
	properties: {
		Action: action,
		...options,
	},
});

//	----------------------------------------------------------------------------
//	General action to track events that are not directly tied to an api call and
//	are not ui interactions.
//	----------------------------------------------------------------------------
//	Example: a registration screen that only sets application state temporarily
//	but needs to be tracked in a mixpanel funnel.
//
//		trackEvent('RegistrationStep',
//			{ ScreenName: 'ChooseAccountType' }
//		)
//
//	This will send something like:
//	Event: 'RegistrationStep', properties: {
//		ScreenName: 'ChooseAccountType',
//	}

export const TRACK_EVENT = 'TRACK_EVENT';

export const trackEvent = (eventName, data = {}) => ({
	type: TRACK_EVENT,
	eventName,
	data,
});

export const TRACK_ORIGIN = 'TRACK_ORIGIN';
/**
 * For tracking where a user has come from.
 * In product-events-javascript some tracking calls require an 'origin' which is how a user came on a page
 * @param origin
 * @returns {{origin, type: *}}
 */
export const trackOrigin = (origin) => ({
	type: TRACK_ORIGIN,
	origin,
});
