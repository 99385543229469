import React from 'react';
import {
	OPEN_DIALOG,
	CONFIRM_DIALOG,
	CANCEL_DIALOG,
} from '../../actions/dialog.js';

export default (state = { open: false }, action) => {
	switch (action.type) {
		case OPEN_DIALOG: {
			const { prompt, type, ...restAction } = action;
			const safePrompt = React.isValidElement(prompt)
				? prompt
				: typeof prompt === 'object'
				? JSON.stringify(prompt)
				: prompt;

			return {
				open: true,
				prompt: safePrompt,
				...restAction,
			};
		}
		case CONFIRM_DIALOG:
			return {
				open: false,
			};
		case CANCEL_DIALOG:
			return {
				open: false,
			};
		default:
			return state;
	}
};
