import { fetchRequestWithTypes } from './index';
import { STASH_VERSION_HEADERS } from '../../components/north-star-home/constants';

export const GET_BANK_ACCOUNT_HOME_REQUEST = 'GET_BANK_ACCOUNT_HOME_REQUEST';
export const GET_BANK_ACCOUNT_HOME_SUCCESS = 'GET_BANK_ACCOUNT_HOME_SUCCESS';
export const GET_BANK_ACCOUNT_HOME_ERRORED = 'GET_BANK_ACCOUNT_HOME_ERRORED';

export const getBankAccountHome = (accountId) =>
	fetchRequestWithTypes({
		types: [
			GET_BANK_ACCOUNT_HOME_REQUEST,
			GET_BANK_ACCOUNT_HOME_SUCCESS,
			GET_BANK_ACCOUNT_HOME_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v2/users/:uuid/accounts/${accountId}/home`,
			headers: STASH_VERSION_HEADERS,
		},
	});

export const UPDATE_TRANSACTION_CATEGORY_SUCCESS = 'UPDATE_TRANSACTION_CATEGORY_SUCCESS';
export const UPDATE_TRANSACTION_CATEGORY_REQUEST = 'UPDATE_TRANSACTION_CATEGORY_REQUEST';
export const UPDATE_TRANSACTION_CATEGORY_ERRORED = 'UPDATE_TRANSACTION_CATEGORY_ERRORED';

export const updateTransactionCategory = (accountId, transactionId, body) =>
	fetchRequestWithTypes({
		types: [
			UPDATE_TRANSACTION_CATEGORY_REQUEST,
			UPDATE_TRANSACTION_CATEGORY_SUCCESS,
			UPDATE_TRANSACTION_CATEGORY_ERRORED,
		],
		request: {
			method: 'PATCH',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/transactions/${transactionId}`,
			body,
		},
	});

export const PUT_DISMISS_TILE_REQUEST = 'PUT_DISMISS_TILE_REQUEST';
export const PUT_DISMISS_TILE_SUCCESS = 'PUT_DISMISS_TILE';
export const PUT_DISMISS_TILE_ERRORED = 'PUT_DISMISS_TILE_ERRORED';

export const dismissHighlightTile = (accountId, tileId) =>
	fetchRequestWithTypes({
		types: [PUT_DISMISS_TILE_REQUEST, PUT_DISMISS_TILE_SUCCESS, PUT_DISMISS_TILE_ERRORED],
		request: {
			method: 'PUT',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/highlightTiles/${tileId}/dismiss`,
		},
	});

export const GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_REQUEST =
	'GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_REQUEST';
export const GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_SUCCESS =
	'GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_SUCCESS';
export const GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_ERRORED =
	'GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_ERRORED';

export const getDebitEnrollmentConfirmationData = () =>
	fetchRequestWithTypes({
		types: [
			GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_REQUEST,
			GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_SUCCESS,
			GET_DEBIT_ENROLLMENT_CONFIRMATION_DATA_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v1/users/:uuid/confirmationData`,
		},
	});

export const POST_CONFIRM_DEBIT_ENROLLMENT_REQUEST =
	'POST_CONFIRM_DEBIT_ENROLLMENT_REQUEST';
export const POST_CONFIRM_DEBIT_ENROLLMENT_SUCCESS =
	'POST_CONFIRM_DEBIT_ENROLLMENT_SUCCESS';
export const POST_CONFIRM_DEBIT_ENROLLMENT_ERRORED =
	'POST_CONFIRM_DEBIT_ENROLLMENT_ERRORED';

export const confirmDebitEnrollment = (address) => {
	return fetchRequestWithTypes({
		types: [
			POST_CONFIRM_DEBIT_ENROLLMENT_REQUEST,
			POST_CONFIRM_DEBIT_ENROLLMENT_SUCCESS,
			POST_CONFIRM_DEBIT_ENROLLMENT_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/checking/api/v1/users/:uuid/confirmEnrollment`,
			body: {
				enrollment: { address },
			},
		},
	});
};

export const POST_DEBIT_ACCOUNT_REQUEST = 'POST_DEBIT_ACCOUNT_REQUEST';
export const POST_DEBIT_ACCOUNT_SUCCESS = 'POST_DEBIT_ACCOUNT_SUCCESS';
export const POST_DEBIT_ACCOUNT_ERRORED = 'POST_DEBIT_ACCOUNT_ERRORED';

export const createDebitAccount = (debitAddress) => {
	// CORE TRANSITION TODO: remove once CORE is launched
	const createDebitRequest = {
		enrollment: {
			address: { ...debitAddress, type: 'home' },
		},
		partner: 'core',
	};

	return fetchRequestWithTypes({
		types: [
			POST_DEBIT_ACCOUNT_REQUEST,
			POST_DEBIT_ACCOUNT_SUCCESS,
			POST_DEBIT_ACCOUNT_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/checking/api/v1/users/:uuid/accounts`,
			body: createDebitRequest,
		},
	});
};

export const PUT_CLOSE_DEBIT_ACCOUNT_REQUEST = 'PUT_CLOSE_DEBIT_ACCOUNT_REQUEST';
export const PUT_CLOSE_DEBIT_ACCOUNT_SUCCESS = 'PUT_CLOSE_DEBIT_ACCOUNT_SUCCESS';
export const PUT_CLOSE_DEBIT_ACCOUNT_ERRORED = 'PUT_CLOSE_DEBIT_ACCOUNT_ERRORED';

export const closeDebitAccount = (accountId) => {
	return fetchRequestWithTypes({
		types: [
			PUT_CLOSE_DEBIT_ACCOUNT_REQUEST,
			PUT_CLOSE_DEBIT_ACCOUNT_SUCCESS,
			PUT_CLOSE_DEBIT_ACCOUNT_ERRORED,
		],
		request: {
			method: 'PUT',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/close`,
		},
	});
};

export const GET_DEBIT_ACCOUNT_REQUEST = 'GET_DEBIT_ACCOUNT_REQUEST';
export const GET_DEBIT_ACCOUNT_SUCCESS = 'GET_DEBIT_ACCOUNT_SUCCESS';
export const GET_DEBIT_ACCOUNT_ERRORED = 'GET_DEBIT_ACCOUNT_ERRORED';

export const getDebitAccount = () =>
	fetchRequestWithTypes({
		types: [
			GET_DEBIT_ACCOUNT_REQUEST,
			GET_DEBIT_ACCOUNT_SUCCESS,
			GET_DEBIT_ACCOUNT_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v1/users/:uuid/accounts`,
			headers: STASH_VERSION_HEADERS,
		},
	});

export const GET_DEBIT_ELIGIBILITY_REQUEST = 'GET_DEBIT_ELIGIBILITY_REQUEST';
export const GET_DEBIT_ELIGIBILITY_SUCCESS = 'GET_DEBIT_ELIGIBILITY_SUCCESS';
export const GET_DEBIT_ELIGIBILITY_ERRORED = 'GET_DEBIT_ELIGIBILITY_ERRORED';

export const getRegistrationEligibility = () =>
	fetchRequestWithTypes({
		types: [
			GET_DEBIT_ELIGIBILITY_REQUEST,
			GET_DEBIT_ELIGIBILITY_SUCCESS,
			GET_DEBIT_ELIGIBILITY_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v1/users/:uuid/registrationEligibility`,
		},
	});

export const POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_REQUEST =
	'POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_REQUEST';
export const POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_SUCCESS =
	'POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_SUCCESS';
export const POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_ERRORED =
	'POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_ERRORED';

export const sendPrefilledDirectDepositForm = (accountId, fill, value) => {
	return fetchRequestWithTypes({
		types: [
			POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_REQUEST,
			POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_SUCCESS,
			POST_SEND_PREFILLED_DIRECT_DEPOSIT_FORM_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${accountId}/features/directDeposit/sendPrefilledForm`,
			body: {
				fill,
				value,
			},
		},
	});
};

export const GET_BANK_ACCOUNT_STATUS_REQUEST = 'GET_BANK_ACCOUNT_STATUS_REQUEST';
export const GET_BANK_ACCOUNT_STATUS_SUCCESS = 'GET_BANK_ACCOUNT_STATUS_SUCCESS';
export const GET_BANK_ACCOUNT_STATUS_ERRORED = 'GET_BANK_ACCOUNT_STATUS_ERRORED';

export const getBankAccountStatus = (id) =>
	fetchRequestWithTypes({
		types: [
			GET_BANK_ACCOUNT_STATUS_REQUEST,
			GET_BANK_ACCOUNT_STATUS_SUCCESS,
			GET_BANK_ACCOUNT_STATUS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/checking/api/v1/users/:uuid/accounts/${id}/temp/treat`,
		},
	});
