export const DASHBOARD_READY = 'DASHBOARD_READY';

export const dashboardReady = () => ({
	type: DASHBOARD_READY,
});

export const DASHBOARD_NOT_READY = 'DASHBOARD_NOT_READY';

export const dashboardNotReady = () => ({
	type: DASHBOARD_NOT_READY,
});
