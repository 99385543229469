import styled from 'styled-components/macro';
import { Box, Flex } from '@stashinvest/ui';

export const SignUpStep = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 90%;
	max-width: 51.25rem;
	margin: 0 auto;
`;

SignUpStep.displayName = 'SignUpStep';

export const SignUpHeader = styled.div`
	width: 100%;
	line-height: 1.25;
	padding-right: ${({ small }) => (small ? '23%' : 0)};

	${({ theme }) => theme.mediaQueries.mobile} {
		padding-right: 0;
	}
`;

SignUpHeader.displayName = 'SignUpHeader';

export const SignUpWrapper = styled(Flex).attrs({ px: 's' })`
	flex-direction: column;
	max-width: 51.25rem;
	margin: ${({ theme }) => `${theme.space.l} auto 0`};
`;

export const SignUpWrapperInner = styled(Box)`
	max-width: 28rem;
`;
