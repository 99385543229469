import { fetchRequestWithTypes } from './index';

// GETs user's status for Coach Check-In

export const GET_COACH_CHECK_IN_STATUS_REQUEST =
	'GET_COACH_CHECK_IN_STATUS_REQUEST';
export const GET_COACH_CHECK_IN_STATUS_SUCCESS =
	'GET_COACH_CHECK_IN_STATUS_SUCCESS';
export const GET_COACH_CHECK_IN_STATUS_ERRORED =
	'GET_COACH_CHECK_IN_STATUS_ERRORED';

export const getCoachCheckInStatus = (checkInCategory) => {
	return fetchRequestWithTypes({
		types: [
			GET_COACH_CHECK_IN_STATUS_REQUEST,
			GET_COACH_CHECK_IN_STATUS_SUCCESS,
			GET_COACH_CHECK_IN_STATUS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/coach/api/v1/users/:uuid/check_ins/${checkInCategory}/status`,
		},
	});
};

// POST to update user's status

export const POST_COACH_CHECK_IN_STATUS_UPDATE_REQUEST =
	'POST_COACH_CHECK_IN_STATUS_UPDATE_REQUEST';
export const POST_COACH_CHECK_IN_STATUS_UPDATE_SUCCESS =
	'POST_COACH_CHECK_IN_STATUS_UPDATE_SUCCESS';
export const POST_COACH_CHECK_IN_STATUS_UPDATE_ERRORED =
	'POST_COACH_CHECK_IN_STATUS_UPDATE_ERRORED';

export const postCoachCheckInStatusUpdate = (checkInCategory, status) => {
	const body = { status };

	return fetchRequestWithTypes({
		types: [
			POST_COACH_CHECK_IN_STATUS_UPDATE_REQUEST,
			POST_COACH_CHECK_IN_STATUS_UPDATE_SUCCESS,
			POST_COACH_CHECK_IN_STATUS_UPDATE_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/coach/api/v1/users/:uuid/check_ins/${checkInCategory}/status`,
			body,
		},
	});
};

// GETs question for Coach Check-In

export const GET_COACH_CHECK_IN_QUESTION_REQUEST =
	'GET_COACH_CHECK_IN_QUESTION_REQUEST';
export const GET_COACH_CHECK_IN_QUESTION_SUCCESS =
	'GET_COACH_CHECK_IN_QUESTION_SUCCESS';
export const GET_COACH_CHECK_IN_QUESTION_ERRORED =
	'GET_COACH_CHECK_IN_QUESTION_ERRORED';

export const getCoachCheckInQuestion = (checkInCategory) => {
	return fetchRequestWithTypes({
		types: [
			GET_COACH_CHECK_IN_QUESTION_REQUEST,
			GET_COACH_CHECK_IN_QUESTION_SUCCESS,
			GET_COACH_CHECK_IN_QUESTION_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/coach/api/v1/users/:uuid/checkIns/${checkInCategory}`,
		},
	});
};

// GETs question for Coach Check-In

export const GET_CFSI_QUESTIONS_REQUEST = 'GET_CFSI_QUESTIONS_REQUEST';
export const GET_CFSI_QUESTIONS_SUCCESS = 'GET_CFSI_QUESTIONS_SUCCESS';
export const GET_CFSI_QUESTIONS_ERRORED = 'GET_CFSI_QUESTIONS_ERRORED';

export const getCfsiQuestions = (checkInCategory) => {
	return fetchRequestWithTypes({
		types: [
			GET_CFSI_QUESTIONS_REQUEST,
			GET_CFSI_QUESTIONS_SUCCESS,
			GET_CFSI_QUESTIONS_ERRORED,
		],
		request: {
			method: 'GET',
			path: `/apis/coach/api/v1/users/:uuid/check_ins/${checkInCategory}/`,
		},
	});
};

export const POST_CFSI_QUESTION_REQUEST = 'POST_CFSI_QUESTION_REQUEST';
export const POST_CFSI_QUESTION_SUCCESS = 'POST_CFSI_QUESTION_SUCCESS';
export const POST_CFSI_QUESTION_ERRORED = 'POST_CFSI_QUESTION_ERRORED';

export const postCfsiQuestion = (answer) => {
	const body = {
		check_in_id: answer.checkInId,
		question_id: answer.questionId,
		option_id: answer.answerId,
	};

	return fetchRequestWithTypes({
		types: [
			POST_CFSI_QUESTION_REQUEST,
			POST_CFSI_QUESTION_SUCCESS,
			POST_CFSI_QUESTION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/coach/api/v1/users/:uuid/check_ins/answer`,
			body,
		},
	});
};

// POST to submit user's answer to Coach Check-In questions

export const POST_COACH_CHECK_IN_QUESTION_REQUEST =
	'POST_COACH_CHECK_IN_QUESTION_REQUEST';
export const POST_COACH_CHECK_IN_QUESTION_SUCCESS =
	'POST_COACH_CHECK_IN_QUESTION_SUCCESS';
export const POST_COACH_CHECK_IN_QUESTION_ERRORED =
	'POST_COACH_CHECK_IN_QUESTION_ERRORED';

const checkInAnswerHash = (answer) => {
	return {
		answer: {
			checkIn: {
				id: answer.checkInId,
				node: {
					id: answer.questionId,
					options: [
						{
							id: answer.answerId,
							text: answer.text,
						},
					],
				},
			},
		},
	};
};

export const postCoachCheckInQuestion = (answer) => {
	const category = answer.category;
	const body = checkInAnswerHash(answer);

	return fetchRequestWithTypes({
		types: [
			POST_COACH_CHECK_IN_QUESTION_REQUEST,
			POST_COACH_CHECK_IN_QUESTION_SUCCESS,
			POST_COACH_CHECK_IN_QUESTION_ERRORED,
		],
		request: {
			method: 'POST',
			path: `/apis/coach/api/v1/users/:uuid/checkIns/${category}/answer`,
			body,
		},
	});
};
